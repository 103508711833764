@import '../../styles/responsive';
@import '../../styles/colors';

.paddingLeftDesktop50 {
  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 50px;
  }
}

.paddingLeftDesktop50 {
  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 50px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .MobileHeaderCell {
    width: 160px;
    padding: 14px 24px 14px 12px;
  }
}
