@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid $disabled-gray 1px;
  border-radius: 6px;
  background: none;
  text-align: center;
  color: $text-gray;

  &.italicize {
    font-style: italic;
  }

  &.small {
    padding: 36px 12px;

    .title {
      @extend .paragraph-regular;
    }

    .paragraph {
      @extend .small-paragraph-regular;
      margin-top: 6px;
    }
  }

  &.regular {
    padding: 72px 12px;

    .title {
      @extend .header-3;
    }

    .paragraph {
      @extend .paragraph-regular;
    }
  }
}

.paragraph,
.title {
  max-width: 700px;
  margin: 0 auto;
}
