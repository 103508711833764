@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.targetSearchInputContainer {
  padding: 0;
  position: relative;

  .iconDashboardIcons_search {
    position: absolute;
    top: 15px;
    left: 12px;
    font-size: 16px;
    color: $text-gray;
  }
}

.textInputDisabledLoading {
  opacity: 0.4;
  pointer-events: none;
}

.targetSearchInput {
  border: solid $border-gray 1px;
  border-radius: 6px;
  padding: 12px 12px 12px 32px;
  height: 36px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 16px;
  color: $black;
  box-sizing: border-box;
  background-color: $white;
  width: 100%;
  display: inline-flex;

  &:active,
  &:focus {
    border: solid $black 1px;
  }
  &::placeholder {
    color: $text-gray;
  }

  &.white {
    background-color: $white;
  }
}
