@import 'styles/colors.scss';
@import 'styles/responsive.scss';

.details {
  margin: 0 48px;
  border-bottom: 1px solid $disabled-gray;
}

.sections {
  margin: 0 48px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .details {
    margin: 0 24px;
  }

  .sections {
    margin: 0 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .details {
    margin: 0 12px;
  }

  .sections {
    margin: 0 12px;
  }
}
