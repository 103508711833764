@import '../../styles/typography';
@import '../../styles/colors';

.container {
  margin: 12px 0;
}

.noTables,
.empty {
  @extend .paragraph-regular;
  color: $text-gray;
}

.empty {
  font-style: italic;
}
