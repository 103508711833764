@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.container {
  display: flex;
  flex-direction: column;
}

.header {
  @extend .header-5;
  color: $black;
  margin-bottom: 12px;
}

.summaryRow {
  @extend .paragraph-regular;
  display: flex;
  justify-content: space-between;
  color: $black;

  &.tip {
    color: $green-tint;
  }

  &.total {
    @extend .paragraph-bold;
  }
}
