@import 'styles/responsive';
@import 'styles/colors';

.checkboxOptionComponent {
  display: block;
  padding-left: 6px;
  cursor: pointer;
  &:hover {
    background-color: $background-gray;
  }
}

.checkboxValueContainer {
  padding-left: 12px;
}

.checkboxValueContainerAll {
  color: $text-gray;
}
