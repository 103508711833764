// import external resources
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i,600,600i,700,700i&display=swap');

// import design system definitions
@import 'colors.scss';
@import 'typography.scss';
@import 'icons.scss';
@import 'responsive.scss';
@import 'z-index.scss';
@import 'borders.scss';
@import 'sharedIcon.scss';


// import components styles
@import '../components/Button/button.scss';
@import '../components/TextInput/text-input.scss';
@import '../components/InputError/input-error.scss';
@import '../components/TextField/text-field.scss';
@import '../components/TextInputNestedLabel/text-input-nested-label.scss';
@import '../components/SelectFilter/select-filter.scss';
@import '../components/Modal/modal.scss';
@import '../components/CenteredPanel/centered-panel.scss';
@import '../components/BackLink/back-link.scss';
@import '../components/UnderLayer/underlayer.scss';
@import '../components/IconButton/icon-button.scss';
@import '../components/Table/table.scss';
@import '../components/Container/container.scss';
@import '../components/Skeleton/skeleton.scss';
@import '../components/FormActionBar/form-action-bar.scss';
@import '../components/SearchInput/search-input.scss';
@import '../components/Form/form.scss';
@import '../components/Toggle/toggle.scss';
@import '../components/Checkbox/checkbox.scss';
@import '../components/RadioGroup/radio-group.scss';
@import '../components/TextArea/text-area.scss';
@import '../components/LoadingSpinner/loading-spinner.scss';
@import '../components/Notification/notification.scss';
@import '../components/FormattedInput/formatted-input.scss';
@import '../components/FormattedInputNestedLabel/formatted-input-nested-label.scss';
@import '../components/ColorPicker/color-picker.scss';
@import '../components/InfoCard/info-card.scss';
@import '../components/InfoCard/info-card-column.scss';
@import '../components/InfoCard/info-card-data-row.scss';
@import '../components/InfoCard/info-card-footer.scss';
@import '../components/InfoCard/info-card-pagination.scss';
@import '../components/InfoCard/info-card-list.scss';
@import '../components/DropDownMenu/drop-down-menu.scss';
@import '../components/OverflowButton/overflow-button.scss';

@import '../components/Tabs/tabs.scss';
@import '../components/SearchSelectFilter/search-select-filter.scss';
@import '../components/UserPermissions/UserPermissionsGroup.scss';
@import '../components/AppLayout/page-empty-result.scss';

// import pages styles
@import '../pages/ResetPassword/reset-password.scss';
@import '../pages/Orders/order-locations-page.scss';
@import '../pages/Transactions/transactions.scss';
