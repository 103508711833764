@import 'styles/responsive.scss';

@import 'styles/typography.scss';

.editDetails {
  margin: 24px 48px 0;
}

.displayName {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 24px;
}

.description {
  padding-top: 6px;
}

.withNoBottomPadding {
  padding: 12px 24px 0;
}

.schedulerContainer {
  padding: 0 24px;
}

.readonlyNameAndDescription {
  &.displayName {
    gap: 5px;
    padding: 12px 24px;
  }
}

.readonlyDisplayName {
  font-size: 28px;
}

.readonlyDescription {
  @extend .paragraph-regular;
  max-width: 702px;
  font-family: inherit;
}

@media screen and (max-width: $breakpoint-tablet) {
  .editDetails {
    margin: 24px 24px 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .editDetails {
    margin: 24px 12px 0;
  }

  .displayName {
    width: 100%;
    padding: 12px;
  }
}
