@import 'styles/colors.scss';
@import 'styles/typography.scss';

.paypalProviderButton {
  display: flex;
}

.paypalLogo {
  height: 24px;
  margin-right: 6px;
}

.paypalLink {
  display: block;
  @extend .paragraph-bold;
  background: $button-gray;
  border: none;
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;
  color: $black;
  display: flex;
}

.errorText {
  color: $text-gray;
  font-style: italic;
}
