@import '../../../styles/colors';
@import '../../../styles/typography';
@import '../../../styles/z-index.scss';

.container {
  display: flex;
  align-items: center;
}

.button {
  @extend .paragraph-bold;
  border-radius: 6px;
  cursor: pointer;
  color: $white;
  padding: 8px 12px;
  border: none;
  border: 1px solid transparent;

  &:focus {
    border: 1px solid $black;
  }
}

.statusButtonDisabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.pendingButton {
  background-color: $dark-text-gray;

  &:hover {
    background-color: $darkest-gray;
  }
}

.inProgressButton {
  background-color: $magenta;

  &:hover {
    background-color: $magenta-tint;
  }
}

.readyButton {
  background-color: $green;

  &:hover {
    background-color: $green-tint;
  }
}

.completedButton {
  background-color: $black;

  &:hover {
    background-color: $darkest-gray;
  }
}

.cancelledButton {
  background-color: $disabled-gray;
  font-style: italic;
  color: $dark-text-gray;
  cursor: not-allowed;
}

.buttonLabel {
  display: flex;
  position: relative;
  align-items: center;
  line-height: unset;
  height: 18px;
  width: max-content;
}

.downArrowIcon {
  margin-left: 6px;
  font-size: 10px;
}

.menuContainer {
  width: 100%;
  position: relative;
}

.menu {
  display: flex;
  flex-direction: column;
  outline: none;
  border: solid $disabled-gray 1px;
  border-radius: 6px 0 6px 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  position: absolute;
  top: 20px;
  right: 6px;
  min-width: 144px;
  max-width: 168px;
  z-index: $drop-down-menu-z-index;
  background: $white;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}
