.listToolbar {
  display: flex;
  align-items: center;
  width: 50%;
  min-width: 372px;
  gap: 12px;
  height: 36px;
}

.listSearchFilter {
  width: 100% !important;
  min-width: 240px;
}

.listSelectFilter {
  width: 55% !important;
  min-width: 132px;
}
