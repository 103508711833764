@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

$focusShadow: 0 0 3px 0 $dark-text-gray;

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}

.buttonContainer {
  height: 48px;
  padding: 4px 0;
  display: flex;
  justify-content: stretch;
}

.button {
  flex-grow: 1;
  outline: none;
  position: relative;
  border-radius: 6px;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-size: 16px;
  cursor: pointer;
  background-color: $white;
  color: $dark-text-gray;
  padding: 6px 12px;
  line-height: 24px;
  border: 1px solid $dark-text-gray;
  margin: 6px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $background-gray;
  }

  &:focus {
    box-shadow: $focusShadow;
  }

  &.processing {
    opacity: 0.6;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.pending {
    &.active {
      background-color: $dark-text-gray;
      border-color: $dark-text-gray;
      color: $white;

      &:hover {
        background-color: $darkest-gray;
        border-color: $darkest-gray;
      }
    }
  }

  &.processed {
    &.active {
      background-color: $magenta;
      border-color: $magenta;
      color: $white;

      &:hover {
        background-color: $magenta-tint;
        border-color: $magenta-tint;
      }
    }
  }

  &.ready {
    &.active {
      background-color: $green;
      border-color: $green;
      color: $white;

      &:hover {
        background-color: $green-tint;
        border-color: $green-tint;
      }
    }
  }

  &.complete {
    &.active {
      background-color: $black;
      border-color: $black;
      color: $white;

      &:hover {
        background-color: $darkest-gray;
        border-color: $darkest-gray;
      }
    }
  }

  &.cancelled {
    background-color: $disabled-gray;
    font-style: italic;
    color: $dark-text-gray;
    border: none;
    cursor: not-allowed;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}
