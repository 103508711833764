@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.options {
  min-width: 0;
}

.optionsDropArea {
  background-color: $disabled-gray;
}

.option,
.optionDragging {
  border-top: 1px solid $main-panel-gray;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option {
  background-color: $white;
}

.option:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.optionDragging {
  background-color: $background-gray;
}

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 20px;
  color: $border-gray;
  margin-left: 24px;
  margin-right: 12px;
}

.noOptions {
  display: flex;
  align-items: center;
  height: 72px;
  color: $text-gray;
  text-align: center;
  padding: 0 12px;
}

.button {
  padding-left: 12px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .option,
  .optionDragging {
    align-items: flex-start;
  }

  .dragHandle {
    margin: 12px 0;
    flex-shrink: 0;
  }

  .footer {
    padding: 0 12px;
  }
}
