.centered-panel__container {
  .container.back-link-container {
    margin-bottom: 24px;
  }
  .reset-password__request-email,
  .reset-password__transaction-result {
    h3 {
      @extend .header-3;
      margin-bottom: 12px;
    }
    .reset-password__request-email__paragraph,
    .reset-password__transaction-result__paragraph {
      @extend .paragraph-regular;
      color: $dark-text-gray;

      a {
        color: $dark-text-gray;
      }
    }
    .container.text-field-nested-label-container {
      margin-top: 24px;
    }
    .container.button-large-container {
      margin-top: 24px;
      width: 100%;
    }
  }
}
