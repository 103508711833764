@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.rootOption,
.option,
.optionNested,
.optionNestedWithGroups {
  width: 100%;
  display: flex;
  align-items: center;
}

.rootOption.compactView,
.rootOptionIndented.compactView {
  &:not(.isReadonly) {
    padding-left: 72px;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    padding-left: 24px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 12px;
  }
}

.rootOptionIndented {
  padding-left: 24px;

  @media screen and (max-width: $breakpoint-tablet) {
    padding-left: 12px;
  }
}

.option {
  border-top: 1px solid $background-gray;
}

.withControlSpace {
  padding-right: 60px;
}

.externalContainer {
  padding-left: 12px;
}

.expanderLevel0 {
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px 0;
  }
}

.rootOptionIndented:hover,
.linkable:hover .expanderLevel0,
.linkable:hover .expanderLevel1,
.linkable:hover .expanderLevel2,
.linkable:hover .expanderLevel3,
.linkable:hover .expanderLevel4,
.linkable:hover .expanderLevel5,
.linkable:hover .indenterLevel1,
.linkable:hover .indenterLevel2,
.linkable:hover .indenterLevel3,
.linkable:hover .indenterLevel4,
.linkable:hover .indenterLevel5,
.linkable:hover a {
  background-color: $background-gray-op;
}

.rootOption.compactView:hover .name,
.linkable.compactView:hover .name {
  color: $magenta;
}

.level1,
.level2,
.level3,
.level4 {
  @media screen and (max-width: $breakpoint-mobile) {
    border-left: 1px solid $background-gray;
    border-bottom: 1px solid $background-gray;
  }
}

.level1 {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 18px;
  }
}

.level2 {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 36px;
  }
}

.level3 {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 54px;
  }
}

.level4 {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 72px;
  }
}

.optionNested > .expanderAndName > .expanderLevel1,
.optionNested > .expanderAndName > .expanderLevel2,
.optionNested > .expanderAndName > .expanderLevel3,
.optionNested > .expanderAndName > .expanderLevel4,
.optionNested > .expanderAndName > .indenterLevel1,
.optionNested > .expanderAndName > .indenterLevel2,
.optionNested > .expanderAndName > .indenterLevel3,
.optionNested > .expanderAndName > .indenterLevel4,
.optionNested > .expanderAndName > .name,
.optionNested > .detailsContainer,
.optionNestedWithGroups > .expanderAndName > .expanderLevel1,
.optionNestedWithGroups > .expanderAndName > .expanderLevel2,
.optionNestedWithGroups > .expanderAndName > .expanderLevel3,
.optionNestedWithGroups > .expanderAndName > .expanderLevel4,
.optionNestedWithGroups > .expanderAndName > .indenterLevel1,
.optionNestedWithGroups > .expanderAndName > .indenterLevel2,
.optionNestedWithGroups > .expanderAndName > .indenterLevel3,
.optionNestedWithGroups > .expanderAndName > .indenterLevel4,
.optionNestedWithGroups > .expanderAndName > .name,
.optionNestedWithGroups > .detailsContainer {
  border-top: 1px solid $background-gray;
  padding: 0;

  @media screen and (max-width: $breakpoint-mobile) {
    border-top: none;
  }
}

.optionNestedWithGroups:not(.compactView) > .expanderAndName > .expanderLevel1,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .expanderLevel2,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .expanderLevel3,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .expanderLevel4,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .indenterLevel1,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .indenterLevel2,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .indenterLevel3,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .indenterLevel4,
.optionNestedWithGroups:not(.compactView) > .expanderAndName > .name,
.optionNestedWithGroups:not(.compactView) > .detailsContainer {
  border-bottom: 1px solid $background-gray;

  @media screen and (max-width: $breakpoint-mobile) {
    border-bottom: none;
  }
}

.expanderLevel0,
.expanderLevel1,
.expanderLevel2,
.expanderLevel3,
.expanderLevel4 {
  width: 18px;
  min-height: 72px;
}

.compactView .expanderLevel0,
.compactView .expanderLevel1,
.compactView .expanderLevel2,
.compactView .expanderLevel3,
.compactView .expanderLevel4 {
  min-height: 48px;
}

.indenterLevel1,
.indenterLevel2,
.indenterLevel3,
.indenterLevel4 {
  width: 12px;
  min-height: 72px;
}

.compactView .indenterLevel1,
.compactView .indenterLevel2,
.compactView .indenterLevel3,
.compactView .indenterLevel4 {
  min-height: 48px;
}

.expanderLevel1,
.indenterLevel1 {
  margin-left: 18px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
    border-left: none;
  }
}

.expanderLevel1:not(.compactView),
.indenterLevel1:not(.compactView) {
  border-left: 1px solid $background-gray;

  @media screen and (max-width: $breakpoint-mobile) {
    border-left: none;
  }
}

.expanderLevel2,
.indenterLevel2 {
  margin-left: 36px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
    border-left: none;
  }
}

.expanderLevel2:not(.compactView),
.indenterLevel2:not(.compactView) {
  border-left: 1px solid $background-gray;

  @media screen and (max-width: $breakpoint-mobile) {
    border-left: none;
  }
}

.expanderLevel3,
.indenterLevel3 {
  margin-left: 54px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
    border-left: none;
  }
}

.expanderLevel3:not(.compactView),
.indenterLevel3:not(.compactView) {
  border-left: 1px solid $background-gray;

  @media screen and (max-width: $breakpoint-mobile) {
    border-left: none;
  }
}

.expanderLevel4,
.indenterLevel4 {
  margin-left: 72px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
    border-left: none;
  }
}

.expanderLevel4:not(.compactView),
.indenterLevel4:not(.compactView) {
  border-left: 1px solid $background-gray;

  @media screen and (max-width: $breakpoint-mobile) {
    border-left: none;
  }
}

.expander {
  margin-top: 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-top: 0;
  }
}

.optionNested .expanderAndName,
.optionNestedWithGroups .expanderAndName {
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px 0;
  }
}

.name {
  padding: 12px 0;
  width: 100%;
  min-height: 72px;
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.compactView .name {
  min-height: 48px;
}

.optionNested .name,
.optionNestedWithGroups .name {
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px 0;
  }
}

.nameBlock {
  overflow: hidden;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0;
  }
}

.detailsContainer {
  flex-grow: 1;
  min-height: 72px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.compactView .detailsContainer {
  min-height: 48px;
}

.detailsContainer {
  padding: 12px 0;
}

.detailsContainer > .pos {
  flex-basis: 30%;
}

.compactView .detailsContainer > .pos {
  flex-basis: 55%;
}

.detailsContainer > .price {
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-basis: 100%;
  }
}

.detailsContainer > .icons {
  flex-basis: 25%;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-basis: 100%;
  }
}

.compactView .detailsContainer > .icons {
  flex-basis: 15%;
}

.detailsContainer > .isMaxValue {
  flex-basis: 15%;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-basis: 50%;
  }
}

.compactView .detailsContainer > .isMaxValue {
  flex-basis: 15%;
}

.detailsContainer > .isDefault {
  flex-basis: 15%;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-basis: 50%;
  }
}

.detailsContainer {
  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.mobileDetailsContainer {
  display: none;

  @media screen and (max-width: $breakpoint-mobile) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 12px 0 0;
  }
}

.maxAndDefault {
  display: flex;

  @media screen and (max-width: $breakpoint-mobile) {
    align-items: center;
  }
}

.maxAndDefault > .isDefault {
  margin-left: 24px;
}

.maxAndDefault > .isMaxValueEditMode {
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}

.isMaxValue,
.isDefault {
  @media screen and (max-width: $breakpoint-mobile) {
    flex-grow: 1;
  }
}

.name,
.pos,
.price,
.icons,
.isDefault,
.isMaxValue {
  padding-right: 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-right: 12px;
  }
}

.expanderAndName {
  flex-basis: 22%;
  display: flex;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-basis: 100%;
    align-items: flex-start;
  }
}

.name,
.pos {
  overflow: hidden;
}

.price {
  @extend .paragraph-regular;
  color: $black;
}

.isDefault,
.isMaxValue {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.price {
  font-weight: $font-weight-semibold;
}

.isDefaultEditMode,
.isMaxValueEditMode {
  @extend .isDefault;
  color: $black;
}

.dropdown {
  @media screen and (max-width: $breakpoint-tablet) {
    margin-left: 12px;
  }
}

.rootNestedMods {
  width: 100%;
  padding-left: 24px;

  &.compactView {
    padding-left: 72px;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    padding-left: 12px;
  }
}
