.back-link {
  @extend .paragraph-regular;
  color: $magenta;
  text-decoration: none;
  line-height: 1.5;

  .icon-dashboard-icons_angle-left {
    font-size: 12px;
    margin-right: 12px;
  }
}

// container styles
.container.back-link-container {
  height: 20px;
  padding: 2px 0;
}
