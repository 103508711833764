.color-picker {
  display: block;
  border: solid $border-gray 1px;
  border-radius: 6px;
  width: 154px;
  height: 36px;
  display: flex;
  overflow: hidden;
  position: relative;

  .color-picker__color-wrapper {
    padding: 4px;
    border-right: solid $border-gray 1px;

    .color-picker__input-color {
      width: 0;
      height: 0;
      opacity: 0;
      position: absolute;
    }
    .color-picker__color {
      width: 28px;
      height: 26px;
      cursor: pointer;
      border-radius: 3px;
    }
  }
  .color-picker__input-wrapper {
    .color-picker__input-text {
      width: 100%;
      height: 100%;
      border: none;
      padding-left: 6px;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 16px;
      color: $black;

      &.color-picker--disabled {
        border: solid $border-gray 1px;
        background-color: $disabled-gray;
        font-style: italic;
        color: $dark-text-gray;
        input {
          background-color: $disabled-gray;
          font-style: italic;
          color: $dark-text-gray;
          &::placeholder {
            color: $dark-text-gray;
            font-style: italic;
          }
        }
        &,
        * {
          cursor: not-allowed !important;
        }
      }
    }
  }
  &.color-picker--focus {
    border: solid $black 1px;
    .color-picker__color-wrapper {
      border-right: solid $black 1px;
    }
  }
  &.color-picker--state-loading {
    opacity: 0.5 !important;
  }
  &.color-picker--disabled {
    border: solid $border-gray 1px;
    background-color: $disabled-gray;
    font-style: italic;
    color: $dark-text-gray;
    input {
      background-color: $disabled-gray;
      font-style: italic;
      color: $dark-text-gray;
      &::placeholder {
        color: $dark-text-gray;
        font-style: italic;
      }
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
}
