@import '../../../../../styles/responsive';
@import '../../../../../styles/colors';

.WidgetsContainer {
  position: relative;
  gap: 12px 24px;
  display: flex;
  padding: 12px 48px;
  max-width: 1700px;
  flex-wrap: wrap;
  @media screen and (max-width: $breakpoint-tablet) {
    padding: 12px 24px;
  }
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px;
    gap: 12px;
  }
}
.MoneyDescription {
  font-weight: bold;
  color: $text-gray;
}
.Widget {
  flex: 1;
  min-width: 350px;
  @media screen and (max-width: $breakpoint-mobile) {
    min-width: 300px;
  }
  &AverageCheckSizeAdvantage {
    color: $green-tint;
    font-weight: bold;
  }
}
.AverageCheckLine {
  display: flex;
  border: 1px solid $disabled-gray;
  margin-top: auto;
}
.WidgetsLoader {
  background-color: transparent;
  z-index: 1;
  opacity: 0.6;
  top: 0;
  left: 0;
}

.BackgroundMagenta {
  background-color: #a7135b;
}
.BackgroundGrey {
  background-color: $dark-text-gray;
}
