@import 'styles/colors.scss';
@import 'styles/typography.scss';

.appInfo {
  text-align: center;
  border-top: $background-gray solid 1px;

  @extend .tiny-paragraph-regular;
  color: $text-gray;
}
