@import 'styles/responsive.scss';
@import 'styles/colors.scss';

.container {
  margin: 0 48px;
  border-bottom: 1px solid $disabled-gray;
}

.sections {
  margin: 0 48px;
}

.subheader {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    margin: 0 24px;
  }

  .sections {
    margin: 0 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    margin: 0 12px;
  }

  .sections {
    margin: 0 12px;
  }
}
