.contentBox {
  flex: 1 1;
  overflow: hidden;
  min-width: 0;
  display: flex;
  gap: 12px;
}

.tagName {
  padding-top: 2px;
}
