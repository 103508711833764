@import 'styles/responsive.scss';

.scheduleHeaderCell {
  width: 100% !important;
  border-left: 1px solid #eeeeee;
  padding-left: 24px;
}

.bodyCell {
  &:nth-of-type(3) {
    border-left: 1px solid #eeeeee;
    padding-left: 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .scheduleHeaderCell,
  .scheduleCell {
    border-left: none;
    padding-left: 0;
  }
}