@import '../../../../styles/responsive';
@import '../../../../styles/colors';
@import '../../../../styles/typography';

.widget {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__title {
    font-size: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    color: $text-gray;
    margin-bottom: 25px;
  }
  &__body {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    @media screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }
  & &__loader {
    background-color: transparent;
    z-index: 1;
    opacity: 0.6;
  }
}
