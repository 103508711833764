.onAccountPaymentBadge {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19px;
  min-width: 30px;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 'initial';
}
