@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';

.cardColumn {
  min-width: 0;
}

.primaryField {
  @extend .paragraph-regular;
}

.additionalField {
  @extend .small-paragraph-regular;
}

.primaryField,
.additionalField {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.additionalField {
  color: $text-gray;
}

.newBadge {
  background-color: $green;
  width: 24px;
  padding: 0 6px;
  color: $white;
  border-radius: 3px;
  @extend .tiny-title;
  margin-left: 6px;
}
