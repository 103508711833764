@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.tagItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: solid $background-gray 1px;

  &:last-child {
    border-bottom: none;
  }
}

.tagItemDetails {
  display: flex;
  gap: 12px;
}

.tagsBadgesLink {
  @extend .paragraph-bold;
  color: $magenta;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.noSearchResults {
  @extend .paragraph-regular;
  color: $text-gray;
  font-style: italic;
  padding: 24px 0;
}
