@import 'styles/responsive.scss';

.locationNameCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 12px;

  .locationName {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // this overrides the padding of the cell, because the edit button adds some padding already
  padding-right: 9px;

  @media screen and (max-width: $breakpoint-mobile) {
    display: inline-flex;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    max-width: 312px;
    width: 312px;
  }
}
