.toggle {
  cursor: pointer;

  .toggle__text {
    @extend .header-6;
    color: $white;
    text-transform: uppercase;
    position: relative;

    &.toggle__text--on {
      left: 10px;
    }
    &.toggle__text--off {
      left: 4px;
    }
  }
  &.toggle--state-loading {
    opacity: 0.5 !important;
  }
  &.toggle--disabled {
    cursor: not-allowed !important;
    opacity: 1 !important;

    .react-switch-bg {
      background-color: $disabled-gray !important;
      cursor: not-allowed !important;
    }
    .toggle__text {
      color: $dark-text-gray;
      font-style: italic;
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
}

// container styles
.toggle-container {
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .toggle__spinner {
    position: absolute;
  }
}
