@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.container,
.containerWithMargin {
  height: 15px;
  background-color: $disabled-gray;
  border-radius: 3px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  @extend .tiny-paragraph-bold;
  color: $dark-text-gray;
}

.containerWithMargin {
  margin: 0 6px;
}
