@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.missing {
  color: $text-gray;
  font-style: italic;
}

.autoAttach {
  color: $green;
}

.noAutoAttach {
  color: $text-gray;
}

.autoAttach,
.noAutoAttach {
  margin-right: 6px;
}

.hasTimeSlots {
  margin-bottom: 24px;
}

.timeSlot {
  margin-bottom: 24px;
}

.timeSlot:last-of-type {
  margin-bottom: 0;
}

.days {
  font-weight: $font-weight-bold;
}
