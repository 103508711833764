@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.item {
  @extend .paragraph-regular;
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: none;
  background: none;
  padding: 12px 0;

  &:hover {
    color: $magenta;
    background-color: $background-gray-op;
  }
}
