@import '../../styles/colors';
@import '../../styles/typography';

.textContainer {
  display: flex;
  background-color: $light-gray;
}

.text {
  @extend .tiny-title;
  color: $dark-text-gray;
}
