@import 'styles/colors.scss';

.clientPublicKey {
  line-height: 1.5;
  padding-top: 6px;
  word-break: break-all;

  &.disabled {
    color: $text-gray;
    font-style: italic;
  }
}

.serverPublicKey {
  padding-top: 6px;
  padding-bottom: 6px;
}
