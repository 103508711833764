@import '../../styles/colors.scss';

.statusContainer {
  display: flex;
  align-items: center;
}

.activeIndicator {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 6px;
  background-color: $green;
}

.inactiveIndicator {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 6px;
  border: 1px solid $border-gray;
}

.inactiveLabel {
  font-style: italic;
  color: $text-gray;
}
