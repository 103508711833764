@import '../../styles/responsive';
@import '../../styles/colors';


.CellNowrap {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    white-space: nowrap;
  }
}
.CellPrewrap {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    white-space: pre-wrap;
  }
}
.paddingLeftDesktop50 {
  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 50px;
  }
}
.HeaderCellShorted50 {
  width: 50px;
}
.HeaderCellShorted500 {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    width: 500px;
    min-width: 200px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .MobileHeaderCell {
    width: 175px;
    padding-right: 24px;
  }
}

.PosDiscountNull {
  color: $orange-tint;
  & i {
    margin-right: 3px;
  }
}

.NoDescription {
  font-style: italic;
  color: $text-gray;
}
