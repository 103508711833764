@import 'styles/colors';
@import 'styles/typography';
@import 'styles/responsive';

.formRow {
  display: flex;
  height: fit-content;
}

.header {
  // override header justify-content to position sublabel beside title
  justify-content: unset !important;
  gap: 6px;
}

.sublabel {
  color: $text-gray;
}
