@import '../../../styles/responsive.scss';

.filter {
  > div {
    > div {
      max-height: 36px;
    }
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}
