@import 'styles/responsive.scss';
@import 'styles/typography.scss';
@import 'styles/colors.scss';

.container {
  padding: 12px 48px 0;
}

.menuCopyError {
  margin-bottom: 24px;
}

.menuCopyErrorHighlight {
  font-weight: $font-weight-bold;
}

.menuCopyErrorControls {
  display: flex;
}

.menuCopyErrorControls > *:first-child {
  padding-left: 24px;
  padding-right: 12px;
}

.menuCopyErrorButton {
  min-width: 102px;
}

.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}

.title {
  @extend .header-2;
}

.controls {
  flex: 1;
  justify-items: flex-end;
  align-items: flex-end;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button {
  margin-left: 12px;
}

.buttonsOverflow {
  display: none;
}

.buttonsOverflowDesktop {
  margin-left: 12px;
}

.menuConfigHeader {
  .label {
    font-weight: bold;
    padding-bottom: 6px;
  }
  width: 30%;
}


@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px 0;
  }

  .menuCopyErrorControls > *:first-child {
    padding-left: 0;
    padding-right: 12px;
  }

  .buttons {
    display: none;
  }

  .buttonsOverflow {
    display: block;
    padding-left: 12px;
  }

  .menuConfigPicker {
    width: unset;
  }
}
