#modal {
  .modal__background {
    background-color: rgba($color: $black, $alpha: 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: $modal-z-index;
    display: flex;
    flex-direction: column;
    padding-top: 15vh;
    padding-bottom: 10vh;
    align-items: center;
  }

  .modal__container {
    font-family: $font-family;
    margin: 0 auto;
    background-color: $white;
    width: 60%;
    max-width: 800px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 24px);
    overflow-y: hidden;

    .modal__header {
      padding: 0 24px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      min-height: 72px;
      flex-wrap: wrap;

      h3 {
        @extend .header-3;
        line-height: 72px;

        &.with-spinner {
          position: relative;
          left: -12px;
        }

        .modal__header--spinner {
          position: relative;
          top: 18px;
          width: 48px;
        }

        .modal__header--prefix {
          font-size: 20px;
          padding-right: 12px;
        }

        .modal__header--warning {
          padding-left: 6px;
          color: $orange-tint;

          .modal__header--warning-icon {
            font-size: 20px;
            padding-left: 6px;
          }
        }
      }

      .modal__header--subheader {
        width: 100%;
      }

      .icon-dashboard-icons_close {
        color: $dark-text-gray;
        font-size: 20px;
      }
    }

    .displayInline {
      display: inline-block;
      margin-bottom: 12px;
    }

    .modal__search-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 24px 0;
    }

    .modal__body {
      padding: 12px 24px 24px;
      flex-grow: 1;
      flex-shrink: 1;
      min-height: 36px;
      overflow-y: auto;

      &.modal__body--with-separator {
        border-top: 1px solid $background-gray;
        padding-top: 0;
      }
    }

    .modal__footer {
      display: flex;
      padding: 0 24px 24px;
      flex-shrink: 0;

      .button-container {
        &:first-of-type:not(:last-of-type) {
          width: 40%;
          margin-right: 12px;
        }

        &:last-of-type:not(:first-of-type) {
          width: calc(60% - 12px);
        }

        &:first-of-type:last-of-type {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .modal__container {
      max-width: unset;
      width: 95%;

      .modal__header {
        padding: 12px 12px 0;

        h3 {
          line-height: 36px;
        }
      }

      .modal__search-panel {
        padding: 6px 12px 0;
      }

      .modal__body {
        padding: 12px 12px 24px;
      }

      .modal__footer {
        padding: 0 12px 24px;
      }
    }
  }
}
