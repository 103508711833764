@import '../../../styles/colors';
@import '../../../styles/responsive';
@import '../../../styles/typography';
@import '../../../styles/borders';

.CurrencyIndicator {
  line-height: 1;
}

.MarkOpen {
  line-height: 1.2;
  & > span {
    height: 16px;
    line-height: 1;
    box-sizing: border-box;
  }
}

.CheckPage {
  &Loading {
    & &Spinner {
      background-color: unset;
      z-index: 1;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
        background-color: $main-panel-gray;
      }
    }
  }

  line-height: 24px;
  padding: 24px;
  max-width: 1624px;
  @media only screen and (max-width: $breakpoint-mobile) {
    margin: 18px 12px;
  }
  &Title {
    font-size: 28px;
    margin-left: 12px;
    font-weight: $font-weight-regular;
  }
  &Header {
    display: flex;
  }
  &Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px;
    gap: 24px;
    @media only screen and (max-width: $breakpoint-mobile) {
      margin-top: 12px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      grid-template-columns: 1fr;
    }
  }
  &Payments {
    .Panel {
      padding: 24px 24px 6px 24px;
      @media screen and (max-width: $breakpoint-mobile) {
        padding: 12px 12px 0 12px;
      }
    }
    @media screen and (min-width: $breakpoint-tablet + 1px) {
      order: 1;
    }
  }
}


.Panel {
  padding: 24px;
  background-color: $white;
  border-radius: 6px;
  &Title {
    font-size: 28px;
    font-weight: $font-weight-semibold;
    line-height: .9;
  }
  &Header {
    gap: 12px;
    display: flex;
    margin-bottom: 24px;
    > * {
      margin-top: auto;
    }
  }
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 24px 12px;

    & .PaymentMoney {
      flex-direction: row;
      &Value {
        margin-right: 3px;
        font-size: 20px;
      }
    }
  }
}

.LineHeight_24_mobile {
  @media screen and (max-width: $breakpoint-mobile) {
    line-height: 24px;
  }
}

.LeftArrowIcon {
  font-size: 24px;
  color: $magenta;
  cursor: pointer;
}

.TextBold {
  font-size: 16px;
  line-height: 24px;
  font-weight: $font-weight-bold;
}

.Uppercase {
  text-transform: uppercase;
}

.Capitalized {
  text-transform: capitalize;
}

.SecondaryText {
  color: $text-gray;
  font-size: 14px;
}

.Payment {
  &AlertIcon {
    color: $orange-tint;
    padding-left: 3px;
  }
  padding: 12px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  &:not(&:last-child) {
    border-bottom: $modal-border;
  }
  > * {
    display: flex;
    flex-direction: column;
  }
  &AlertIcon {
    color: $orange-tint;
    padding-left: 3px;
  }
  &Tip {
    color: $green-tint;
  }
  &Date {
    display: flex;
    flex-direction: column;
    text-align: right;
    @media screen and (max-width: $breakpoint-mobile) {
      text-align: left;
      flex-direction: row;
      & > span:first-child {
        margin-right: 3px;
        display: block;
      }
    }
  }
  &StatusBadgeContainer > span {
    white-space: nowrap;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &CartBrand {
    @media screen and (max-width: $breakpoint-mobile) {
      font-size: 14px;
    }
  }
}

.HistoryEvent {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  &::before {
    content: '';
    left: 0;
    top: 4px;
    position: absolute;
    display: block;
    width: 12px;
    aspect-ratio: 1 / 1;
    background: $disabled-gray;
    border-radius: 50%;
  }
  &:not(&:last-child) {
    padding-bottom: 24px;
    &::after {
      content: '';
      height: 100%;
      display: block;
      border-left: 1px solid $disabled-gray;
      position: absolute;
      left: 5px;
      top: 6px;
    }
  }
}

.PaymentDescription {
  margin-left: auto;
  width: 370px;
  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
  &Item {
    display: flex;
    justify-content: space-between;
    &MT {
      margin-top: 24px;
    }
  }
}

.CheckPaymentDistribution {
  color: $text-gray;
  font-weight: $font-weight-bold;
  margin: 12px 0 12px 0;
}

.CheckItem {
  &s {
    margin-bottom: 12px;
  }
  padding: 12px 0 12px 32px;
  &AdditionalInfo {
    padding: 12px 0 12px 64px;
    color: $text-gray;
    border-bottom: $modal-border;
    display: flex;
  }
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: $modal-border;
  @media screen and (max-width: $breakpoint-mobile) {
    &Cost {
      display: flex;
      flex-direction: column;
    }
    &DisabledCost {
      margin-left: unset;
    }
  }
  &ReadyMark {
    left: 0;
    top: 12px;
    position: absolute;
    line-height: 24px;
    color: $text-gray;
  }
  &Cost {
    flex: 1 1 auto;
    text-align: right;
    font-weight: $font-weight-semibold;
  }
  &DisabledCost {
    font-weight: $font-weight-regular;
    color: $text-gray;
    text-decoration: line-through;
    margin-left: 32px;
  }
  &Count {
    font-weight: $font-weight-bold;
    margin-right: 32px;
  }
  &Description {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
  }
  &CustomerComment {
    margin-top: 6px;
    background-color: $lighter-yellow;
    padding: 3px 6px;
    border-radius: 3px;
    text-transform: unset;
    display: flex;
    margin-right: 12px;
    > span {
      display: block;
    }
    &Icon {
      color: $yellow-text;
      margin-right: 6px;
    }
  }
}

.GeneralInfo {
  display: flex;
  flex-direction: column;
}



.Alert {
  margin-top: 24px;
  position: relative;
  $self: &;
  & .POSPaymentRetryLoading {
    top: 0;
    left: 0;
    opacity: .7;
    & > img {
      top: calc(-148px / 2 + 50%);
    }
  }

  &Text {
    margin: auto auto auto 0;
  }
  &Btn {
    white-space: nowrap;
    #{$self} & {
      padding: 0;
    }
    &:first-child {
      margin-right: 24px;
    }
  }
  &Btns {
    padding-left: 24px;
    display: flex;
    margin-left: auto;
    @media screen and (max-width: $breakpoint-mobile) {
      margin-top: 12px;
    }
  }
}

.Modal {
  &Hidden {
    visibility: hidden;
  }
  &Buttons {
    & .ModalButton {
      padding: 0;
      white-space: nowrap;
    }
    margin-top: 36px;
    display: flex;
    & > * {
      flex: 1 1 auto;
      &:first-child {
        margin-right: 12px;
      }
    }
  }
  &Text {
    margin-top: 30px;
  }
  &Title {
    font-size: 24px;
    font-weight: $font-weight-semibold;
  }
  &Description {
    &Title {
      font-size: 12px;
      font-weight: $font-weight-bold;
      color: $text-gray;
      text-transform: uppercase;
      text-align: center;
    }
    &Value {
      padding: 24px;
      font-size: 20px;
      margin-top: 6px;
      border-radius: 6px;
      background-color: $background-gray;
    }
    &Container {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
    }

    &Item {
      &:nth-child(even) {
        border-top: 1px solid $disabled-gray;
      }
      &__CardInfo {
        margin-right: 24px;
        flex: 1
      }
      &__PaymentDescription {
        margin-right: 24px;
        flex: 2
      }
      &>*{
        padding: 12px 0 12px 0;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }
    }
  }
}

.ColorGreen {
  color: $green-tint
}

