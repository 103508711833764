@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.title {
  display: flex;
}

.subTitle {
  @extend .small-paragraph-regular;
  color: $text-gray;
  margin-left: 48px;
  padding: 0 24px 0 0;

  &.isReadonly {
    margin-left: 0;
  }

  &.withSeparator {
    padding-bottom: 6px;
  }
}

.guestInstructions {
  @extend .paragraph-regular;
  padding: 0 24px 0 48px;

  &.isReadonly {
    padding-left: 0px;
  }
}

.attributes {
  display: flex;
}

.minMax {
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  color: $text-gray;
  align-self: center;
}

.minMaxSubTitle {
  display: none;
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  color: $text-gray;
}

.menu {
  @extend .header-4;
  color: $dark-text-gray;
  padding: 0 24px;
}

.option {
  border-bottom: 1px solid $background-gray;
}

.option:last-of-type {
  border-bottom: none;
}

.optionName {
  flex-basis: 22%;
}

.option:hover .optionName {
  color: $magenta;
}

.optionPosItemId {
  flex-basis: 18%;
}

.optionPosItemName {
  flex-basis: 30%;
}

.optionPrice {
  flex-basis: 10%;
  text-align: end;
  padding-right: 2%;
  font-weight: $font-weight-semibold;
}

.optionMaxValue {
  flex-basis: 10%;
}

.optionDefault {
  flex-basis: 10%;
}

.optionMaxValueContent {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.optionDefaultContent {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

@media screen and (max-width: $breakpoint-tablet) {
  .optionName {
    flex-basis: 23%;
  }

  .optionPosItemId {
    flex-basis: 17%;
  }

  .optionPosItemName {
    flex-basis: 23%;
  }

  .optionPrice {
    flex-basis: 17%;
    padding-right: 18px;
  }

  .optionMaxValue {
    flex-basis: 10%;
  }

  .optionDefault {
    flex-basis: 10%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .subTitle,
  .minMaxSubTitle {
    padding: 0 12px 0 0;
    margin-left: 0;

    &.withSeparator {
      padding-bottom: 0;
    }
  }

  .guestInstructions {
    padding: 6px 12px 0 0;
  }

  .minMax {
    display: none;
  }

  .minMaxSubTitle {
    display: block;
  }

  .menu {
    padding: 0 36px 0 0;
  }

  .optionName {
    flex-grow: 1;
  }

  .optionPosItemId {
    display: none;
  }

  .optionPosItemName {
    display: none;
  }

  .optionMaxValue {
    flex-grow: 0.3;
  }

  .optionDefault {
    flex-grow: 0.5;
  }
}
