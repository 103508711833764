.transactionFeeInput {
  width: 100%;
  max-width: 155px;
}

.paymentMethodCheckbox {
  margin-bottom: 12px;
}

.paymentMethodCheckboxContainer {
  padding: 12px 0px;
  width: 100%;
}
