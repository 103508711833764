@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';
@import 'styles/sharedIcon.scss';

.forcedModBannerContainer {
  padding: 0 48px 12px;

  @media screen and (max-width: $breakpoint-tablet) {
    padding: 0 24px 12px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 12px 12px;
  }

  .text {
    font-weight: bold;
  }
}

.alertIcon {
  margin-right: 6px;
}

.alertButton {
  width: 132px;
}

.statusField {
  display: flex;
}

.statusMessage {
  @extend .small-paragraph-regular;
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: $text-gray;
  font-style: italic;
}

.small {
  width: 33%;
}

.forcedMods {
  width: 100%;
  margin: 12px 0;
}

.forcedMod {
  display: flex;
  align-items: center;
}

.forcedModDetails {
  flex: 1;
  display: flex;
  @extend .paragraph-regular;
}

.forcedModId {
  margin-left: 3px;
  color: $text-gray;
}

.forcedModOutOfStock {
  margin: 3px 24px 0 12px;
  height: 16px;
  border-radius: 3px;
  background-color: $orange-tint;
  padding: 0 6px;
  @extend .tiny-paragraph-bold;
  display: flex;
  align-items: center;
  color: $white;
}

.forcedModPrice {
  font-weight: $font-weight-semibold;
}

.forcedModsEditLink {
  margin-top: 12px;
}

.pos {
  display: flex;
  align-items: center;
}

.alcoholControl {
  display: flex;
  flex-direction: column;

  &.readonly {
    padding-top: 12px;
  }
}

.readonlyAlcohol {
  padding-left: 24px;

  .value {
    font-weight: 700;
  }
}

.note {
  @extend .tiny-paragraph-regular;
  color: $text-gray;
  font-style: italic;
}

.image {
  margin: 12px 0;
}

.thumbnailImage {
  margin: 12px 0 0;
  label {
    max-width: 100px;
  }
}

.modifiersHeader {
  margin: 48px 48px 0;
}

.modifiers,
.pairings {
  margin: 0 48px;
}

.pairingsHeader {
  margin: 24px 48px 0;
}

.modifiersDragging {
  margin: 0 24px 18px;
}

.modifiersHeader,
.pairingsHeader {
  display: flex;
  padding-bottom: 12px;
}

.modifiersHeaderText,
.pairingsHeaderText {
  @extend .header-2;
  flex: 1;
}

.modifiersHeaderControls,
.pairingsHeaderControls {
  display: flex;
}

.modifiersHeaderControls > div,
.pairingsHeaderControls > div {
  margin-left: 12px;
}

.modifiersHeaderOverflow,
.pairingsHeaderOverflow {
  display: none;
}

.modifiers,
.pairings {
  border-top: 1px solid $disabled-gray;
}

.modifiers {
  padding: 24px 0 0;
}

.pairings {
  padding: 24px 0 0;
}

.item:hover {
  background-color: $background-gray-op;
}

.modifiersDragging {
  background-color: $disabled-gray;
  border: none;
  border-radius: 6px;
  padding: 24px 24px 6px;
}

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  font-size: 20px;
  color: $dark-text-gray;
}

.modifier,
.pairing {
  margin-bottom: 24px;
}

.button {
  font-size: 20px;
  color: $dark-text-gray;
}

.posMappingHeader {
  @extend .header-3;
}

.stockStatus {
  padding: 10px 0px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .small {
    width: 50%;
  }

  .modifiersHeader {
    margin: 48px 24px 0;
  }

  .modifiers,
  .pairings {
    margin: 0 24px;
  }

  .pairingsHeader {
    margin: 24px 24px 0;
  }

  .modifiersDragging {
    margin: 0 12px;
    padding: 24px 12px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .small {
    width: 50%;
  }

  .statusField {
    flex-direction: column;
    align-items: flex-start;
  }

  .statusMessage {
    margin-left: 0;
  }

  .forcedMods {
    margin-top: 0;
  }

  .forcedMod {
    align-items: flex-start;
  }

  .forcedModOutOfStock {
    margin-right: 12px;
  }

  .modifiersHeader {
    margin: 48px 12px 0;
  }

  .modifiers,
  .pairingsHeader,
  .pairings {
    margin: 0 12px;
  }

  .pairingsHeader {
    margin: 24px 12px 0;
  }

  .dragHandle {
    margin-right: 6px;
  }

  .modifiersDragging {
    margin: 0 6px;
    padding: 24px 6px;
  }

  .modifiersHeaderControls,
  .pairingsHeaderControls {
    display: none;
  }

  .modifiersHeaderOverflow,
  .pairingsHeaderOverflow {
    display: flex;
    align-items: center;
  }
}

.sharedItemIcon {
  @extend .sharedIconTitle;
}

.readonlyPriceContainer {
  padding: 6px 0;

  .readonlyPrice {
    font-weight: 700;
  }
}

// Recommended size from design
.priceInput {
  width: 186px;
}
