@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.itemDescription {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 12px 0;
}

.itemDescriptionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemName {
  @extend .paragraph-regular;
  flex-basis: 75%;
  padding-left: 12px;
}

.priceContainer {
  display: flex;
  justify-content: flex-end;
  flex-basis: 25%;
}

.itemQuantityContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 24px;
  height: 24px;
}

.itemQuantity {
  @extend .paragraph-bold;
}

.itemAmount {
  @extend .paragraph-bold;
  min-width: 60px;
  text-align: right;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6px;
}

.comment {
  display: flex;
  background-color: $lighter-yellow;
  padding: 0 6px;
  border-radius: 3px;
  align-self: flex-start;
}

.commentIcon {
  color: $yellow-text;
  margin-right: 6px;
  margin-top: 3px;
}

.commentText {
  @extend .small-paragraph-regular;
}

.itemLine,
.itemModifiers {
  border-bottom: 1px solid $background-gray;

  &:last-of-type {
    border-bottom: none;
  }
}

.itemLine {
  padding-left: 24px;
  padding-right: 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.itemModifiers {
  padding-left: 36px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 24px;
    padding-right: 12px;
  }
}

.modifierRowContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $background-gray;
  padding: 12px 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.modifierRow {
  display: flex;
  align-items: center;
  color: $text-gray;
}

.modifierQuantityContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 24px;
  height: 24px;
}

.modifierQuantity {
  @extend .paragraph-bold;
  color: $text-gray;
}

.modifierAmountContainer {
  margin-left: 6px;
  font-weight: $font-weight-semibold;
}

.modifierAmount {
  flex-shrink: 0;
}

.nestedModifier {
  margin-top: 12px;
  padding: 12px 0;
  border-top: solid $background-gray 1px;

  &:last-child {
    padding-bottom: 0;
  }
}

.nestedModifierDetails {
  display: flex;
  align-items: center;
  color: $text-gray;
}

.nestedIndentation {
  padding-left: 12px;
  display: flex;
  align-items: center;
}

.nestedIndicator {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 6px;
  border: solid $text-gray 1px;
}

.nestedIndicatorSolid {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-right: 6px;
  border: solid $text-gray 1px;
  background: $text-gray;
}
