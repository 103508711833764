@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px;
  gap: 6px;

  &:hover {
    background-color: $background-gray-op;
    cursor: pointer;
  }

  &:not(:last-of-type) {
    border-bottom: solid 1px $main-panel-gray;
  }

  .galleryIcon {
    color: $text-gray;
  }

  .overflowButton {
    margin-left: auto;
  }
}
