@import 'styles/colors.scss';
@import 'styles/typography.scss';

.container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 12px 0;
}

.btnContainer {
  margin-left: auto;
  padding-left: 6px;
}

.posInfo {
  display: flex;
  flex-direction: column;
}

.editButton {
  color: $dark-text-gray;
  font-size: 20px;
}

.posItemName {
  padding-right: 5px;
  padding-bottom: 3px;
}

.posItemId {
  color: $text-gray;
  font-size: small;
}

.posItemLinkText {
  margin-left: 6px;
}

.warning {
  @extend .paragraph-regular;
  color: $orange-tint;
}

.warningText {
  margin-left: 3px;
  margin-right: 6px;
}
