.icon-button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  i {
    max-width: 100%;
  }
}

.icon-button-frame {
  border-radius: 6px;
  width: 36px;
  height: 36px;

  &:hover {
    background-color: $background-gray;
  }

  &:active {
    background-color: $disabled-gray;
  }

  &:focus {
    background-color: $white;
    border: solid 1px $black;
  }

  span {
    color: $dark-text-gray;
    font-size: 20px;
  }
}
