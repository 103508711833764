.sectionControls {
  display: flex;
  align-items: center;
}

.button {
  margin-left: 24px;
}

.sectionOverflow {
  margin: 0 12px;
}
