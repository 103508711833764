@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.optionContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 72px;
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #eeeeee;
  
  @media screen and (max-width: $breakpoint-mobile) {
    padding-left: 15px;
    justify-content: flex-start;
  }
}

.optionContainer:hover {
  background-color: $background-gray-op;
  color: $magenta;
}

.nameColumn {
  padding: 12px 0;
  width: 80%;
  max-height: 72px;
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.nameBlock {
  overflow: hidden;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0;
  }
}

.otherDetailsColumn {
  display: flex;
  width: 32%;
  @media screen and (max-width: $breakpoint-mobile) {
    width: 60%;
  }
}

.assignedLocations,
.isMaxValue,
.isDefault {
  width: 180px;
  @media screen and (max-width: $breakpoint-mobile) {
    width: 80px;
  }
}

.isMaxValue,
.isDefault {
  @extend .small-paragraph-regular;
  color: $text-gray;
}
