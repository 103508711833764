@import '../../../styles/colors.scss';

.container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.items,
.itemsDragging {
  max-height: calc(75vh - 180px);
  overflow: auto;
}

.itemsDragging {
  background-color: $disabled-gray;
}

.item,
.itemDragging {
  height: 72px;
  border-bottom: 1px solid $background-gray;
  display: flex;
  align-items: center;
}

.item:last-of-type {
  border-bottom: none;
}

.item {
  background-color: $white;
}

.itemDragging {
  background-color: $main-panel-gray;
}

.dragHandle {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
}

.label {
  flex: 1;
  display: flex;
  align-items: center;
}

.controls {
  height: 96px;
  display: flex;
  align-items: center;
}

.cancel {
  flex: 33%;
  margin-right: 12px;
}

.save {
  flex: 67%;
}

.menuGalleryStarIcon {
  color: $dark-text-gray;
  margin-right: 6px;
}

.reorderItemDisabled {
  color: $text-gray;
  font-style: italic;
  margin-right: 6px;
}
