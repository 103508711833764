@import 'styles/colors.scss';
@import 'styles/responsive.scss';

.mobileToolbarHeader {
  width: inherit;
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: flex-end;

  .editButton {
    margin-right: 50px;
  }
  .overflowButton {
    position: absolute;
  }
}

.checkboxCell {
  width: 24px;
  position: inherit;
  @media screen and (max-width: $breakpoint-tablet) {
    left: 25px;
  }
}

.locationNameCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    display: inline-flex;
  }
}
// moving the status cell up a few pixels to align with the other cells in the row
.statusCell {
  transform: translateY(-3px);
  @media screen and (max-width: $breakpoint-mobile) {
    transform: translateY(0px);
  }
}

.scheduleLinkCell {
  .effect {
    font-weight: bold;
  }
}

.priceLevelCell {
  .priceLevel {
    color: $text-gray;
    white-space: nowrap;
  }
}

.none {
  font-style: italic;
  color: $text-gray;
}

.effectiveDatesCell {
  white-space: nowrap;

  @media screen and (max-width: $breakpoint-mobile) {
    white-space: unset;
  }
}
