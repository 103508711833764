@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.menuGallery {
  margin-bottom: 12px;
}

.menuGalleryControls {
  display: flex;
  padding-right: 12px;
  align-items: center;
}

.menuGalleryStarIcon {
  margin-right: 6px;
  color: $dark-text-gray;
}

.menuGalleryDescription {
  display: flex;
  align-items: center;
}

.menuGalleryDescriptionName {
  margin-right: 6px;
}

.menuGalleryDescriptionNameDisabled {
  margin-right: 6px;
  color: $text-gray;
  font-style: italic;
}

.menuGalleryEditButton {
  padding-right: 12px !important;
}

.descriptionSummary {
  border-bottom: 1px solid $background-gray;
}

.descriptionSummaryContent {
  @extend .paragraph-regular;
  padding: 0 24px 12px 24px;
  width: 50%;
  color: $text-gray;
}

.noItemsMessage {
  @extend .paragraph-regular;
  color: $text-gray;
  text-align: center;
  padding: 24px;
}

.noItemsMessage span {
  font-style: italic;
}

.viewContent {
  position: relative;
}

.viewContentLoading {
  background-color: $white !important;
  border-top: solid $background-gray 1px;
  img {
    top: 0 !important;
    width: 78px !important;
  }
}

.subitemVisibilityIcon {
  font-size: 18px;
  color: $dark-text-gray;
}

@media screen and (max-width: $breakpoint-mobile) {
  .descriptionSummaryContent {
    padding: 12px;
    width: 100%;
  }

  .noItemsMessage {
    padding: 24px 12px;
  }
}
