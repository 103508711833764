.radio-group {
  .radio {
    display: flex;
    height: 24px;
    cursor: pointer;

    .radio__element {
      display: block;
      width: 24px;
      height: 24px;
      border: solid $border-gray 1px;
      border-radius: 24px;
      overflow: hidden;
      padding: 5px;
      box-sizing: border-box;
      background-color: $white;

      i {
        display: block;
        width: 100%;
        height: 100%;
        background-color: $magenta;
        border-radius: 12px;
        opacity: 0;
      }
      &.radio__element--focus {
        border: solid $black 1px !important;
      }
      &.radio__element--selected {
        border: solid $lightmagenta 1px;
        background-color: $lightmagenta;
        i {
          opacity: 1;
        }
      }
    }
    .radio__label {
      @extend .paragraph-regular;
      margin-left: 12px;
    }
    input {
      opacity: 0;
    }
    &.radio--state-loading {
      opacity: 0.5 !important;
    }
    &.radio--disabled {
      .radio__element {
        border: solid $disabled-gray 1px;

        &.radio__element--selected {
          background-color: $disabled-gray;

          i {
            background-color: $dark-text-gray;
          }
        }
      }
      .radio__label {
        color: $text-gray;
        font-style: italic;
      }
      &,
      * {
        cursor: not-allowed !important;
      }
    }
  }
  &.radio-group--horizontal {
    display: flex;

    .radio {
      padding-right: 12px;
    }
  }
}

// container styles
.radio__container {
  padding: 10px 0;
}
