@import '../../styles/typography';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;
}

.section {
  margin: 6px 0;
}

.sectionPadding {
  padding-left: 24px;
  padding-right: 24px;
}

.contentContainer {
  @extend .section;
  @extend .sectionPadding;
}

.headerContainer,
.searchContainer,
.buttonContainer {
  @extend .section;
  @extend .sectionPadding;
}

.headerContainer {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
}

.buttonContainer {
  margin-bottom: 0;
}

.modalBody {
  padding: 0 !important;
}
