// The info card list assumes a list of <InfoCard /> components wrapped in link tags
$border-radius-first-child-value: 6px 6px 0 0;
$border-radius-last-child-value: 0 0 6px 6px;
$border-radius-only-child-value: 6px;

.info-card-list {
  a {
    &:first-child {
      .card-wrapper,
      .card-content {
        border-radius: $border-radius-first-child-value;
      }
    }
  }

  a {
    // if pagination is enabled, this style won't be applied since the pagination has a link tag in itself
    &:last-child {
      .card-wrapper,
      .card-content {
        border-radius: $border-radius-last-child-value;
      }
    }
  }

  a {
    &:only-child {
      .card-wrapper,
      .card-content {
        border-radius: $border-radius-only-child-value;
      }
    }
  }
}

.info-card-list > .card-wrapper:first-child,
.info-card-list > .card-wrapper:first-child > .card-content {
  border-radius: $border-radius-first-child-value;
}

.info-card-list > .card-wrapper:last-child,
.info-card-list > .card-wrapper:last-child > .card-content {
  border-radius: $border-radius-last-child-value;
}

.info-card-list > .card-wrapper:only-child,
.info-card-list > .card-wrapper:only-child > .card-content {
  border-radius: $border-radius-only-child-value;
}
