@import '../../styles/typography';
@import '../../styles/responsive';
@import '../../styles/colors';

.truncateText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  padding-bottom: 12px;

  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.headerContainer {
  display: flex;
  min-width: 0;
  justify-content: space-between;
  align-items: center;
}

.header {
  @extend .header-3;
  @extend .truncateText;
}

.assignButtonContainer {
  margin-left: 16px;
}

.assignButton {
  flex-shrink: 0;
}

.assignButtonLabelContainer {
  display: flex;
  justify-content: center;
  min-width: 0;
}

.assignButtonLabel {
  @extend .truncateText;
}

.assignButtonCount {
  flex-shrink: 0;
  padding-left: 6px;
}

.expandButton {
  font-size: 20px;
  color: $dark-text-gray;
}

@media screen and (max-width: $breakpoint-tablet) {
  .assignButtonContainer {
    margin-left: 0;
  }
}
