.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox__box {
    border: solid $border-gray 1px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-self: start;

    &.checkbox__box--focus {
      border: solid $black 1px !important;
    }
    i {
      color: $magenta;
      opacity: 0;
    }
  }
  .checkbox__label {
    @extend .paragraph-regular;
    display: flex;
    flex-direction: column;

    margin-left: 12px;

    .checkbox__label__additional-help {
      @extend .tiny-paragraph-regular;

      font-style: italic;
    }
  }
  input {
    opacity: 0;
  }
  &.checkbox--checked {
    .checkbox__box {
      border: solid $lightmagenta 1px;
      background-color: $lightmagenta;
      i {
        opacity: 1;
      }
    }
    &.checkbox--disabled {
      .checkbox__box {
        background-color: $disabled-gray;
        i {
          color: $dark-text-gray;
        }
      }
    }
  }
  &.checkbox--disabled {
    .checkbox__box {
      border: solid $disabled-gray 1px;
    }
    .checkbox__label {
      color: $text-gray;
      font-style: italic;
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
  &.checkbox--loading {
    opacity: 0.5 !important;
  }
  &.checkbox--state-error:not(.checkbox--disabled) {
    .checkbox__box {
      border: solid $orange-tint 1px;
      &.checkbox__box--focus {
        border: solid $orange-tint 1px !important;
      }
    }
    .checkbox__label {
      color: $orange-tint;
    }
  }
}

.checkbox__tooltip {
  padding-left: 3px;
}

// container styles
.checkbox-container {
  padding: 12px 0;
  display: flex;
  align-items: center;
}

.checkbox_input {
  display: none;
}
