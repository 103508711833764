@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.forcedMods {
  width: 100%;
  margin: 12px 0;
}

.forcedMod {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.forcedModDetails {
  @extend .paragraph-regular;
  display: flex;
  align-items: center;
}

.removeAndDollarContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.forcedModId {
  margin-left: 3px;
  color: $text-gray;
}

.forcedModOutOfStock {
  margin-left: 6px;
  height: 16px;
  border-radius: 3px;
  background-color: $orange-tint;
  padding: 0 6px;
  @extend .tiny-paragraph-bold;
  color: $white;
  white-space: nowrap;
}

.disabledBadge {
  display: inline;
  margin-left: 6px;
}

.forcedModPrice {
  text-align: right;
  @extend .paragraph-bold;

  &.disabled {
    color: $text-gray;
    font-style: italic;
  }
}

.priceDeleted {
  font-weight: bold;
  color: $orange-tint;
}

.posItemDeleted {
  display: inline-block;

  span {
    color: $orange-tint;
  }
}

.forcedModsEditLink {
  margin-top: 12px;
}

.modifierLink {
  color: #a7135b;
}

.editLinkButton {
  padding-top: 12px;
}
