.page-with-empty-result {
  background: $main-panel-gray;
  height: 100%;
  padding: 24px 48px;
  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    padding: 24px 24px;
  }
  // Mobile viewport
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 24px 12px;
  }
}
