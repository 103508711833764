@import 'styles/responsive.scss';

.draggableItemCard {
  display: flex;
  padding: 0 0 0 24px;
  align-items: center;
  flex: 1;
  max-width: 100%;
}
.imageAndDetails {
  display: flex;
  overflow: hidden;
}
.image {
  margin-right: 10px;
}
.details {
  max-width: 80%;
}

.button {
  border: solid;
  margin-right: 20px;
}

@media screen and (max-width: 750px) {
  .draggableItemCard {
    padding: 0 0 12px 0;
  }

  .imageAndDetails {
    align-items: center;
  }
}
