@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.container {
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;

  @media screen and (max-width: $breakpoint-tablet-mobile-midway) {
    flex-direction: column;
    margin-top: 12px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin-top: 24px;
  }
}

.header {
  @extend .header-5;
  flex-basis: 33.3%;
  padding-right: 6px;
  padding-top: 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-top: 0;
  }
}

.input {
  display: flex;
  flex-basis: 66.6%;
  padding-left: 6px;

  @media screen and (max-width: $breakpoint-tablet-mobile-midway) {
    padding-left: 0;
    padding-top: 12px;
  }
}

.editButton {
  flex-shrink: 0;
  margin-left: 12px;
}

.editIcon {
  font-size: 20px;
  color: $magenta;
}
