@import '../../styles/responsive.scss';

.container {
  display: flex;
  padding: 0 48px;
  align-items: flex-start;
}

.column {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}

.column > div:last-of-type {
  margin-bottom: 0;
}

.mainColumn {
  flex-grow: 2;
}

.column:first-child {
  padding-left: 0;
}

.column:last-child {
  padding-right: 0;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 0 24px;
  }

  .containerStacked {
    display: block;
  }

  .containerStacked > .column {
    margin-bottom: 24px;
  }

  .containerStacked > .column:last-of-type {
    margin-bottom: 0;
  }

  .margins {
    margin: 0 24px;
  }

  .column {
    padding: 0 12px;
  }

  .columnStacked {
    padding: 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    display: block;
    padding: 0 12px;
  }

  .margins {
    margin: 0 12px;
  }

  .column {
    padding: 0;
  }
}
