@import '../../../styles/colors';
@import '../../../styles/typography';

.field {
  margin: 12px 0;
  border: solid $disabled-gray 1px;
  padding: 18px 12px;
  border-radius: 6px;
  background-color: $light-gray;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
}

.fieldLabel {
  padding-top: 6px;
  display: block;
}

.fieldLabelText {
  font-weight: $font-weight-bold !important;
}

.fieldLabelTextDisabled {
  color: $disabled-gray;
}

.maxLengthInput {
  width: 160px;
}

.posId,
.posDetails {
  color: $text-gray;
}

.posDetails {
  @extend .small-paragraph-regular;
}
