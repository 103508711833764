@import 'styles/colors.scss';

.formRow {
  display: flex;
  height: fit-content;
}

.header {
  gap: 10px;
  justify-content: flex-start !important;
}

.subLabel {
  color: $text-gray;
}

.scheduleCheckbox {
  display: block;
}

.populatItemFormControl {
  height: 48px;
}
