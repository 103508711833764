@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.itemGalleryForm {
  background: $white;
  border: solid $disabled-gray 1px;
  margin-bottom: 12px;
  border-radius: 6px;
  overflow: hidden;
}

.top {
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
}

.topControls {
  width: 33%;
}

.topControls div {
  margin-top: 6px;
}

.topControls div:first-child {
  margin-top: unset;
}

.middle {
  border-bottom: solid $main-panel-gray 1px;
}

.noSections {
  border-top: solid $main-panel-gray 1px;
  display: flex;
  justify-content: center;
  padding: 24px;
}

.noSectionsButton {
  @extend .paragraph-regular;
  font-weight: $font-weight-bold;
  color: $magenta;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 6px;
}

.noSectionsText {
  @extend .paragraph-regular;
  color: $text-gray;
}

.noSectionsTextItems {
  font-style: italic;
}

.itemGalleryFormLoading {
  position: relative;
  height: 120px;
}

.itemGalleryFormLoadingSpinner {
  background-color: $white !important;
  img {
    width: 120px !important;
    top: 0 !important;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .topControls {
    width: 50%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .top {
    padding: 12px;
    flex-direction: column;
  }

  .topControls {
    width: 100%;
  }

  .noSections {
    padding: 12px;
  }

  .container {
    padding: 12px;
  }

  .controls {
    flex-direction: column;
  }
}
