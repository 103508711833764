.text-field-container {
  display: flex;
  align-items: center;
}

.text-field {
  @extend .paragraph-regular;
}

.text-field-additional {
  padding-left: 4px;
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  color: $text-gray;
}
