div.search {
  margin: 6px 0;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
