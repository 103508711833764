@import '../../../styles/colors';
@import '../../../styles/typography';
@import '../../../styles/responsive';
@import '../TableRow/TableRow.module';
.tableV2__rowSkeleton {
  &:hover {
    background: none;
  }
}
.tableV2__body--empty {
  @extend .tableV2__row;

  display: table-row-group;
  color: $text-gray;
  font-style: italic;
  font-weight: $font-weight-regular;
  border-bottom: solid $main-panel-gray 1px;
  height: 48px;
  &::after {
    @media screen and (max-width: $breakpoint-mobile) {
      content: none;
    }
  }
  &:hover {
    background-color: $white;
  }
  > * {
    display: table-cell;
    line-height: 48px;
    @media screen and (max-width: $breakpoint-tablet){
      padding-left: 24px;
    }
    @media screen and (max-width: $breakpoint-mobile){
      padding-left: 12px;
    }
  }
}
