* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
textarea {
  &:focus,
  &:active {
    outline: none;
  }
}
