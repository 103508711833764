// color palette
$black: #000000;
$black-op: #00000030;
$darkest-gray: #404040;
$dark-text-gray: #5c5c5c;
$text-gray: #767676;
$border-gray: #999999;
$disabled-gray: #d4d4d4;
$button-gray: #efefef;
$main-panel-gray: #ededed;
$background-gray: #eeeeee;
$background-gray-op: #eeeeee50;
$light-gray: #f3f3f3;
$white: #ffffff;
$white-op: #ffffff40;

$magenta-tint: #820f49;
$magenta-deep: #9a235a;
$magenta: #a70e5b;
$magenta-op: #a70e5b40;
$lightmagenta-tint: #e8b3d0;
$lightmagenta: #f7cbe3;
$lightmagenta-op: #f7cbe340;

$magentorange-100: #b73556;
$magentorange-200: #c94c4b;
$magentorange-300: #dd5f39;

$magenta-orange-tint: #b73556;
$magenta-orange: #c94c4b;
$orange-magenta-tint: #dd5f39;

$dark-orange: #782a00;
$orange-toast: #c25100;
$orange-tint: #a83b00;
$orange-tint-op: #a83b0040;
$orange: #f17106;
$lightorange-tint: #ff9e4d;
$light-orange: #ffd79e;

$yellow-tint: #c48d00;
$yellow-text: #bd8f2f;
$yellow: #efcb0b;
$lightyellow-tint: #ffdc24;
$light-yellow: #ffed8f;
$lighter-yellow: #fdee9b;

$green-tint: #237031;
$green: #81b822;
$lightgreen-tint: #a7d45b;
$lightgreen: #c8eb8d;
