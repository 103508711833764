.notification {
  width: 100%;
  min-height: 48px;
  border-radius: 6px;
  border: 1px solid;
  background: $background-gray;

  .notification__container {
    padding: 12px 24px;

    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      padding: 12px;
    }
  }

  i {
    line-height: 20px;
    margin-right: 6px;
  }

  .notification__text {
    @extend .small-paragraph-regular;
    font-weight: $font-weight-semibold;
    display: flex;

    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
    }

    &.notification__text--vertical {
      flex-direction: column;
    }
  }

  &.notification--alert {
    background: $light-orange;
    color: $orange-tint;
  }

  &.notification--warning {
    background: $lightmagenta;
    color: $magenta;
  }

  &.notification--info {
    background: $background-gray;
    color: $dark-text-gray;
  }
}
