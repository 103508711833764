@import '../../styles/typography.scss';
@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';

$border-radius: 6px;

.container {
  border-bottom: solid 1px $main-panel-gray;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  background-color: $white;
  display: flex;
  align-items: center;
  min-height: 72px;
}

.roundBottom {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.noResultsText {
  @extend .paragraph-regular;
  font-style: italic;
  color: $text-gray;
  margin: 0 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin: 0 12px;
  }
}
