.table {
  .table__row {
    display: flex;
    padding-left: 48px;
    padding-right: 48px;
    border-bottom: solid $main-panel-gray 1px;

    &.table__row--skeleton {
      cursor: unset !important;
      background: none !important;
      padding-top: 18px !important;
      padding-bottom: 17px !important;

      .skeleton {
        height: 12px;
        border-radius: 6px;
      }
      .table__row__cell span {
        display: block !important;
      }
    }
    &.table__row--has-link {
      padding: 0 !important;
    }
    .table__row__link {
      display: flex;
      padding-top: 12px;
      padding-bottom: 11px;
      padding-left: 48px;
      padding-right: 48px;
      flex: 1;
      color: inherit;
      text-decoration: none;
    }
    .table__row__cell {
      flex: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 6px;
      padding-right: 6px;
      min-width: 0;

      // Seems to be an existing bug in chrome. Setting this will
      // set the pre-flex width to zero, then it will grow to the
      // required width.
      // https://bugs.chromium.org/p/chromium/issues/detail?id=464210
      // https://bugs.chromium.org/p/chromium/issues/detail?id=240765
      width: 0px;

      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }

      img {
        height: 18px;
        width: 30px;
        vertical-align: text-bottom;
      }
      &.table__row__cell--align-left {
        text-align: start;
      }
      &.table__row__cell--align-center {
        text-align: center;
      }
      &.table__row__cell--align-right {
        text-align: end;
      }
      &.table__row__cell--hide-on-desktop {
        display: none;
      }
      &.table__row__cell--hide-on-mobile {
        display: inline;
      }
    }
  }
  .table__header {
    .table__row {
      @extend .tiny-paragraph-bold;
      color: $text-gray;
      text-transform: uppercase;
    }
  }
  .table__body {
    .table__row {
      @extend .paragraph-regular;
      color: $black;
      padding-top: 12px;
      padding-bottom: 11px;
      cursor: pointer;

      &:hover {
        background-color: $background-gray-op;
      }
      .table__row__cell {
        &:first-of-type {
          color: $magenta;
        }
        &.table__row__cell--disabled {
          color: $text-gray !important;
          font-style: italic;
        }
      }
    }
    .table__body__empty {
      background-color: $white;
      color: $text-gray;
      padding: 12px 48px;
      font-style: italic;
      font-weight: $font-weight-regular;
      border-bottom: solid $main-panel-gray 1px;
      height: 48px;
    }
  }
  .table__footer {
    .table__row {
      @extend .small-paragraph-regular;
      font-weight: $font-weight-semibold;
      padding-top: 12px;
      padding-bottom: 11px;

      .table__row__cell:last-of-type {
        text-align: right;

        a {
          color: $magenta;
          text-decoration: none;
          font-weight: $font-weight-regular;

          &:hover {
            text-decoration: underline;
          }
        }
        .table__row__cell__navigation__link--disabled {
          color: $text-gray;
          cursor: not-allowed;
          font-style: italic;
          font-weight: $font-weight-regular;
        }
        .table__row__cell__navigation__separator {
          margin: 0 8px;
          color: $main-panel-gray;
        }
      }
    }
  }

  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    .table__header {
      display: none;
    }
    .table__body {
      .table__row {
        padding: 12px 24px;
        flex-wrap: wrap;

        &.table__row--skeleton .skeleton {
          margin: 12px 0;
        }
        .table__row__link {
          flex-wrap: wrap;
          padding: 12px 24px;
        }
        .table__row__cell {
          flex: 50% !important;
          order: 4;
          padding-left: unset;
          padding-right: unset;

          &:first-of-type:not(.table__row__cell--manual-layout) {
            font-weight: $font-weight-bold;
            order: 1;
          }
          &:nth-of-type(even):not(.table__row__cell--manual-layout) {
            text-align: end;
          }
          // in card view: switch order between second and third cells
          &:nth-of-type(2):not(.table__row__cell--manual-layout) {
            order: 3;
            text-align: start;
          }
          &:nth-of-type(3):not(.table__row__cell--manual-layout) {
            order: 2;
            text-align: end;
          }
          &:last-of-type:not(.table__row__cell--manual-layout) {
            text-align: end;
          }
          &.table__row__cell--manual-layout-first-item {
            font-weight: $font-weight-bold;
            color: $magenta;
          }
          &.table__row__cell--hide-on-mobile {
            display: none;
          }
          &.table__row__cell--hide-on-desktop {
            display: inline;
          }
        }
      }
    }
    .table__footer {
      .table__row {
        padding: 12px 24px;
      }
    }
  }

  // Mobile viewport
  @media screen and (max-width: $breakpoint-mobile) {
    .table__body,
    .table__footer {
      .table__row,
      .table__row .table__row__link {
        padding: 12px;
      }
    }
  }
}
