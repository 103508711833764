.button {
  border-radius: 6px;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  width: 100%;

  &.button--primary {
    border: none;
    background-color: $magenta;
    color: $white;

    &:hover {
      background-color: $magenta-tint;
    }

    &.button--loading,
    &.button--unavailable {
      background-color: $magenta-op;
      color: $white;
    }
  }

  &.button--secondary {
    border: none;
    background-color: $lightmagenta;
    color: $magenta;

    &:hover {
      background-color: $lightmagenta-tint;
    }

    &.button--loading,
    &.button--unavailable {
      background-color: $lightmagenta-op;
      color: $magenta-op;
    }
  }

  &.button--secondary-gray-bg {
    border: solid $magenta 1px;
    background-color: $white;
    color: $magenta;

    &:hover {
      background-color: $lightmagenta-tint;
    }

    &.button--loading,
    &.button--unavailable {
      border: solid $magenta-op 1px;
      background-color: $white-op;
      color: $magenta-op;
    }
  }

  &.button--alert {
    border: none;
    background-color: $orange-tint;
    color: $white;

    &:hover {
      background-color: $dark-orange;
    }

    &.button--loading,
    &.button--unavailable {
      background-color: $orange-tint-op;
      color: $white-op;
    }
  }

  &.button--alert-secondary {
    border: 1px solid $orange-tint;
    background-color: transparent;
    color: $orange-tint;

    &:hover {
      border-color: $dark-orange;
      color: $dark-orange;
    }

    &.button--loading,
    &.button--unavailable {
      border-color: $orange-tint-op;
      color: $orange-tint-op;
    }
  }

  &.button--size-large {
    height: 40px;
    padding: 0 12px;
  }

  &.button--size-small {
    height: 36px;
    padding: 0 12px;
  }

  &.button--toggle-unselected,
  &.button--toggle-selected,
  &.button--toggle-colour-unselected,
  &.button--toggle-colour-selected {
    padding: 0 10px !important;

    &:focus {
      outline: none;
      box-shadow: 0 0 3px 0 $dark-text-gray;
    }
  }

  &.button--toggle-colour-unselected,
  &.button--toggle-unselected {
    color: $dark-text-gray;
    background-color: $white;
    border: solid $dark-text-gray 1px;

    &:hover {
      color: $darkest-gray;
      border: solid $darkest-gray 1px;
    }

    &:focus {
      color: $darkest-gray;
      border: solid $black 1px;
    }
  }

  &.button--toggle-selected {
    background-color: $dark-text-gray;
    color: $white;
    border: none;

    &:focus,
    &:hover {
      background-color: $darkest-gray;
    }
  }

  &.button--toggle-colour-selected {
    background-color: $magenta;
    color: $white;
    border: none;

    &:focus,
    &:hover {
      background-color: $magenta-tint;
    }
  }

  img {
    width: 18px;
    position: absolute;
    top: 25%;
    left: calc(50% - 9px);
    display: none;
  }

  &.button--loading,
  &.button--unavailable {
    cursor: not-allowed;
  }

  &.button--loading {
    img {
      display: initial;
    }
  }

  &.button--disabled,
  &.button--disabled:hover {
    border: none !important;
    background-color: $disabled-gray !important;
    color: $dark-text-gray !important;
    font-style: italic !important;
    cursor: not-allowed !important;

    img {
      display: none;
    }
  }

  i {
    margin-right: 6px;
    font-size: 16px;
  }
}

// container styles
.container.button-large-container {
  padding: 4px 0;
}
