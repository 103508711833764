@import '../../../styles/colors';
@import '../../../styles/responsive.scss';

.toggles {
  padding: 0 0 12px;
}

.container {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;
}

.button {
  margin-left: 24px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .button {
    margin-left: 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .button {
    margin-left: 12px;
  }
}
