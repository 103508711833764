@import '../../styles/colors';
@import '../../styles/typography';
@import '../../styles/responsive';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $disabled-gray;
  height: 48px;

  &:last-of-type {
    border-bottom: none;
  }

  padding: 0 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 12px;
  }
}

.tableLabelContainer {
  display: flex;
  padding-top: 12px;
  width: 100%;
}

.revenueCenter,
.tableName {
  @extend .paragraph-regular;
}

.tableName {
  width: 30%;
  text-align: left;
}

.removeIcon {
  font-size: 20px;
  color: $dark-text-gray;
}
