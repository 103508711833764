@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';
@import '../../styles/typography.scss';

.container {
  .title {
    position: relative;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      @extend .header-3;
    }

    .extra {
      @extend .small-paragraph-regular;
      font-style: italic;
      color: $text-gray;
    }
  }

  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    .title {
      .extra {
        position: static;
      }
    }
  }

  // Mobile viewport
  @media screen and (max-width: $breakpoint-mobile) {
    .title {
      display: block;
    }
  }
}
