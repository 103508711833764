@import '../../styles/typography.scss';
@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';

.panelLayoutColumn {
  min-width: 0;
  padding: 0 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px 0;
  }
}

.panelContent {
  padding-top: 12px;
  padding-bottom: 6px;
}

.itemHeader {
  display: flex;
  align-items: flex-end;
}

.itemHeaderText {
  @extend .header-3;
  color: $black;
  line-height: unset;
}

.itemHeaderCount {
  @extend .tiny-paragraph-regular;
  color: $text-gray;
  margin-left: 6px;
}
