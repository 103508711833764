@import 'styles/responsive.scss';
@import 'styles/colors.scss';

.noConfigMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $main-panel-gray;
  padding: 12px 24px;
  min-height: 72px;
  color: $text-gray;
}

.panelHeaderControlOverflowButton {
  display: none;
}

@media screen and (max-width: $breakpoint-mobile) {
  .panelHeaderControlButton {
    display: none;
  }
  .panelHeaderControlOverflowButton {
    display: block;
  }
}
