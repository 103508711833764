@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/z-index.scss';
@import '../../../styles/responsive.scss';
@import '../../../components/Skeleton/skeleton.scss';
@import '../../../components/TextInput/text-input.scss';

.emptyCell {
  color: $text-gray;
}

.inputLike.rowCell.editableCell {
  border: solid $border-gray 1px;
  border-radius: 6px;
  padding: 12px;
  height: 36px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 16px;
  color: $black;
  box-sizing: border-box;
  width: 156px;
  margin-top: 6px;
}

input.rowCell.editableCell {
  height: 36px;
  border-radius: 6px;

  &:focus {
    box-shadow: 0 0 3px 0 rgba(9, 9, 9, 1);
    border: solid 1px $black;
  }
}

.rowNoOrderType:focus-within,
.rowWithOrderType:focus-within {
  background-color: #f6f6f6;
}

.tableHeader .rowNoOrderType,
.tableHeader .rowWithOrderType {
  @extend .tiny-paragraph-bold;
  color: $text-gray;
  text-transform: uppercase;
  height: 24px;
}

.headerTitleText {
  @extend .header-3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headerControls {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.header {
  padding: 12px 24px;
  border-bottom: $background-gray solid 1px;
}

.titleRow {
  display: flex;
  justify-content: space-between;
}

.headerDescription {
  max-width: 700px;
}

.headerTitle {
  display: flex;
  align-items: center;
  width: 50%;
  flex-grow: 1;
  gap: 6px;
}

.rowLink {
  display: flex;
  padding: 12px 24px;
  flex: 1;
  color: inherit;
  text-decoration: none;
}

%grid-row {
  display: grid;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: solid $main-panel-gray 1px;
  background-color: $white;
  text-align: start;
}

%grid-cell {
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: inherit;
  height: 48px;
}

%grid-cell span {
  overflow: hidden;
}

.rowNoOrderType,
.rowWithOrderType {
  @extend %grid-row;
  grid-gap: 24px;
}

.rowNoOrderType {
  grid-template-areas: 'name id  rc  rcid bc tt';
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.rowWithOrderType {
  grid-template-areas: 'name id  rc  rcid bc pot tt';
  grid-template-columns: 2fr 2fr 3fr 2fr 4fr 5fr 2fr;
}

.rowSquarePOS {
  grid-template-areas: 'name bc tt ed';
  grid-template-columns: 4fr 4fr 1fr 1fr;
}

.tableHeader .rowCell {
  height: 24px !important;
}

.rowCell {
  min-width: 0;
  display: flex;
  width: 0;

  img {
    height: 18px;
    width: 30px;
    vertical-align: text-bottom;
  }
}

.rowCell div {
  display: none;
}

.rowCell:nth-child(1) {
  @extend %grid-cell;
  grid-area: name;
}

.rowCell:nth-child(2) {
  @extend %grid-cell;
  grid-area: id;
}

.rowCell:nth-child(3) {
  @extend %grid-cell;
  grid-area: rc;
}

.rowCell:nth-child(4) {
  @extend %grid-cell;
  grid-area: rcid;
}

.rowCell:nth-child(5) {
  @extend %grid-cell;
  grid-area: bc;
}

.rowWithOrderType {
  .rowCell:nth-child(6) {
    @extend %grid-cell;
    grid-area: pot;
  }

  .rowCell:nth-child(7) {
    @extend %grid-cell;
    grid-area: tt;
  }
}

.rowNoOrderType {
  .rowCell:nth-child(6) {
    @extend %grid-cell;
    grid-area: tt;
  }
}

.rowSquarePOS {
  .rowCell:nth-child(2) {
    @extend %grid-cell;
    grid-area: bc;
  }
  .rowCell:nth-child(3) {
    @extend %grid-cell;
    grid-area: tt;
  }
  .rowCell:nth-child(4) {
    @extend %grid-cell;
    grid-area: ed;
    justify-content: flex-end;
  }
  :nth-child(5) {
    display: none;
  }
}

.rowSkeleton {
  cursor: unset !important;
  background: none !important;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.rowSkeleton .rowCell {
  height: 12px !important;
}

.rowSkeleton div {
  @extend .skeleton;
  display: block !important;
  height: 12px;
  border-radius: 6px;
}

.rowSkeleton .rowCell span {
  display: block !important;
}

.hasLink {
  padding: 0 !important;
}

.tableBody .rowNoOrderType,
.tableBody .rowWithOrderType {
  @extend .paragraph-regular;
  color: $black;
  cursor: default;
}

.tableBody .hasLink:hover,
.tableBody .hasLink:hover {
  background-color: $background-gray-op;
}

.tableBody .rowNoOrderType .rowCell.disabled,
.tableBody .rowWithOrderType .rowCell.disabled {
  color: $text-gray !important;
  font-style: italic;
}

.empty {
  background-color: $white;
  color: $text-gray;
  padding: 12px 24px;
  font-style: italic;
  font-weight: $font-weight-regular;
  border-bottom: solid $main-panel-gray 1px;
  height: 48px;
}

.hasNoQuery {
  text-align: center;
  padding: 24px;
  font-size: 16px;
  color: $text-gray;
}

.footer .rowNoOrderType,
.footer .rowWithOrderType {
  display: grid;
  padding-left: 24px;
  padding-right: 24px;
  background-color: $white;
  text-align: start;
  grid-template: 'left right' 10fr / 8fr 72px;
  border-radius: 0 0 6px 6px;
}

.footer .rowCell {
  min-width: 0;
  display: flex;
  width: 0;
}

.footer .rowCell:nth-child(1) {
  @extend %grid-cell;
  grid-area: left;
  font-weight: $font-weight-semibold;
}

.footer .rowCell:nth-child(2) {
  @extend %grid-cell;
  grid-area: right;
}

.footer .rowNoOrderType .rowCell,
.footer .rowWithOrderType .rowCell {
  font-size: 14px;
}

.footer .rowNoOrderType .rowCell:last-of-type a,
.footer .rowWithOrderType .rowCell:last-of-type a {
  color: $magenta;
  text-decoration: none;
  font-weight: $font-weight-regular;
}

.footer .rowNoOrderType .rowCell:last-of-type a:hover,
.footer .rowWithOrderType .rowCell:last-of-type a:hover {
  text-decoration: underline;
}

.footer .rowNoOrderType .rowCell:last-of-type .linkDisabled,
.footer .rowWithOrderType .rowCell:last-of-type .linkDisabled {
  color: $text-gray;
  cursor: not-allowed;
  font-style: italic;
  font-weight: $font-weight-regular;
}

.footer .rowNoOrderType .rowCell:last-of-type .navigationSeparator,
.footer .rowWithOrderType .rowCell:last-of-type .navigationSeparator {
  margin: 0 8px;
  color: $main-panel-gray;
}

@media screen and (max-width: $breakpoint-tablet) {
  .rowNoOrderType,
  .rowWithOrderType {
    grid-gap: 0;
    .rowCell div {
      display: inline-block;
      color: $text-gray;
      padding-left: 6px;
    }
  }

  .rowNoOrderType {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .rowWithOrderType {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .rowCell.editableCell:nth-child(3) {
    width: 80%;
  }

  .inputLike.rowCell.editableCell > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .rowNoOrderType,
  .rowWithOrderType {
    background-color: $white;
    text-align: start;
    border-bottom: solid $main-panel-gray 1px;
    padding: 6px 12px;
    grid-gap: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .rowNoOrderType {
    grid-template-areas:
      'name'
      'rc'
      'bc'
      'tt';
  }

  .rowWithOrderType {
    grid-template-areas:
      'name'
      'rc'
      'bc'
      'pot'
      'tt';
  }

  .rowSquarePOS {
    grid-template-areas:
      'name tt of'
      'bc tt of';
  }

  .rowCell div {
    display: inline-block;
    color: $text-gray;
    padding-left: 6px;
  }
  .rowCell:nth-child(1) {
    grid-area: name;
    height: 24px;
  }
  .rowCell:nth-child(2) {
    grid-area: rc;
    height: 24px;
  }
  .rowCell.editableCell:nth-child(3) {
    grid-area: bc;
    width: 50%;
  }
  .rowWithOrderType {
    div:nth-child(4) {
      grid-area: pot;
      width: 75%;
    }
    .rowCell:nth-child(5) {
      grid-area: tt;
      height: 30px;
    }
  }
  .rowNoOrderType {
    .rowCell:nth-child(4) {
      grid-area: tt;
      height: 30px;
    }
  }

  .rowSquarePOS {
    .rowCell:nth-child(2) {
      width: 50%;
      height: 48px;
    }
    :nth-child(3) {
      margin-right: 48px;
    }
    .rowCell:nth-child(4) {
      display: none;
    }
    :nth-child(5) {
      grid-area: of;
      margin-top: 6px;
      display: flex;
      :nth-child(2) {
        top: 32px;
        :nth-child(1) {
          margin-right: 24px;
        }
      }
    }
  }

  .tableHeader .rowNoOrderType,
  .tableHeader .rowWithOrderType {
    display: none;
  }
  .footer .rowNoOrderType,
  .footer .rowWithOrderType {
    padding: 6px 12px;
  }
  .header {
    padding: 6px 12px;
  }
  .rowSkeleton .rowCell {
    margin: 2px;
  }
}
