@import 'styles/responsive.scss';
@import 'styles/colors.scss';
@import 'styles/typography.scss';

.container {
  margin: 0 48px;

  @media screen and (max-width: $breakpoint-tablet) {
    margin: 0 24px;
  }
}

.displayName {
  font-weight: 600;
}

.title {
  @extend .header-2;

  padding-left: 8px;
}

.nameCell {
  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 50px;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    max-width: 312px;
    width: 312px;
  }
}

.leftBorder {
  overflow: unset;

  @media screen and (min-width: $breakpoint-mobile) {
    border-left: 1px solid #eeeeee;
    padding-left: 24px !important; // override table styling
  }
}

.galleryIcon {
  margin-right: 6px;
  color: $dark-text-gray;
  font-size: 28px;
}

.checkboxCell {
  padding-left: 50px;
}

.bodyCell {
  // adds border to left side of 3rd cell (right side of location name cell)
  &:nth-of-type(3) {
    border-left: 1px solid #eeeeee;
    padding-left: 24px;
  }
}
