@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.panel {
  width: 100%;
  display: flex;
  border-radius: 6px;
  background-color: $background-gray;
  padding: 12px 12px 12px 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.controlContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.timeRangeContainer {
  display: flex;
  margin-top: 24px;
}

.timeInputContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 92px;
  max-width: 156px;
  margin-right: 12px;

  &:last-of-type {
    margin-right: 0;
  }
}

.timeInputLabel {
  @extend .paragraph-bold;
}

.timeInput {
  background-color: $white;
  width: 100% !important;
  justify-content: flex-start !important;
  padding-left: 12px;
  padding-right: 12px;
}

.errorMessage {
  @extend .tiny-paragraph-bold;
  display: flex;
  color: $orange-tint;
  line-height: 12px;
}

.dayOfWeekContainer {
  display: flex;
}

.clearButtonContainer {
  padding-top: 6px;
  padding-bottom: 6px;
}

.clearButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.clearIcon {
  @extend .paragraph-regular;
  color: $text-gray;
}
