@import '../../styles/colors.scss';

.container {
  display: flex;
}

.tagIconCenter {
  padding: 30px 0 0 12px;
}

.warning {
  background-color: $background-gray;
  padding: 12px 12px 14px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: $dark-text-gray;
}
