.extraLarge {
  width: 96px;
  height: 96px;
}

.large {
  width: 48px;
  height: 48px;
}

.medium {
  width: 24px;
  height: 24px;
}

.small {
  width: 16px;
  height: 16px;
}
