@import 'styles/colors.scss';

.container {
  display: flex;
  align-items: center;
  gap: 6px;

  .check {
    color: $green;
  }

  .remove {
    color: $text-gray;
  }

  .uncheckedLabel {
    color: $text-gray;
    font-style: italic;
  }
}
