@import '../../../../styles/colors';
@import '../../../../styles/responsive';
@import '../../colors';

.ratingItem {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-template-columns: 38px auto 40px;

  > * {
    display: flex;
    line-height: 14px;
  }
  &:not(&:last-child) {
    margin-bottom: 8px;
  }
  &__star,
  &__starsIcon {
    color: $dark-text-gray;
    font-size: 16px;
  }
  &__starsCount {
    width: 36px;
  }
  &__starIcon {
    margin: auto 0 auto 8px;
  }
  &__rating {
    position: relative;
    height: 14px;
    margin: auto 12px auto 12px;
    border-radius: 40px;
    border: solid 1px $disabled-gray;
    background-color: $main-panel-gray;
    &Wrapper {
      position: absolute;
      left: -1px;
      top: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border-radius: 40px;
      overflow: hidden;
    }
  }
  &__ratingFill {
    height: 100%;
    border-radius: 40px;
    transition: width 2s;
    &--color {
      @each $index, $color in $feedback-colors {
        &--#{$index} {
          background-color: $color;
        }
      }
    }
  }
}
.starsRating {
  &__midRating {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: $breakpoint-mobile) {
      margin: auto auto 28px auto;
    }
    &Val {
      font-size: 60px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    &Stars--disabled {
      & > .starsHistogram__star {
        color: $background-gray;
      }
    }
  }
  &__star {
    font-size: 20px;
    &:not(&:last-child) {
      margin-right: 10px;
    }
    &--disabled {
      color: $text-gray;
    }
  }
  &__counts {
    margin-left: 24px;
    width: calc(75% - 12px);
    display: table;
    @media (max-width: $breakpoint-tablet) {
      margin: 24px 0 auto 0;
      width: 100%;
    }
    @media screen and (max-width: $breakpoint-mobile) {
      min-width: unset;
      margin: auto;
    }
  }
  &__body {
    display: flex;
    @media (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile + 1px) {
      flex-direction: column;
    }
  }
}
