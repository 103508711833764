.controlWidth {
  width: 33%;
  margin-right: 12px;
}

.defaultCustomTip {
  width: 100%;
}

.defaultTipValueContainer {
  display: flex;
  .controlWidth:last-child {
    margin-right: 0px;
  }
}
