.centered-panel {
  font-family: $font-family;
  background: $background-gray;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .centered-panel__container {
    background: $white;
    flex: 1;
    max-width: 570px;
    padding: 24px 48px 48px;
    box-sizing: border-box;
    border-radius: 12px;
    border: solid $disabled-gray 1px;
  }

  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    .centered-panel__container {
      margin: 0 24px;
      padding: 24px;
    }
  }

  // Mobile viewport
  @media screen and (max-width: $breakpoint-mobile) {
    .centered-panel__container {
      margin: 0 12px;
    }
  }
}
