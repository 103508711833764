@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.container {
  display: flex;
  align-items: baseline;
  margin: 0;
  overflow: hidden;
  min-width: 0;
}

.title {
  @extend .header-3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.magenta {
  color: $magenta;
}

.gray {
  color: $text-gray;
  font-style: italic;
}
