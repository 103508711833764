@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.title {
  display: flex;
  align-items: center;
  gap: 6px;
}

.container {
  padding: 12px 48px 0;
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0;
  }
}

.tableLayout {
  margin: 0;
}

.toolbar {
  display: flex;
  gap: 12px;
  width: 100%;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    gap: unset;
  }

  .locationFilter {
    flex-basis: 425px;
    @media screen and (max-width: $breakpoint-mobile) {
      flex-basis: unset;
    }
  }

  .linkedStatusFilter {
    flex-basis: 200px;
    @media screen and (max-width: $breakpoint-mobile) {
      flex-basis: unset;
    }
  }
}

.notAssigned {
  color: $text-gray;
  font-style: italic;
  font-weight: $font-weight-regular;
  border-bottom: solid $main-panel-gray 1px;
  text-align: center;
  padding: 26px;
}

.mobileGap {
  display: none;
  @media screen and (max-width: $breakpoint-mobile) {
    display: block;
    height: 12px;
    background-color: $background-gray;
  }
}
