// Typography variables
$font-family: 'Source Sans Pro', Arial;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Font style classes
.header-1 {
  font-family: $font-family;
  font-size: 32px;
  line-height: 48px;
  font-weight: $font-weight-regular;
}
.header-2 {
  font-family: $font-family;
  font-size: 28px;
  line-height: 48px;
  font-weight: $font-weight-regular;
}
.header-3 {
  font-family: $font-family;
  font-size: 24px;
  line-height: 48px;
  font-weight: $font-weight-semibold;
}
.header-4 {
  font-family: $font-family;
  font-size: 20px;
  line-height: 24px;
  font-weight: $font-weight-semibold;
}
.header-5 {
  font-family: $font-family;
  font-size: 16px;
  line-height: 24px;
  font-weight: $font-weight-bold;
}
.header-6 {
  font-family: $font-family;
  font-size: 14px;
  line-height: 24px;
  font-weight: $font-weight-bold;
}
.tiny-title {
  font-family: $font-family;
  font-size: 12px;
  line-height: 24px;
  font-weight: $font-weight-bold;
}
.paragraph-regular,
.paragraph-bold {
  font-family: $font-family;
  font-size: 16px;
  line-height: 24px;
}
.paragraph-regular {
  font-weight: $font-weight-regular;
}
.paragraph-bold {
  font-weight: $font-weight-bold;
}
.small-paragraph-regular,
.small-paragraph-bold {
  font-family: $font-family;
  font-size: 14px;
  line-height: 24px;
}
.small-paragraph-regular {
  font-weight: $font-weight-regular;
}
.small-paragraph-bold {
  font-weight: $font-weight-bold;
}
.tiny-paragraph-regular,
.tiny-paragraph-bold {
  font-family: $font-family;
  font-size: 12px;
  line-height: 24px;
}
.tiny-paragraph-regular {
  font-weight: $font-weight-regular;
}
.tiny-paragraph-bold {
  font-weight: $font-weight-bold;
}
