@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.section {
  margin: 0 24px;
  padding: 12px 0;

  &:last-of-type {
    border-bottom: none;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.containerSection {
  @extend .section;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $background-gray;
}

.orderSummary {
  @extend .section;
}

.locationName {
  @extend .tiny-paragraph-bold;
  color: $text-gray;
  margin-bottom: 24px;
}

.orderPlaced {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.orderInfo {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-tablet-mobile-midway) {
    flex-direction: column;
  }
}

.statusInfo {
  flex-shrink: 0;
  flex-grow: 0;

  @media screen and (max-width: $breakpoint-tablet-mobile-midway) {
    margin-top: 24px;
  }
}

.refundRow {
  @extend .paragraph-regular;
  @extend .section;
  display: flex;
  justify-content: space-between;
}

.refundIcon {
  margin-right: 12px;
}

.locationIcon {
  margin-right: 3px;
}
