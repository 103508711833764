.drop-down-menu {
  width: 100%;
  position: relative;

  .drop-down-menu__options {
    border: solid $disabled-gray 1px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
    position: absolute;
    top: 6px;
    min-width: 140px;
    max-width: 250px;
    display: none;
    z-index: $drop-down-menu-z-index;

    &.drop-down-menu__options--visible {
      display: block;
    }
    &.drop-down-menu__options-snap-left {
      border-radius: 0 6px 6px 6px;
      left: 6px;
    }
    &.drop-down-menu__options-snap-right {
      border-radius: 6px 0 6px 6px;
      right: 6px;
    }
    .drop-down-menu__option {
      display: block;
      width: 100%;
      font-family: $font-family;
      @extend .paragraph-regular;
      background: $white;
      border: none;
      border-bottom: solid $disabled-gray 1px;
      padding: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      text-align: left;
      color: $black;

      img {
        width: 32px;
        display: initial;
        overflow: visible;
        vertical-align: middle;
      }

      &:hover {
        background: $light-gray;
      }
      &.drop-down-menu__option--primary {
        color: $magenta;
      }
      &.drop-down-menu__option--active {
        font-weight: $font-weight-bold;
        color: $magenta;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &.drop-down-menu__option--loading,
      &.drop-down-menu__option--loading:hover {
        display: inline;
        color: $border-gray !important;
        font-style: italic;
        cursor: not-allowed;
        padding: 8px 12px 8px 4px;
      }
      &.drop-down-menu__option--disabled,
      &.drop-down-menu__option--disabled:hover {
        border: none !important;
        color: $border-gray !important;
        font-style: italic !important;
        cursor: not-allowed !important;
      }
    }
  }
}
