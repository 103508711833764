@import '../../styles/colors.scss';

$border-radius: 6px;
$border-arrow-size-large: 12px;
$border-arrow-size-small: 6px;
$border-arrow-margin-large: 24px;
$border-arrow-margin-small: 18px;

$border-arrow-large-transparent: $border-arrow-size-large solid transparent;
$border-arrow-large: $border-arrow-size-large solid;

$border-arrow-small-transparent: $border-arrow-size-small solid transparent;
$border-arrow-small: $border-arrow-size-small solid;

.container {
  display: flex;
  flex-grow: 1;

  &.vertical {
    flex-direction: column;
  }
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  border-radius: $border-radius;
  padding: 12px;

  &.gray {
    background-color: $background-gray;
  }
  &.black {
    background-color: $black;
    color: $white;
  }
}

.arrow {
  width: 0;
  height: 0;

  &.top {
    &.small {
      border-left: $border-arrow-small-transparent;
      border-right: $border-arrow-small-transparent;
      border-bottom: $border-arrow-small;
    }
    &.large {
      border-left: $border-arrow-large-transparent;
      border-right: $border-arrow-large-transparent;
      border-bottom: $border-arrow-large;
    }

    &.gray {
      border-bottom-color: $background-gray;
    }
    &.black {
      border-bottom-color: $black;
    }

    &.atStart {
      align-self: flex-start;
      &.small {
        margin-left: $border-arrow-margin-small;
      }
      &.large {
        margin-left: $border-arrow-margin-large;
      }
    }
    &.atEnd {
      align-self: flex-end;
      &.small {
        margin-right: $border-arrow-margin-small;
      }
      &.large {
        margin-right: $border-arrow-margin-large;
      }
    }
  }

  &.right {
    &.small {
      border-top: $border-arrow-small-transparent;
      border-bottom: $border-arrow-small-transparent;
      border-left: $border-arrow-small;
    }
    &.large {
      border-top: $border-arrow-large-transparent;
      border-bottom: $border-arrow-large-transparent;
      border-left: $border-arrow-large;
    }

    &.gray {
      border-left-color: $background-gray;
    }
    &.black {
      border-left-color: $black;
    }

    &.atStart {
      align-self: flex-start;
      &.small {
        margin-top: $border-arrow-margin-small;
      }
      &.large {
        margin-top: $border-arrow-margin-large;
      }
    }
    &.atEnd {
      align-self: flex-end;
      &.small {
        margin-bottom: $border-arrow-margin-small;
      }
      &.large {
        margin-bottom: $border-arrow-margin-large;
      }
    }
  }

  &.container {
    &.small {
      border-left: $border-arrow-small-transparent;
      border-right: $border-arrow-small-transparent;
      border-top: $border-arrow-small;
    }
    &.large {
      border-left: $border-arrow-large-transparent;
      border-right: $border-arrow-large-transparent;
      border-top: $border-arrow-large;
    }

    &.gray {
      border-top-color: $background-gray;
    }
    &.black {
      border-top-color: $black;
    }

    &.atStart {
      align-self: flex-start;
      &.small {
        margin-left: $border-arrow-margin-small;
      }
      &.large {
        margin-left: $border-arrow-margin-large;
      }
    }
    &.atEnd {
      align-self: flex-end;
      &.small {
        margin-right: $border-arrow-margin-small;
      }
      &.large {
        margin-right: $border-arrow-margin-large;
      }
    }
  }

  &.left {
    &.small {
      border-top: $border-arrow-small-transparent;
      border-bottom: $border-arrow-small-transparent;
      border-right: $border-arrow-small;
    }
    &.large {
      border-top: $border-arrow-large-transparent;
      border-bottom: $border-arrow-large-transparent;
      border-right: $border-arrow-large;
    }

    &.gray {
      border-right-color: $background-gray;
    }
    &.black {
      border-right-color: $black;
    }

    &.atStart {
      align-self: flex-start;
      &.small {
        margin-top: $border-arrow-margin-small;
      }
      &.large {
        margin-top: $border-arrow-margin-large;
      }
    }
    &.atEnd {
      align-self: flex-end;
      &.small {
        margin-bottom: $border-arrow-margin-small;
      }
      &.large {
        margin-bottom: $border-arrow-margin-large;
      }
    }
  }
  &.bottom {
    &.small {
      border-right: $border-arrow-small-transparent;
      border-left: $border-arrow-small-transparent;
      border-top: $border-arrow-small;
    }
    &.large {
      border-right: $border-arrow-large-transparent;
      border-left: $border-arrow-large-transparent;
      border-top: $border-arrow-large;
    }
    &.gray {
      border-top-color: $background-gray;
    }
    &.black {
      border-top-color: $black;
    }

    &.atStart {
      align-self: flex-start;
      &.small {
        margin-right: $border-arrow-margin-small;
      }
      &.large {
        margin-right: $border-arrow-margin-large;
      }
    }
    &.atEnd {
      align-self: flex-end;
      &.small {
        margin-right: $border-arrow-margin-small;
      }
      &.large {
        margin-right: $border-arrow-margin-large;
      }
    }
  }
}
