@import '../../styles/responsive';
@import '../../styles/colors';

.table {
  background-color: white;
}

.headerControls {
  display: flex;
  width: 300px;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.CellNowrap {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    white-space: nowrap;
  }
}
.CellPrewrap {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    white-space: pre-wrap;
  }
}
.paddingLeftDesktop50 {
  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 50px;
  }
}
.HeaderCellShorted50 {
  width: 50px;
}
.HeaderCellShorted500 {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    width: 500px;
    min-width: 200px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .MobileHeaderCell {
    width: 175px;
    padding-right: 24px;
  }
}

.PosDiscountNull {
  color: $orange-tint;
  & i {
    margin-right: 3px;
  }
}

.NoDescription {
  font-style: italic;
  color: $text-gray;
}

.selectable {
  color: $magenta;
}

.CheckboxCell {
  width: 10px;
  padding-right: 100px;
}
