.toggle {
  justify-content: space-between;
}

.singleCheckPos {
  padding-left: 36px;
}

.itemCommentsCharLimits {
  padding-bottom: 6px;
}
