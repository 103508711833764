@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.container {
  padding: 12px 48px 0;
}

.item:hover {
  background-color: $background-gray-op;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.search {
  width: 492px;
}

.overflow,
.tabletOverflow {
  display: none;
}

.filter {
  flex-shrink: 0;
  margin-left: 12px;
  width: 156px;
}

.toggles {
  flex-shrink: 0;
  padding-left: 12px;
  width: 270px;
}

.buttons {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttons > * {
  margin-left: 12px !important;
}

.noResults {
  color: $text-gray;
  font-style: italic;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px 0;
  }

  .search {
    width: 258px;
  }

  .buttons {
    display: none;
  }

  .tabletOverflow {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 12px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px 0;
  }

  .controls {
    flex-direction: column;
  }

  .search {
    width: 100%;
    display: flex;
  }

  .filter {
    width: 100%;
    margin-top: 6px;
    margin-left: 0;
  }

  .overflow {
    display: flex;
    margin-left: 12px;
  }

  .tabletOverflow {
    display: none;
  }

  .toggles {
    width: 100%;
    padding: 6px 0 0;
  }
}
