@import '../../../styles/colors';
@import '../../../styles/responsive';

.cellMobileLayoutWrapper {
  & > .cellMobileLayoutWrapped {
    @media screen and (max-width: $breakpoint-mobile) {
      padding-right: 18px;
    }
  }
}
.headerCell {
  @media screen and (min-width: $breakpoint-mobile + 1px) {
    &--location {
      min-width: 156px;
      width: 156px;
    }
    &--date {
      min-width: 180px;
      width: 180px;
    }
    &--email {
      min-width: 174px;
      width: 174px;
    }
  }
}

.checksListing {
  overflow: auto;
  background: $white;
}
