.colorControls {
  display: flex;
}

.backgroundColorControl {
  margin-left: 12px;
}

.preview {
  display: flex;
  margin-top: 12px;
}
