@import 'styles/typography.scss';

.transactionAmount {
  @extend .header-2;
  font-weight: $font-weight-semibold;
  margin-right: 6px;
}

.locationDetails {
  @extend .paragraph-regular;
}

