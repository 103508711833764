@import '../../../styles/colors.scss';

.tagElement {
  display: flex;
  align-items: center;
  background: $background-gray;
  padding: 4px 6px;
  border-radius: 18px;

  &.readonly {
    padding-right: 9px;
  }
}

.tagElementDetails {
  display: flex;
  align-items: center;
  color: $text-gray;
  gap: 6px;
}

.removeButton {
  border: none;
  background: transparent;
  cursor: pointer;
}

.removeIcon {
  color: $text-gray;
  font-size: 18px;

  &:hover {
    color: $darkest-gray;
  }
}
