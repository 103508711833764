@import '../../../styles/colors.scss';

.itemImage,
.modifierImage {
  width: 48px;
  height: 48px;
  background-color: $background-gray;
  color: $text-gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.itemImage,
.itemOutOfStock {
  border-radius: 3px;
}

.modifierImage,
.modifierOutOfStock {
  border-radius: 24px;
}

.modifierImage {
  font-size: 12px;
}

.outOfStock {
  position: relative;
  width: 48px;
  height: 48px;
  top: -48px;
  margin-bottom: -48px;
  border-width: 2px 2px 0;
  border-color: $orange;
  border-style: solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.outOfStockIndicator {
  width: 48px;
  height: 14px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $orange-tint;
  color: $white;
  font-size: 10px;
  text-align: center;
}
