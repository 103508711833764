@import '../../styles/typography.scss';
@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';

.layoutContent {
  margin: 24px 48px 72px 48px;
  background-color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: solid $disabled-gray 1px;
}

.header {
  padding: 12px 24px;
  border-bottom: $background-gray solid 1px;
  display: flex;
  justify-content: space-between;
}

.headerTitle {
  display: flex;
  align-items: center;
  width: 50%;
  flex-grow: 1;
}

.headerTitleText {
  @extend .header-3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headerControls {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

.toolbar {
  padding: 12px 24px;
  border-bottom: $background-gray solid 1px;
  display: flex;
}

.mainContentWrapper {
  height: 100%;
}

@media screen and (max-width: $breakpoint-tablet) {
  .layoutContent {
    margin-left: 24px;
    margin-right: 24px;
  }

  .header {
    padding: 12px 24px;
  }

  .toolbar {
    padding: 6px 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 0 12px;
  }

  .layoutContent {
    margin-left: 12px;
    margin-right: 12px;
  }

  .headerTitle {
    padding: 6px 0;
  }

  .headerTitleText {
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-bold;
  }

  .headerControls {
    top: -2px;
    right: 12px;
  }

  .toolbar {
    padding: 6px 12px;
    display: block;
  }
}
