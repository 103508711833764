@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container {
  display: flex;
  align-items: center;
}

.label {
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  margin-right: 12px;
}

.boldLabel {
  font-weight: $font-weight-bold;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    align-items: flex-start;
    flex-direction: column;
  }
}
