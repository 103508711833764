@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.statusIndicator {
  span {
    color: $black;
    font-style: normal;
  }
}

.shortCodeContainer {
  display: flex;
  align-items: center;
}

.readOnlyInputField {
  background-color: $background-gray;
  border: none !important;
}
.textInputsContainer {
  input {
    color: $dark-text-gray;
  }
}
.detailElementLabel {
  margin-bottom: 12px;
}
.textInputLabel {
  padding-top: 32px !important;
}
