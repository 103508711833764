@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

.badge {
  @extend .tiny-title;
  border: solid $black 1px;
  border-radius: 3px;
  color: $black;
  padding: 0 6px;
  text-transform: uppercase;
}

.badgeIcon {
  margin-right: 6px;
}

.gray {
  border-color: $darkest-gray;
  color: $darkest-gray;
}
.graySolid {
  border-color: $darkest-gray;
  background-color: $darkest-gray;
  color: $white;
}

.disabledGraySolid {
  border-color: $disabled-gray;
  background-color: $disabled-gray;
  color: $dark-text-gray;
}

.green {
  border-color: $green;
  color: $green;
}
.greenSolid {
  border-color: $green;
  background-color: $green;
  color: $white;
}

.magenta {
  border-color: $magenta;
  color: $magenta;
}
.magentaSolid {
  border-color: $magenta;
  background-color: $magenta;
  color: $white;
}

.orange {
  border-color: $orange;
  color: $orange;
}
.orangeSolid {
  border-color: $orange;
  background-color: $orange;
  color: $white;
}

.yellowSolid {
  border-color: $yellow;
  background-color: $yellow;
  color: $black;
}
