.formatted-input {
  display: flex;
  border: solid $border-gray 1px;
  border-radius: 6px;
  font-family: $font-family;
  overflow: hidden;
  height: 36px;

  &.formatted-input--focus,
  &:active,
  &:focus {
    border: solid $black 1px;
  }
  .formatted-input__input {
    padding: 12px;
    height: 100%;
    width: 100%;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 16px;
    color: $black;
    box-sizing: border-box;
    border: none;

    &::placeholder {
      color: $text-gray;
    }

    &.formatted-input__input--money {
      text-align: right;
    }

    &.formatted-input__input--centered {
      text-align: center;
    }

    &.formatted-input__input--right {
      text-align: right;
    }
  }
  .formatted-input__prefix,
  .formatted-input__suffix {
    @extend .paragraph-bold;
    line-height: 2;
    color: $text-gray;
    padding: 0 12px;
  }
  &.formatted-input--state-error {
    border: solid $orange-tint 1px;
  }
  &.formatted-input--state-loading {
    opacity: 0.5 !important;
    border: solid $border-gray 1px;
  }
  &.formatted-input--disabled {
    border: solid $border-gray 1px;
    background-color: $disabled-gray;
    font-style: italic;
    color: $dark-text-gray;
    input {
      background-color: $disabled-gray;
      font-style: italic;
      color: $dark-text-gray;
      &::placeholder {
        color: $dark-text-gray;
        font-style: italic;
      }
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
}

.formatted-input-container {
  width: 100%;
}
