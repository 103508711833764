@import '../../../../../styles/colors.scss';
@import '../../../../../styles/typography.scss';

.editLocations {
  width: 100%;
  padding: 12px 0;
  font-weight: $font-weight-bold;
}

.magenta {
  color: $magenta;
  font-weight: $font-weight-semibold;
  padding-left: 6px;
  font-weight: $font-weight-bold;
}

.labelNote {
  display: block;
  font-size: 14px;
  color: $text-gray;
  font-weight: $font-weight-regular;
  line-height: 24px;
}
