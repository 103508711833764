@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container {
  width: 100%;
  overflow: hidden;
}

.nameContainer {
  display: flex;
  align-items: baseline;
}

.indented {
  padding-left: 12px;
}

.warning {
  color: $orange-tint;
  margin-right: 6px;
}

.name {
  @extend .paragraph-regular;
  overflow: hidden;
}

.compactView .name {
  @extend .small-paragraph-regular;
}

.description {
  flex: 1;
  color: $text-gray;
  @extend .small-paragraph-regular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.parentDisabled,
.disabled,
.hidden,
.missing {
  color: $text-gray;
}

.disabled,
.missing {
  font-style: italic;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 0 12px;
  }
}
