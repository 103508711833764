@import 'styles/responsive.scss';

.toolbar {
  display: flex;
  gap: 12px;
  width: 100%;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    gap: unset;
  }

  .locationFilter {
    flex-basis: 425px;
    @media screen and (max-width: $breakpoint-mobile) {
      flex-basis: unset;
    }
  }
}
