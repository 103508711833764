@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.infoCard {
  gap: 12px;
}

.container {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.left {
  flex: 3;
  gap: 12px;
  display: flex;

  .details {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .name {
      @extend .paragraph-regular;
      overflow: hidden;
    }

    .description {
      color: $text-gray;
      @extend .small-paragraph-regular;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.missing {
  font-style: italic;
}

.attributes {
  display: flex;
  flex: 1;
  align-items: center;

  .assignedLocations {
    display: flex;
    align-items: center;
    width: 100px;

    .icon {
      font-size: 14px;
    }

    .amount {
      padding-left: 3px;
      font-weight: bold;
    }
  }

  .numItems {
    display: flex;
    align-items: center;
    width: 100px;

    .amount {
      padding-left: 3px;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
