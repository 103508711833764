@import 'styles/colors';
@import 'styles/typography';

.posId,
.posDetails {
  color: $text-gray;
}

.posDetails {
  @extend .small-paragraph-regular;
}
