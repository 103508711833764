@import '../../styles/borders.scss';
@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/responsive.scss';

.container {
  width: 100%;
  position: relative;
  padding: 6px 0;

  .input {
    display: flex;
    border: solid $border-gray 1px;
    border-radius: 6px;
    font-family: $font-family;
    overflow: hidden;
    height: 36px;

    :global(.text-input-container) {
      padding: 0;

      :global(.text-input) {
        border: none;
        cursor: pointer;
      }
    }

    &.withError {
      border-color: $orange-tint;
    }

    .inputIcon {
      font-size: 16px;
      color: $text-gray;
    }
  }

  .datePicker {
    position: absolute;
    top: 52px;
    background-color: $white;
    border: $modal-border;
    border-radius: $modal-border-radius;
    box-shadow: $modal-box-shadow;
    z-index: 2;

    .footer {
      border-top: 1px solid $background-gray;
      display: flex;
      flex-direction: column;
      padding: 10px 24px 13px 0;
      margin-top: -24px; // nullifies the bottom margin of the calendar

      &Buttons {
        display: flex;
        justify-content: flex-end;
      }
      .button {
        box-sizing: border-box;
        padding: 0 5px;
      }
    }
  }
  .isInModal {
    position: fixed;
    top: inherit;

    @media screen and (max-width: $breakpoint-mobile) {
      top: 30%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.clearButton {
  width: 28px;
}

.tipMessage {
  color: $orange-tint;
  margin: 6px 0 6px 24px;
  font-weight: $font-weight-bold;
  font-size: 12px;
}
