@import '../../styles/colors.scss';

.select-filter {
  width: 100%;

  &.select-filter--searchable {
    .select-filter__dropdown-indicator {
    }
  }
  &.select-filter--loading {
    opacity: 0.5;
  }
  .select-filter__control {
    @extend .text-input;
    padding: 0;
    min-height: 36px;
    height: auto;

    &:hover {
      border-color: $border-gray;
    }
    &.select-filter__control--is-focused {
      border: solid $black 1px;
      box-shadow: none;
    }
  }
  .select-filter__placeholder {
    color: $text-gray;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 16px;
  }
  .select-filter__indicator-separator {
    display: none;
  }
  .select-filter__clear-indicator {
    padding: 0 9px 1px;
  }
  .select-filter__menu {
    margin: 0 auto;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    padding: 3px;
    font-family: $font-family;
    z-index: $select-filter-menu-z-index;
  }
  .select-filter__menu-list {
    background-color: $white;
    border: solid $border-gray 1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0;
    overflow-wrap: break-word;
  }
  .select-filter__option {
    font-size: 16px;
    &.select-filter__option--is-selected {
      color: $black;
      background-color: transparent;
    }
    &.select-filter__option--is-selected:hover,
    &.select-filter__option--is-focused {
      background-color: $background-gray;
    }
    &.select-filter__option--is-disabled {
      color: $text-gray;
      font-style: italic;
    }
  }
  .select-filter__menu-notice--no-options {
    font-weight: $font-weight-regular;
    font-style: italic;
    text-align: start;
  }
  .select-filter__input,
  .select-filter__input input {
    font-family: $font-family;
  }
  .select-filter__multi-value {
    background-color: $lightmagenta;

    @media screen and (max-width: $breakpoint-tablet) {
      max-width: 186px;
    }

    .select-filter__multi-value__label {
      color: $magenta;
      font-size: 16px;
    }
    .select-filter__multi-value__remove {
      padding: 0 6px;
      &:hover {
        background-color: $lightmagenta-tint;
      }
      svg {
        background: $magenta;
        border-radius: 14px;
        color: $white;
        width: 16px;
        height: 16px;
      }
    }
  }
  &.select-filter--disabled {
    .select-filter__control {
      background-color: $disabled-gray;

      .select-filter__value-container {
        font-style: italic;
      }
      .select-filter__indicator svg {
        color: $border-gray;
      }
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
  &.select-filter--with-error {
    .select-filter__control {
      border-color: $orange-tint;
    }
  }

  &.select-filter--without-border {
    .select-filter__control {
      border: none;
      &.select-filter__control--is-focused {
        border: none;
      }
    }
  }

  .select-filter__group {
    padding: 0 !important;

    .select-filter__group-heading {
      background-color: $light-gray;
      color: $dark-text-gray;
      font-size: 12px;
      font-weight: bold;
      line-height: 24px;
      margin: 0;
    }
  }
}

.select-filter--state-error .select-filter__control {
  border: 1px solid $orange-tint;
}

.container.select-filter-container {
  width: 100%;
  &.select-filter-container--large {
    .select-filter__value-container {
      height: 60px;
      padding: 0;

      .select-filter__single-value {
        margin: 0;
      }
    }

    .select-filter__option {
      padding: 0;
    }
  }
}

.select-filter__loading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $text-gray;
  font-style: italic;

  img {
    height: 36px;
  }
}

.select-filter__footer {
  padding: 6px 12px 12px;
  color: $text-gray;
  font-style: italic;
}

.select-filter-select-option {
  font-size: 14px;
  color: $text-gray;
  font-style: italic;
}
