@import '../../styles/typography.scss';
@import '../../styles/colors.scss';
@import '../../styles/z-index.scss';
@import '../../styles/responsive.scss';
$sidebar-width: 248px;

.truncated {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layout {
  font-family: $font-family;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.layoutUserAdmin {
  @extend .layout;
  border-top: solid $yellow 5px;
}
.sidebar {
  width: $sidebar-width;
  flex-shrink: 0;
  border-right: $main-panel-gray solid 1px;
  background: $white;
  height: 100%;
  z-index: $layout-sidebar-z-index;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -o-transition: width 0.3s;
}
.sidebarVisible {
  @extend .sidebar;
}
.sidebarUser {
  padding: 24px;
  border-bottom: $main-panel-gray solid 1px;
  display: flex;
  align-items: center;
}
.readyIcon {
  height: 40px;
  .readyIconImg {
    width: 40px;
    height: 40px;
  }
}
.sidebarUserInfo {
  padding-left: 16px;
  width: calc(100% - 36px);
  div {
    height: 9px;
    border-radius: 5px;
    margin: 7px 0;
  }
}
.sidebarUserInfoName {
  @extend .header-6;
  @extend .truncated;
  line-height: 1.7;
}
.sidebarUserInfoContextName {
  @extend .header-6;
  line-height: 1.7;
  @extend .small-paragraph-regular;
}
.sidebarContextSwitcherButton {
  border: none;
  background: none;
  cursor: pointer;
  color: $magenta;
  width: 100%;
}
.sidebarContextSwitcherParagraph {
  display: flex;
  align-items: center;
  @extend .small-paragraph-regular;
  i {
    font-size: 10px;
    margin-left: 6px;
    flex-shrink: 0;
  }
}
.sidebarContextSwitcherContextLabel {
  @extend .truncated;
  &:hover {
    text-decoration: underline;
  }
}
.sidebarContextSwitcherNoButton {
  @extend .small-paragraph-regular;
}
.toogleSidebarButton {
  align-self: flex-start;
  display: none;
  i {
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: $darkest-gray;
  }
}
.sidebarNavigation {
  height: 100%;
  overflow-y: auto;
}
.sidebarNavigationPages {
  border-bottom: solid $main-panel-gray 1px;
}
.sidebarNavigationButton,
.sidebarNavigationLink {
  @extend .paragraph-regular;
  color: $black;
  text-decoration: none;
  display: block;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  padding: 12px 24px;
  box-sizing: border-box;
  &:hover {
    @extend .paragraph-bold;
  }
}
.sidebarNavigationLinkActive {
  @extend .sidebarNavigationLink;
  @extend .paragraph-bold;
  background-color: $background-gray;
  border-right: solid $magenta 7px;
}
.layoutContent {
  width: calc(100% - #{$sidebar-width});
  position: relative;
  display: flex;
  flex-direction: column;
}
.headerTitle,
.controls,
.subHeader {
  padding: 6px 48px;
}
.subHeader {
  display: flex;
  justify-content: space-between;
}
.header {
  min-height: 72px;
  border-bottom: $background-gray solid 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scrollToolbarForMobile {
  @media screen and (max-width: $breakpoint-mobile) {
    height: 100%;
    overflow: scroll;
    padding-top: 50px;
    .header {
      position: fixed;
      top: 0;
      background: white;
      width: 100%;
      z-index: 2;
    }
    .mainContentWrapper {
      height: auto;
      overflow-y: unset;
    }
  }
}

.headerTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
}
.headerTitle {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}
.group {
  height: calc-size(auto, size);
  transition: height 0.25s;
  overflow-y: hidden;
}
.displayNone {
  height: 0;
}
.headerTitleWithControls {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.headerSidebarToggler {
  display: none;
  margin-right: 12px;
  i {
    font-size: 22px;
    color: $darkest-gray;
  }
}
.headerTitleText {
  @extend .header-1;
  @extend .truncated;
}
.headerTitleControlsWrapper {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-top: 8px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 12px;
    margin-top: 0;
  }
}
.headerTitleControls {
  flex: 1;
  color: $magenta;
  @extend .header-5;
  line-height: 1.44;
}
.headerControlsDesktop,
.headerControlsDesktopTablet {
  display: flex;
  flex-shrink: 0;
  align-items: center;
}
.headerControlsTabletMobile,
.headerControlsMobile {
  display: none;
}
.toolbar {
  padding: 6px 48px;
  border-bottom: $background-gray solid 1px;

  &.darkBackground {
    background-color: $main-panel-gray;
    border-bottom: none;

    input {
      background-color: $white;
    }
  }
}
.mainContentWrapper {
  background-color: $main-panel-gray;
  height: 100%;
  overflow-y: auto;
}
.mainContentFull {
  background-color: $white;
  margin-bottom: 72px;

  &.darkBackground {
    background-color: $main-panel-gray;
  }
}
.mainContentWithin {
  background-color: $main-panel-gray;
  padding-bottom: 72px;

  &.darkBackground {
    background-color: $main-panel-gray;
  }
}
.mainContentPanel {
  margin: 24px 48px 72px;
  padding: 24px;
  border-radius: 6px;
  background-color: $white;
  border: solid $disabled-gray 1px;

  &.darkBackground {
    background-color: $main-panel-gray;
  }
}

.sidebarNavigationLinkNested {
  @extend .sidebarNavigationLink;
}
.multiLevelSidebarButton {
  border-radius: 6px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  display: flex;
  color: $black;
}
.subLinkNav {
  padding-left: 12%;
}
.expandIcon {
  margin-top: 6px;
  color: $text-gray;
}
.multiLevelSidebarLabel {
  @extend .paragraph-regular;
  font-size: 16px;
  font-weight: $font-weight-regular;
  font-family: $font-family;
  flex-direction: row-reverse;
  width: 100%;
  color: $black;
}

.showCompanySelectionPageParagraph {
  @extend .small-paragraph-regular;
  @extend .truncated;
  color: $text-gray;
  font-style: italic;
}

.multiLevelSidebarLabelActive {
  @extend .multiLevelSidebarLabel;
  font-weight: $font-weight-bold;
}
// Tablet viewport
@media screen and (max-width: $breakpoint-tablet) {
  .sidebar {
    position: absolute;
    width: 0;
  }
  .sidebarVisible {
    width: $sidebar-width;
  }
  .sidebarUserInfoName {
    width: 122px;
  }
  .toogleSidebarButton {
    display: block;
  }
  .layoutContent {
    width: 100%;
  }
  .headerTitle,
  .controls,
  .subHeader {
    padding: 6px 24px;
  }
  .headerSidebarToggler {
    display: block;
  }
  .toolbar {
    padding: 6px 24px;
  }
  .headerControlsDesktop {
    display: none;
  }
  .headerControlsTablet,
  .headerControlsTabletMobile {
    display: block;
  }
}

// Mobile viewport
@media screen and (max-width: $breakpoint-mobile) {
  .headerTitle,
  .controls,
  .subHeader {
    padding: 6px 12px;
  }
  .header {
    min-height: 48px;
  }
  .headerTitleText {
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-bold;
  }
  .headerTitleControlsWrapper {
    flex: 1;
    align-items: center;
    padding-bottom: 0;
  }
  .headerSidebarToggler {
    i {
      font-size: 20px;
    }
  }
  .headerControlsDesktopTablet,
  .headerControlsTablet {
    display: none;
  }
  .headerControlsMobile {
    display: block;
  }
  .toolbar {
    padding: 6px 12px;
  }
  .mainContentPanel {
    margin: 24px 12px;
    padding: 12px;
  }
}
