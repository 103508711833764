@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';

.reauthenticateButtonWrapper {
  margin-top: 12px;
  width: 160px;
}

.container {
  margin: 24px 48px 0;

  @media screen and (max-width: $breakpoint-tablet) {
    margin: 24px 24px 0;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin: 12px 12px 0;
  }
}
