@import 'styles/colors.scss';

.noSelectedText {
  color: $text-gray;
}

.supportLink {
  text-decoration: underline;
  color: $orange-tint;
}
