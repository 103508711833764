@import 'styles/responsive.scss';
@import 'styles/colors.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 48px 0;
}
.noResults {
  color: $text-gray;
  font-style: italic;
}

.notification {
  margin: 24px 48px 12px 48px;
  width: inherit;
}

.notificationLink {
  text-decoration: underline;
  color: inherit;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 0 24px 0;
  }
  .notification {
    margin: 24px 24px 12px 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 0 12px 0;
  }
  .notification {
    margin: 24px 12px 12px 12px;
  }
}
