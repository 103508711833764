.image {
  margin: 12px 0;
}

.thumbnailImage {
  margin: 12px 0 0;
  label {
    max-width: 100px;
  }
}
