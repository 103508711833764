@import '../../../styles/typography.scss';

.container {
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding-right: 12px;
}

.primaryContactContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 0;
}

.primaryContactPart {
  @extend .header-3;
  word-break: break-all;
  margin-right: 6px;
  line-height: 36px;
  // With container padding, this will be bound within a 48px high box when
  // displayed as a single line, or stacked 36px lines with 6+6px top-bottom
  // padding when displayed on multiple lines

  &:last-of-type {
    margin-right: 0;
  }
}

.secondaryContact {
  @extend .paragraph-regular;
  word-break: break-all;
}

.iconPickupTimeContainer {
  display: flex;
}

.pickupTimeContainer {
  @extend .header-4;
  flex-wrap: wrap;
  margin-bottom: 24px;
  margin-left: 12px;
}

.pickupTimeLabel {
  margin-right: 6px;
}
