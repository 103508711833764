@import '../../styles/typography';
@import '../../styles/colors';
@import '../../styles/responsive';

.tableV2 {
  display: flex;
  background-color: $white;
  max-width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  &__container {
    display: table;
    table-layout: auto;
    width: auto;
  }
  &__allColumnSizesUpdated {
    & .tableV2__container {
      width: 100%;
    }
  }
}
.Skeleton {
  display: flex !important;
}

@function shadowGradient($direction) {
  @return linear-gradient($direction, rgba(92, 92, 92, 0.4), rgba(92, 92, 92, 0.1), transparent);
}

.scrollShadow {
  position: sticky;
  z-index: 2;
  $this: &;
  &__container {
    position: relative;
    display: flex;
    height: 100%;
  }
  &__shadow {
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 30px;
    pointer-events: none;
  }
  &--right {
    left: calc(100% - 30px);
    #{$this}__shadow {
      background-image: shadowGradient(to left);
    }
  }
  &--left {
    left: 0;
    #{$this}__shadow {
      background-image: shadowGradient(to right);
    }
  }
}

.tableV2__tableStyledXScroll {
  position: relative;
  display: flex;
  flex-direction: row;
  &--rightScroll {
    .scrollShadow--right {
      .scrollShadow__shadow {
        opacity: 1;
      }
    }
  }
  &--leftScroll {
    .scrollShadow--left {
      .scrollShadow__shadow {
        opacity: 1;
      }
    }
  }
}

.allItemsSelected {
  width: 100%;
  padding: 12px 0 12px 0;
  border-radius: 6px;
  background-color: $background-gray;
  margin-bottom: 18px;
  display: flex;
  &__container {
    margin: auto;
  }
  &__button {
    color: $magenta;
    transition: color 0.3s;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      color: $magenta-tint;
    }
  }
}
