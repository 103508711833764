@import '../../../../../styles/responsive';
@import '../../../../../styles/colors';

.ReadyPaymentOptionComponent {
  display: block;
  padding-left: 6px;
  cursor: pointer;
  &:hover {
    background-color:$background-gray;
  }
}


.ReadyPaymentTextGray {
  color: $text-gray;
}
.CheckStatusTextGray {
  & > *:first-child {
    color: $text-gray;
  }
}

.ReadyPaymentValueContainer {
  padding-left: 12px;
}

.toolBar {
  display: flex;
  & label {
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-tablet) {
    justify-content: flex-start;
  }
  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }

  & + .toolBar {
    padding-top: 12px;
  }

  > * {
    flex: 1;

    &:not(&:last-child) {
      padding-right: 12px;
    }

    &.blockWidthBig {
      max-width: 600px;
      flex: 2;
    }

    &.filterWidthSmall {
      max-width: 186px;
      flex: auto;
    }

    &.blockSearch {
      flex: auto;
      max-width: 186px;
    }

    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
      padding-bottom: 12px;
      &:not(&:last-child) {
        padding-right: 0;
      }
    }
    @media screen and (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile + 1px) {
      max-width: 342px;
    }
    @media screen and (min-width: $breakpoint-tablet + 1px) {
      max-width: 584px;
      .blockWidthBig2 {
        max-width: 339px;
      }
    }
  }
}
