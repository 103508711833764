.info__card__data__row {
  display: flex;
  flex-direction: column;
}

.info__card__data__row--skeleton {
  cursor: unset !important;
  background: none !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  .skeleton {
    height: 12px;
    border-radius: 6px;
  }
}
