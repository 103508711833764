@import '../../styles/colors.scss';

.iconLink {
  display: inline-flex;
  border-radius: 6px;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $background-gray;
  }

  &:active,
  &:focus {
    background-color: $disabled-gray;
  }
}
