@import '../../../styles/colors';
@import '../../../styles/responsive';

.tableV2 {
  &__cellWrapper {
    display: table-cell;
  }
  &__row {
    flex: 1;
    display: table-row;
    position: relative;
    min-height: 48px;
    white-space: nowrap;

    &Clickable {
      cursor: pointer;
    }

    &::after {
      content: '';
      width: 100%;
      position: absolute;
      border-bottom: 1px solid $background-gray;
      @media screen and (max-width: $breakpoint-mobile) {
        border-bottom: 11px solid $background-gray;
      }
      bottom: 0;
      left: 0;
    }

    @media screen and (min-width: $breakpoint-tablet + 1px) {
      & > *:first-child {
        padding-left: 50px;
      }
    }
    @media screen and (max-width: $breakpoint-mobile) {
      display: table;
      flex-direction: column;
      padding-bottom: 11px;
      width: 100%;
    }
    @media screen and (min-width: $breakpoint-mobile + 1px) {
      &:hover {
        background-color: $light-gray;
      }
    }
  }
}
