@import 'styles/typography.scss';
@import 'styles/colors.scss';

.isAllowMultiplesModifier {
  @extend .paragraph-regular;
  color: $text-gray;
}

.isAllowMultiplesModifierEditMode {
  color: $black;
}
