@import '../../../styles/responsive';

.tableV2__headerRow {
  @media screen and (min-width: $breakpoint-tablet + 1px) {
    & > *:nth-child(1) {
      padding-left: 50px;
    }
  }

  @media screen and (min-width: $breakpoint-tablet + 1px) {
    & > *:nth-child(2) {
      padding-left: 0;
    }
  }
  @media screen and (max-width: $breakpoint-mobile) {
    display: none !important;
  }
  &:hover {
    background-color: unset !important;
  }
}
