@import '../../../styles/responsive.scss';

.newTableButton {
  margin-right: 12px;
}

.filtersContainer {
  display: flex;
  width: 100%;
  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.filterName {
  flex: 3;
}

.filterRevenueCenter,
.filterPosOrderType,
.filterTableType {
  flex: 2;
  margin-left: 12px;
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}

.filterTableType {
  margin-right: 20%;
  @media screen and (max-width: $breakpoint-tablet) {
    margin-right: 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .tab {
    padding: 0 12px;
  }
}

.orderExperienceType {
  text-align: right;
}
