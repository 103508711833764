@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.container {
  width: 258px;
}

.value {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

.icon {
  font-size: 32px;
  color: $text-gray;
}

.textContainer {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.label,
.subLabel {
  color: #707070;
}

.label {
  @extend .small-paragraph-bold;
  color: #707070;
}

.subLabel {
  @extend .small-paragraph-regular;
  color: #707070;
}
