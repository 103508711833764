@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.specialHoursContainer {
  margin-top: 24px;

  .specialHoursTitleRow {
    display: flex;
  }

  .dateTimeRow {
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid $background-gray;
    margin-top: 12px;

    .dateCheckboxColumn {
      width: 30%;
      .dateWrapper {
        width: 155px;
      }
    }
  }

  .addDayRow {
    margin-top: 12px;
  }

  .timeSlotColumn {
    display: flex;
    margin-left: 90px;
    overflow: hidden;
    align-items: baseline;

    .timePicker {
      display: flex;
      width: 255px;

      .timeDivider {
        align-items: center;
        margin: 5px;
        color: $text-gray;
      }
    }
    .clearButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin-left: 5px;
      padding: 10px;
    }

    .clearIcon {
      @extend .paragraph-regular;
      color: $text-gray;
    }
  }

  .isClosedContainer {
    height: 100%;
    display: flex;
    align-items: center;
    width: 255px;

    .isClosed {
      color: $text-gray;
      font-style: italic;
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .specialHoursContainer {
    .dateTimeRow {
      display: block;
      width: 100%;

      .dateCheckboxColumn {
        width: 100%;
        .dateWrapper {
          width: 186px;
        }
      }

      .timeSlotColumn {
        margin-left: 30px;
        .timePicker {
          margin-bottom: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .componentContainer {
    .specialHoursContainer {
      .dateTimeRow {
        .dateCheckboxColumn {
          .dateWrapper {
            width: 186px;
          }
        }
      }
    }
  }
}
