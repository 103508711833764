@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';
@import 'styles/sharedIcon.scss';

.link {
  width: 100%;
  min-width: 0;
}

.columns {
  display: flex;
  align-items: center;
  overflow: hidden;
  min-width: 0;
}

.column {
  width: 100%;
  padding-right: 24px;
  overflow: hidden;
}

.name,
.displayName,
.options {
  @extend .paragraph-regular;
}

.displayName,
.guestInstructions {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noDisplayName {
  color: $text-gray;
  font-style: italic;
}

.noDisplayNameMessage {
  display: inline;
}

.noDisplayNameIndicator {
  display: none;
}

.options {
  font-weight: $font-weight-semibold;
}

.constraints {
  @extend .small-paragraph-regular;
}

.guestInstructions,
.constraints {
  color: $text-gray;
}

.noGuestInstructions {
  @extend .small-paragraph-regular;
  color: $text-gray;
  font-style: italic;
}

.sharedIcon {
  @extend .sharedIconItemList;
}

@media screen and (max-width: $breakpoint-mobile) {
  .columns {
    display: block;
  }

  .column {
    padding-right: 12px;
  }

  .displayName {
    font-size: 14px;
    color: $text-gray;
  }

  .noDisplayNameMessage {
    display: none;
  }

  .noDisplayNameIndicator {
    display: inline;
  }

  .guestInstructions {
    display: none;
  }
}
