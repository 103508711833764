.tooltipContainer {
  padding-top: 12px;
  padding-bottom: 6px;
}

.tooltipLabel {
  margin-right: 3px;
}

.modalBody {
  /* This fixes the tooltip being hidden behind the modal footer. */
  /* Using !important to override the predefined value from modal.scss. */
  overflow-y: unset !important;
}

.indented {
  padding-left: 36px;
}
