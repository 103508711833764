@import 'styles/responsive.scss';
@import 'styles/colors.scss';

.container {
  padding: 12px 48px 0;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px 0;
  }
}

.noResults {
  color: $text-gray;
  font-style: italic;
}
