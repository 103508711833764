.itemColumn {
  min-width: 80px;
}

.totalColumn,
.stackedContainer {
  min-width: 130px;
}

.stackedContainer {
  display: flex;
  flex-direction: column;
}

.stackedChild {
  margin: 3px 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
