.tabs {
  .tabs__list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    height: auto;
    border-bottom: 1px solid $disabled-gray;
    padding-left: 48px;
    padding-right: 48px;
    background-color: $main-panel-gray;

    .tabs__list__item {
      flex: 0 0 auto;
      @extend .paragraph-bold;
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 10px;
      padding-bottom: 6px;
      text-emphasis: bold;
      border-bottom: 4px solid $main-panel-gray;
    }
    .tabs__list__item--selected {
      border-bottom: 4px solid $magenta;
    }
  }
  .tabs__list--white {
    background-color: $white;
    .tabs__list__item {
      border-bottom: 4px solid $white;
    }
    .tabs__list__item--selected {
      border-bottom: 4px solid $magenta;
    }
  }

  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    .tabs__list {
      height: auto;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  // Mobile viewport
  @media screen and (max-width: $breakpoint-mobile) {
    .tabs__list {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}
