@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.bannerWrapper {
  @extend .small-paragraph-regular;
  padding: 0px 24px 12px;
  font-weight: $font-weight-bold;
}

@media screen and (max-width: $breakpoint-mobile) {
  .bannerWrapper {
    padding: 0px 12px 12px;
  }
}
