@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.option {
  display: flex;

  gap: 6px;
  flex-direction: row;
  align-items: center;
}

.inactive {
  color: $disabled-gray;
  font-style: italic;
}

.shareIcon {
  color: $text-gray;
  font-size: 14px;
}

.indicatorStyles {
  margin-right: 100px;
}
