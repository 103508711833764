.container {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 96px;
  flex-shrink: 0;
}

.cancelButton {
  flex-basis: 33.3%;
  padding-right: 6px;
}

.submitButton {
  flex-grow: 1;
  padding-left: 6px;
}
