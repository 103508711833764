@import '../../styles/colors.scss';
@import '../../styles/borders.scss';
@import '../../styles/responsive.scss';
@import '../../styles/typography.scss';
@import '../../styles/positionalContainer.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  height: 48px;
}

.dateButtonContainer {
  width: 120px;
  position: relative;
}

.dateButton {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background: transparent;
  height: 36px;
  width: 120px;

  &:hover {
    background-color: $background-gray;
  }

  &:active {
    background-color: $disabled-gray;
  }

  &:focus {
    background-color: $white;
    border: solid 1px $black;
  }
}

.dateIcon {
  color: $magenta;
  margin-left: 12px;
  margin-right: 6px;
  margin-bottom: 4px;
  font-size: 18px;
}

.dateText {
  color: $magenta;
  margin-right: 12px;
  @extend .paragraph-bold;
}

.buttonIcon {
  color: $magenta;
  font-size: 18px;
}

.datePicker {
  @include positionalContainer(8px);
  background-color: $white;
  border: $modal-border;
  border-radius: $modal-border-radius;
}
