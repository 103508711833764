@import '../../styles/colors';
@import '../../styles/responsive';

.cellMobileLayoutWrapper {
  & > .cellMobileLayoutWrapped {
    @media screen and (max-width: $breakpoint-mobile) {
      padding-right: 18px;
    }
  }
}

.emailMarketingList {
  overflow: auto;
  background: $white;
}
