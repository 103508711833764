@import '../styles/responsive';
@import '../styles/colors';

.unassignedPanel {
  padding-top: 12px;
  padding-bottom: 0;
}

.dataPanelContainer {
  margin-bottom: 24px;

  @media screen and (max-width: $breakpoint-tablet) {
    margin-bottom: 0;
  }
}

.panelColumn {
  flex-shrink: 1;
  margin-top: 24px;
  min-width: 0;
}

.sectionContainer {
  margin: 0;
  padding: 0;
}

.sectionContents {
  padding: 0 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 12px;
  }
}

.hidden {
  display: none;
}
