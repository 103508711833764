$iconWidthHeight: 42px;

.column {
  padding: 0 6px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.icon {
  margin-right: 24px;
  max-width: $iconWidthHeight;
  max-height: $iconWidthHeight;
  min-width: $iconWidthHeight;
  min-height: $iconWidthHeight;
}

.dineInTable {
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
