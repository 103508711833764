.text-input {
  border: solid $border-gray 1px;
  border-radius: 6px;
  padding: 12px;
  height: 36px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 16px;
  color: $black;
  box-sizing: border-box;
  width: 100%;

  &:active,
  &:focus {
    border: solid $black 1px;
  }
  &::placeholder {
    color: $text-gray;
  }
  &.text-input--state-error {
    border: solid $orange-tint 1px;
  }
  &.text-input--disabledLoading {
    opacity: 0.4;
    pointer-events: none;
  }
  &.text-input--state-loading {
    opacity: 0.5 !important;
    border: solid $border-gray 1px;
  }
  &.text-input--disabled {
    border: solid $border-gray 1px;
    background-color: $disabled-gray;
    font-style: italic;
    color: $dark-text-gray;
    &::placeholder {
      color: $dark-text-gray;
      font-style: italic;
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
}

// container styles
.text-input-container {
  width: 100%;
}
