@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.time {
  @extend .paragraph-bold;
}

.urgent {
  @extend .paragraph-regular;
  color: $orange;
}

.subText {
  @extend .small-paragraph-regular;
}
