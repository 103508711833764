@import '../../../styles/colors';
@import '../../../styles/responsive';
@import '../../../styles/typography';

.container {
  background-color: $disabled-gray;
}

.nestedModGroups {
  padding: 12px;
}

.nestedModGroup {
  width: 100%;
  min-height: 72px;
  margin-top: 12px;
  border-radius: 6px;
  background-color: $white;
  padding-left: 24px;
  display: flex;
}

.nestedModGroup:first-child {
  margin-top: 0;
}

.dragHandleContainer {
  height: 72px;
  display: flex;
  align-items: center;
}

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 20px;
  color: $dark-text-gray;
  margin-right: 12px;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
  display: flex;
}

.header {
  min-height: 72px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.nameAndDisplayName {
  flex-grow: 1;
}

.name {
  @extend .header-5;
}

.displayName {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.noDisplayName {
  @extend .small-paragraph-regular;
  font-style: italic;
  color: $text-gray;
}

.constraints {
  @extend .header-5;
  color: $text-gray;
}

.controls {
  display: flex;
  align-items: center;
}

.remove {
  margin-left: 12px;
  margin-right: 24px;
  width: 36px;
  height: 36px;
  font-size: 20px;
  color: $dark-text-gray;
}

@media screen and (max-width: $breakpoint-mobile) {
  .nestedModGroup {
    padding-left: 12px;
  }

  .header {
    padding: 12px 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .controls {
    margin-top: 12px;
    justify-self: flex-end;
    align-self: flex-start;
  }

  .remove {
    margin-right: 12px;
  }
}
