@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

$scheduler-padding: 12px;

.container {
  display: flex;
  max-width: 700px;
  padding-bottom: 6px;

  button {
    margin: 6px 0 0 6px;
  }
}

.fullWidth {
  width: inherit;
}

.openedContainer {
  display: flex;
  width: 100%;
}

.scheduler {
  color: $magenta;
  background-color: transparent !important;
  border-style: none !important;
  width: fit-content !important;
}

.btnTextLink {
  width: 112px;
  @extend .header-5;
}

.btnTextForm {
  padding: $scheduler-padding $scheduler-padding 0 0;
  font-size: 20px;
  color: $dark-text-gray;
}

.dropdown {
  font-size: 20px;
  color: $dark-text-gray;
  padding: 3px 0 0 6px;
  height: 42px !important;
  width: 42px !important;

  &:active,
  &:hover {
    background-color: transparent !important;
  }

  &:focus {
    background-color: $white;
    border: none !important;
  }
}

.selectorStatusContainer {
  width: 40%;
}

.selectorAvailabilityContainer {
  width: 60%;
  padding-left: $scheduler-padding;
}

.filters {
  flex: 1;
  max-width: 606px;
  display: flex;
}

.openedSchedule {
  width: 100%;
  display: flex;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    display: flex;
    width: 100%;
  }
  .openedContainer {
    display: flex;
    width: 100%;
  }
  .filters {
    width: 100%;
    display: flex;
  }
  .openedSchedule {
    width: 100%;
    display: flex;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    display: flex;
    width: 100%;
  }
  .openedContainer {
    display: flex;
    width: 100%;
  }
  .filters {
    display: block;
    width: 79%;
    padding-left: 6px;
  }
  .openedSchedule {
    display: block;
    width: 100%;
  }
  .selectorStatusContainer,
  .selectorAvailabilityContainer {
    width: 100%;
    padding-left: 0;
  }
  .btnTextForm {
    width: 18px;
  }
}
