@import '../../../styles/responsive.scss';

.flexColumnAlignRight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
}

.stackedContainer,
.statusColumn {
  min-width: 152px;

  @media screen and (max-width: $breakpoint-mobile) {
    min-width: unset;
  }
}

.stackedContainer,
.statusColumn,
.stackedColumn {
  @extend .flexColumnAlignRight;
}

.stackedChild {
  margin: 3px 0;
  text-align: right;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
