@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.panelContainer {
  margin: 24px 0;
}

.wrapper {
  background: $white;
  border: solid $disabled-gray 1px;
  border-radius: 6px;
  margin: 24px 48px;
  padding: 12px 24px;
}

.title {
  @extend .header-3;
}

.paymentProcessingOption {
  display: flex;
  margin-top: 12px;
}

.label {
  width: 292px;
  padding: 12px 0;
  display: flex;
  align-items: center;
}

.labelContent {
  @extend .paragraph-bold;
}

.control {
  display: flex;
  align-items: center;
}

.paypalLogo {
  height: 24px;
  margin-right: 6px;
}

.nestedDetailContent {
  padding-left: 24px;
  flex-basis: 100%;

  > p {
    margin-top: 12px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.mobileButtons {
  display: none;
  @media screen and (max-width: $breakpoint-mobile) {
    display: block;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .wrapper {
    margin: 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .wrapper {
    margin: 24px 12px;
  }

  .paymentProcessingOption {
    flex-direction: column;
  }
}
