@import '../../styles/colors.scss';

.badgeHost {
  display: block;
  position: relative;
  padding: 2px 16px;
  margin: 0 24px 0 8px;
}

.triangle {
  position: absolute;
  right: calc(100% - 0.5px);
  width: 8px;
  height: 50%;
}

#topTriangle {
  top: 0;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

#bottomTriangle {
  bottom: 0;
  clip-path: polygon(0 100%, 100% 100%, 100% 0);
}

.globalHeaderFont {
  display: block;
  font-size: 12px;
  line-height: 1.4;
}

.contentBox {
  flex: 1 1;
  overflow: hidden;
  min-width: 0;
  display: flex;
}
