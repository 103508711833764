@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container,
.containerDisabled {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.containerDisabled {
  color: $text-gray;
}

.imageMobile {
  display: none;
}

.imageAndDetails {
  display: flex;
  overflow: hidden;
  width: 40%;
}

.imageDesktop {
  display: flex;
}

.name {
  padding: 0 24px;
}

.dotContainer {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
}

.dot {
  height: 6px;
  width: 6px;
  margin: 0 24px 0 18px;
  background-color: #fac7e5;
  border-radius: 6px;
}

.details {
  display: flex;
  overflow: hidden;
}

.pos {
  width: 30%;
  padding-right: 24px;
  overflow: hidden;
}

.separator {
  padding: 0 6px;
}

.attributes {
  width: 30%;
  display: flex;
  align-items: center;
  min-width: 0;
}

.priceLevel {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  text-align: end;
  padding-right: 24px;
}

.icons {
  padding-left: 24px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .priceLevel {
    width: 40%;
  }

  .icons {
    padding-left: 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .imageDesktop {
    display: none;
  }

  .imageMobile {
    display: flex;
    align-self: flex-start;
  }

  .details {
    flex-direction: column;
  }

  .imageAndDetails {
    width: 100%;
  }

  .pos {
    width: 100%;
    display: none;
  }

  .attributes {
    width: 100%;
    padding: 0 12px;
  }

  .priceLevel {
    flex: 0;
    justify-content: flex-start;
    min-width: 96px;
  }
}
