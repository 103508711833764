@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.itemGroup {
  flex: 100%;
  min-height: 72px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.itemGroupDetails {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.itemGroupName {
  width: 40%;
  max-width: 486px;
  margin-right: 24px;
}

.itemCount {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: $font-weight-semibold;
}

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  font-size: 20px;
  color: $border-gray;
  margin: 0 12px 0 48px;
}

.itemsDragging {
  background-color: $disabled-gray;
}

.item {
  flex: 100%;
  min-height: 72px;
  border-top: 1px solid $background-gray;
  display: flex;
  align-items: center;
}

.item {
  background-color: $white;
}

.itemDragging {
  background-color: $main-panel-gray;
}

.hide,
.show,
.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  font-size: 20px;
  color: $dark-text-gray;
  margin-left: 24px;
}

.remove {
  min-width: 36px;
  margin-left: 12px;
}

.hide,
.show {
  margin-left: 10%;
}

.show {
  color: $border-gray;
}

.noItems {
  margin: 0 24px 0 84px;
  padding: 0 12px;
  max-width: 486px;
  border: 2px dotted $disabled-gray;
  border-radius: 6px;
  min-height: 36px;
  color: $text-gray;
  @extend .small-paragraph-regular;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addItem {
  margin: 0 24px 12px 84px;
  max-width: 486px;
}

.addAnotherItem {
  flex: 1;
  padding: 0;
  min-height: 72px;
  border-top: 1px solid $background-gray;
}

.addAnotherItemButton {
  padding: 12px 24px 0 120px;
  max-width: 612px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .itemGroupName {
    width: 50%;
  }

  .itemGroupInformation {
    width: 50%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .itemGroup {
    align-items: flex-start;
    padding: 12px 12px;
  }

  .itemGroupDetails {
    display: block;
  }

  .itemGroupName {
    width: 100%;
  }

  .itemCount {
    display: none;
  }

  .item {
    padding: 0;
  }

  .dragHandle {
    margin: 6px 12px 0 36px;
  }

  .hide,
  .show {
    display: none;
  }

  .dragHandle,
  .dropdown {
    margin-top: 12px;
  }

  .dropdown {
    margin-left: 12px;
  }

  .remove {
    margin-left: 0;
  }

  .noItems {
    margin: 0 60px 0 60px;
    padding: 12px;
  }

  .addItem {
    margin: 0 60px 12px 60px;
  }

  .addAnotherItemButton {
    padding: 12px 60px 0 96px;
    max-width: 612px;
  }
}
