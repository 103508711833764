.search-select-filter {
  width: 100%;

  &.search-select-filter--searchable {
    .search-select-filter__dropdown-indicator {
    }
  }
  &.search-select-filter--loading {
    opacity: 0.5;
  }
  .search-select-filter__control {
    @extend .text-input;
    padding: 0;
    min-height: 36px;
    height: auto;

    &:hover {
      border-color: $border-gray;
    }
    &.search-select-filter__control--is-focused {
      border: solid $black 1px;
      box-shadow: none;
    }
  }
  .search-select-filter__placeholder {
    color: $text-gray;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 16px;
  }
  .search-select-filter__indicator-separator {
    display: none;
  }
  .search-select-filter__indicator {
    height: 34px;
  }
  .search-select-filter__menu {
    margin: 0 auto;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    padding: 3px;
    font-family: $font-family;
    z-index: $search-select-filter-menu-z-index;
  }
  .search-select-filter__menu-list {
    background-color: $white;
    border: solid $border-gray 1px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 0;
  }
  .search-select-filter__option {
    font-size: 16px;
    &.search-select-filter__option--is-selected {
      color: $black;
      background-color: transparent;
    }
    &.search-select-filter__option--is-selected:hover,
    &.search-select-filter__option--is-focused {
      background-color: $background-gray;
    }
  }
  .search-select-filter__menu-notice--no-options {
    font-weight: $font-weight-regular;
    font-style: italic;
    text-align: start;
  }
  .search-select-filter__input,
  .search-select-filter__input input {
    font-family: $font-family;
  }
  .search-select-filter__multi-value {
    background-color: $lightmagenta;

    .search-select-filter__multi-value__label {
      color: $magenta;
      font-size: 16px;
    }
    .search-select-filter__multi-value__remove {
      padding: 0 6px;
      &:hover {
        background-color: $lightmagenta-tint;
      }
      svg {
        background: $magenta;
        border-radius: 14px;
        color: $white;
        width: 16px;
        height: 16px;
      }
    }
  }
  &.search-select-filter--disabled {
    .search-select-filter__control {
      background-color: $disabled-gray;

      .search-select-filter__value-container {
        font-style: italic;
      }
      .search-select-filter__indicator svg {
        color: $border-gray;
      }
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
}

.container.search-select-filter-container {
  width: 100%;
}
