@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.cardControls,
.cardControlsHidden {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  font-size: 20px;
}

.cardControls {
  color: $dark-text-gray;
}

.cardControlsHidden {
  color: $border-gray;
}

@media screen and (max-width: $breakpoint-mobile) {
  .cardControls {
    align-self: flex-start;
  }
}
