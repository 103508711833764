@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid $background-gray;
}

.content {
  flex: 1;
  display: flex;
  margin: 0 12px;
  max-height: 72px;
  min-width: 0;
}

.unavailable {
  color: $text-gray;
  font-style: italic;
}

.description {
  flex: 1;
  overflow: hidden;
}

.name,
.displayName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name,
.options {
  @extend .paragraph-regular;
}

.displayName,
.minMax {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.attributes {
  text-align: end;
}

.options {
  font-weight: $font-weight-semibold;
}

.controls {
  margin-right: 12px;
  width: 84px;
  display: flex;
  justify-content: flex-end;
}

.button {
  flex: 0;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    height: 120px;
  }

  .expander {
    padding-top: 36px;
    align-self: flex-start;
  }

  .content {
    flex-direction: column;
    max-height: 120px;
  }

  .attributes {
    flex: 1;
    text-align: start;
  }

  .controls {
    align-self: flex-end;
    width: 72px;
    padding-bottom: 12px;
  }
}
