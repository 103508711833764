/**
 * $position-offset is the amount of space between this container and its parent
 */
@mixin positionalContainer($position-offset: 8px) {
  position: absolute;
  z-index: 1;

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.topLeft {
    bottom: 0;
    left: 0;
    margin-bottom: $position-offset;
  }

  &.topCenter {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: $position-offset;
  }

  &.topRight {
    bottom: 0;
    right: 0;
    margin-bottom: $position-offset;
  }

  &.leftTop {
    top: 0;
    transform: translateX(-100%);
    margin-right: $position-offset;
  }

  &.leftCenter {
    top: 50%;
    transform: translate(-100%, -50%);
    margin-right: $position-offset;
  }

  &.leftBottom {
    bottom: 0;
    transform: translateX(-100%);
    margin-right: $position-offset;
  }

  &.rightTop {
    top: 0;
    right: 0;
    transform: translateX(100%);
    margin-left: $position-offset;
  }

  &.rightCenter {
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    margin-left: $position-offset;
  }

  &.rightBottom {
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    margin-left: $position-offset;
  }

  &.bottomLeft {
    top: 100%;
    left: 0;
    margin-top: $position-offset;
  }

  &.bottomCenter {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: $position-offset;
  }

  &.bottomRight {
    top: 100%;
    right: 0;
    margin-top: $position-offset;
  }
}
