@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.container {
  margin: 0 48px;
  border-bottom: 1px solid $disabled-gray;
}

.noItemsMessage {
  @extend .paragraph-regular;
  color: $text-gray;
  text-align: center;
  padding: 24px;
  border-top: 1px solid $main-panel-gray;
}

.itemGalleryStarIcon {
  margin-right: 6px;
  color: $dark-text-gray;
  font-size: 28px;
}

.listContainer {
  border-top: 1px solid #eeeeee;
}

.panel {
  margin: 24px 48px 0;

  @media screen and (max-width: $breakpoint-tablet) {
    margin: 24px 24px 0;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin: 12px 12px 0;
  }
}
