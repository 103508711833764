@import 'styles/responsive.scss';
@import 'styles/colors.scss';

.noConfigMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $main-panel-gray;
  padding: 12px 24px;
  min-height: 72px;
  color: $text-gray;
}

.booleanValue {
  padding-bottom: 12px;
}

.skel {
  min-height: 24px;
}

.termsUrl {
  padding-left: 24px;
}
