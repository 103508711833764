@import 'styles/colors.scss';
@import 'styles/typography.scss';

.cardContent {
  min-height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $main-panel-gray;

  &.disabled {
    opacity: 0.4;
  }
}

.cardColumn {
  margin-right: 12px;
}

.name {
  @extend .paragraph-regular;
}

.locationAddress {
  @extend .small-paragraph-regular;
  color: $text-gray;
}
