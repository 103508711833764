@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-top: solid $background-gray 1px;
}

.content {
  @extend .paragraph-regular;
  color: $text-gray;
}
