.transaction_toolbar__container {
  display: flex;

  .transaction_toolbar__date-filter,
  .transaction_toolbar__location-filter {
    padding: 0 6px;
    width: 100%;
  }

  .transaction_toolbar__date-filter {
    padding-left: 0;
  }

  .transaction_toolbar__location-filter {
    padding-right: 0;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;

    .transaction_toolbar__date-filter,
    .transaction_toolbar__location-filter {
      padding: 6px 0;
    }
  }
}

#transactions-list-page {
  .icon-dashboard-icons_alert {
    margin-right: 6px;
    color: $orange;
  }
}
