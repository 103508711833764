@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.transactionChecksPanel {
  @media screen and (max-width: $breakpoint-mobile) {
    &:first-child {
      margin-top: 24px;
    }
  }
}
.checkId {
  @extend .header-3;
  font-weight: $font-weight-bold;
}
.checkNumber {
  color: $magenta;
}
.notEnteredAlert {
  background: $light-orange;
  color: $orange-tint;
  padding: 12px;
  border-radius: 6px;

  span {
    @extend .small-paragraph-bold;
    margin-left: 3px;
  }
}
.checkItem {
  @extend .paragraph-regular;
  border-bottom: solid $light-gray 1px;
  padding: 12px 0;
}
.checkInfo {
  display: flex;
}
.checkItemNameWrapper {
  flex-basis: 60%;
  flex-shrink: 0;
}
.checkItemQuantityWrapper {
  flex-basis: 10%;
  text-align: center;
}
.checkItemTotalsWrapper {
  flex-basis: 30%;
  flex-shrink: 0;
  text-align: right;
}
.checkItemQuantity {
  @extend .small-paragraph-regular;
  color: $darkest-gray;
  background-color: $main-panel-gray;
  border-radius: 3px;
  width: 26px;
  height: 24px;
  margin: 0 auto;
}
.checkItemTotal:last-child {
  margin-left: 12px;
}
.checkItemComment {
  @extend .small-paragraph-regular;
  background-color: $lighter-yellow;
  padding: 0 6px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 6px;
}
.checkItemCommentIcon {
  color: $yellow-text;
  margin-right: 6px;
}
.checkItemModifiers {
  @extend .paragraph-regular;
  border-top: solid $light-gray 1px;
  margin-top: 12px;
  padding-left: 24px;
  color: $text-gray;
}
.itemListHeader {
  @extend .header-3;
  font-weight: $font-weight-bold;
}
.itemsNotFound {
  @extend .paragraph-regular;
  margin-top: 8px;
  color: $text-gray;
}
.checkModifierDescription {
  border-bottom: solid $light-gray 1px;
  padding: 12px 0;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.checkModifierValue {
  font-weight: $font-weight-semibold;
  margin-left: 3px;
}
.checkPaymentSummary {
  padding-top: 6px;
}
.checkPayment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  margin-left: -12px;
  margin-right: -12px;
  border-radius: 6px;

  &:first-child {
    margin-top: 6px;
  }
  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
    margin-right: 0;
  }
}
.checkPaymentIcon {
  margin-right: 6px;
}
.checkPaymentIconEntered {
  color: $green;
}
.checkPaymentIconNotEntered {
  color: $orange-tint;
}
.checkPaymentIconSending {
  color: $black;
}
.checkPaymentType {
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  margin-right: 3px;
}
.checkPaymentDetail {
  @extend .small-paragraph-regular;
  color: $dark-text-gray;
  margin-left: 3px;
}
.checkPaymentDetailTip {
  color: $green-tint;
}
.checkPaymentEntered {
}
.checkPaymentNotEntered {
  background: $light-orange;
  padding: 6px 12px;
}
.checkPaymentSending {
  background: $background-gray;
}
.checkPaymentInfo {
  height: 24px;
  padding-right: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: $breakpoint-tablet) {
    margin-bottom: 6px;
  }
}
.checkPaymentExtra {
  flex-shrink: 0;
}
.checkPaymentExtraText {
  @extend .paragraph-regular;
  font-style: italic;
  color: $dark-text-gray;
}
.checkPaymentButton {
  @extend .paragraph-regular;
  line-height: 18px;
  border: solid $orange-tint 1px;
  border-radius: 6px;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: $font-weight-bold;
  position: relative;

  &:hover {
    border-color: $orange-toast;
  }
  &:last-child {
    margin-left: 12px;
  }
}
.checkPaymentButtonOrange {
  background: none;
  color: $orange-tint;

  &:hover {
    color: $orange-toast;
  }
}
.checkPaymentButtonOrangeSolid {
  background: $orange-tint;
  color: $white;

  &:hover {
    background: $orange-toast;
  }
}
.checkPaymentButtonProcessing {
  opacity: 0.5;
  cursor: not-allowed;
}
.checkPaymentButtonSpinner {
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
}
