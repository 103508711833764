@import 'styles/responsive.scss';
@import 'styles/colors.scss';
@import 'styles/typography.scss';

.description {
  @extend .paragraph-regular;
  max-width: 702px;
}

.subheader {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}
