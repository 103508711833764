.centeredIconCell {
  display: flex;
  align-items: center;
  height: 100%;
}

.mobileTypeAndPaymentCell {
  justify-content: flex-end;
  align-content: center;
  display: flex;
  height: 100%;
  gap: 5px;
}
