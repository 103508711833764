.sharedIconItemList {
  width: 36px;
  color: $text-gray;
  font-size: 14px;
}

.sharedIconTitle {
  font-size: 25px;
  padding-left: 6px;
  color: $text-gray;
}
