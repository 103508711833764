@import '../../styles/colors';

$border-line: $main-panel-gray solid 1px;

.radioContainer {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  height: 48px;
}

.listItem {
  border-bottom: $border-line;

  &:first-of-type {
    border-top: $border-line;
  }
}
