@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.container {
  flex: 1;
  display: flex;
  overflow: hidden;
}

.imageAndDetails {
  display: flex;
  overflow: hidden;
}

.image {
  display: flex;
}

.name {
  padding: 0 24px;
}

.details {
  display: flex;
  overflow: hidden;
}

.attributes {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 50px;
}

.icons,
.assignedLocations {
  padding-left: 24px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .icons,
  .assignedLocations {
    padding-left: 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .icons {
    display: none;
  }

  .details {
    flex-direction: column;
  }

  .attributes {
    max-width: 20%;
    margin-right: 10px;
  }
}
