$sidebar-width: 248px;
$action-bar-height: 72px;

.formActionBar {
  left: $sidebar-width;
  width: calc(100% - #{$sidebar-width});
  height: $action-bar-height;
  position: absolute;
  background: white;
  transition: top 0.3s;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.formActionBar__buttons {
  padding-top: 12px;
  padding-left: 48px;
  display: flex;

  .button {
    outline: none;
  }

  .container {
    padding-right: 12px;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .formActionBar {
    left: 0;
    width: 100%;
  }

  .formActionBar__buttons {
    padding-left: 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .formActionBar {
    height: 48px;
    left: 0;
    width: 100%;
  }

  .formActionBar__buttons {
    padding-top: 0;
    padding-left: 12px;
  }
}
