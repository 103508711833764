@import 'styles/responsive';

.cardBrandCell,
.paymentMethodCell {
  text-align: center;

  img {
    height: 18px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    text-align: left;
  }
}
