@import '../../../styles/responsive.scss';
@import '../../../styles/colors.scss';

.container {
  display: flex;
  flex-direction: row;
}

.searchAndNavContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.searchContainer {
  display: flex;
  align-items: center;
  max-width: 480px;
  flex-grow: 1;
  height: 48px;
}

.searchInput {
  background-color: $white !important;
}

.toggleLinksContainer {
  margin-left: 12px;
  max-width: 252px;
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 378px;

  @media screen and (max-width: $breakpoint-mobile) {
    max-width: unset;
  }
}

.datePickerContainer {
  display: flex;
  align-items: center;
  margin-left: 12px;
  flex-grow: 0.25;
  justify-content: flex-end;
}

.datePickerBounds {
  min-width: 180px;
  max-width: 240px;
  display: flex;
  flex-grow: 1;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .searchContainer {
    max-width: unset;
  }

  .datePickerContainer {
    margin-left: 0;
    justify-content: center;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    flex-direction: column-reverse;
    align-content: stretch;
  }

  .searchAndNavContainer {
    flex-direction: column;
  }

  .searchContainer {
    max-width: 100%;
    width: 100%;
    margin-bottom: 12px;
  }

  .toggleLinksContainer {
    margin-left: 0;
    width: 100%;
  }
}
