@import '../../../styles/colors';
@import '../../../styles/typography';
@import '../../../styles/responsive';

.container {
  display: flex;
  justify-content: space-between;
}

.actionMenu {
  display: none;
}

.actionButtons {
  display: flex;
  align-items: flex-end;
}

.actionButton {
  padding-left: 6px;
  padding-right: 6px;
}

.actionButton:first-child {
  padding-left: 0;
}

.actionButton:last-child {
  padding-right: 0;
}

.backLinkSection {
  display: flex;
  align-items: center;
  min-width: 0;
}

.leftArrowIcon {
  font-size: 24px;
  color: $magenta;
}

.text {
  @extend .header-2;
}

.subText {
  @extend .small-paragraph-regular;
  color: $text-gray;
  padding-left: 48px;
}


@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px;
  }

  .actionButtons {
    display: none;
  }

  .actionMenu {
    display: block;
  }
}
