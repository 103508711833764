@import '../../styles/responsive.scss';
@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

.container {
  width: 100%;
  max-width: 900px;
  display: flex;

  .label {
    display: flex;
    @extend .header-5;
    width: 30%;
    margin-right: 24px;
    padding: 12px 0;
    &.condensed {
      padding: 0;
    }

    .tooltip {
      padding-left: 3px;
    }
  }
  .content {
    flex: 1;
    padding: 12px 0;
    line-height: 24px;
    &.condensed {
      padding: 0;
    }
  }
}
.isLabelNoteColumn {
  flex-direction: column;
  @media screen and (max-width: $breakpoint-tablet) {
    margin-bottom: 12px;
  }
}

// Tablet viewport
@media screen and (max-width: $breakpoint-tablet) {
  .container {
    flex-direction: column;

    .label {
      width: 100%;
      padding: 12px 0 0;
      &.condensed {
        padding: 12px 0 0;
      }
    }
    .content {
      padding: 0 0 12px;
      &.condensed {
        padding: 0 0 12px;
      }
    }
  }
}
