@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

.permissionItemView {
  display: flex;
  margin-bottom: 24px;
}

.permissionViewTitle {
  @extend .header-5;
}

.permissionViewDescription {
  @extend .paragraph-regular;
}

.active {
  color: $green;
  padding-right: 12px;
  padding-top: 3px;
}

.notActive {
  color: $text-gray;
  padding-right: 12px;
  padding-top: 3px;
}
