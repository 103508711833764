@import '../../styles/colors';
@import '../../styles/typography';
@import '../../styles/responsive';

.container,
.containerFullWidth {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.container {
  padding: 12px 48px;
}

.containerFullWidth {
  padding: 12px 0;
}

.actionMenu {
  display: none;
  flex-direction: column;
  justify-content: center;
  height: 48px;
}

.actions {
  flex-shrink: 0;
}

.actionButtons {
  display: flex;
  align-items: flex-end;
}

.actionButton {
  padding-left: 6px;
  padding-right: 6px;
}

.actionButton:first-child {
  padding-left: 0;
}

.actionButton:last-child {
  padding-right: 0;
}

.backLinkSection {
  display: flex;
  min-width: 0;
  flex-flow: row wrap;
}

.leftArrowIcon {
  font-size: 24px;
  color: $magenta;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}

.textWrapperWithBackLink {
  margin-left: 12px;
}

.text {
  @extend .header-2;

  &.wrapAll {
    word-break: break-all;
  }

  &.wrapWord {
    word-break: break-word;
  }

  &.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
  }

  &.disabled {
    color: $text-gray;
    font-style: italic;
  }
}

.extendedText {
  @extend .paragraph-regular;
  word-break: break-word;
  &.hint {
    @extend .small-paragraph-regular;
    color: $text-gray;
    font-style: italic;
  }

  &.inline {
    padding-left: 12px;
    padding-top: 8px;
  }
}

.backLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
}

.subText {
  @extend .small-paragraph-regular;
  color: $text-gray;
  flex-basis: 100%;
}

.textSection {
  display: flex;
  flex-direction: column;

  &.inline {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px;
  }

  .actionButtons {
    display: none;
  }

  .actionMenu {
    display: flex;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px;
  }
}
