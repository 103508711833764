@import '../../styles/responsive.scss';
@import '../../styles/colors.scss';

$iconWidthHeight: 42px;

.column {
  padding: 0 6px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.toolbarContainer {
  padding: 12px 0;
}

.listContainer {
  margin: 0 48px;
}

.settingsIcon {
  font-size: 20px;
  color: $dark-text-gray;
}

.settingsIcon:hover {
  color: $darkest-gray;
}

@media screen and (max-width: $breakpoint-tablet) {
  .listContainer {
    margin: 0 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .listContainer {
    margin: 0 12px;
  }
}

.icon {
  margin-right: 24px;
  max-width: $iconWidthHeight;
  max-height: $iconWidthHeight;
  min-width: $iconWidthHeight;
  min-height: $iconWidthHeight;
}
