@import '../../../styles/colors';
@import '../../../styles/responsive';
@import '../../../styles/typography';

.container {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid $background-gray;
}

.expander {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 20px;
  color: $dark-text-gray;
}

.content,
.subContent {
  flex: 1;
  display: flex;
  margin: 0 12px;
  max-height: 72px;
  min-width: 0;
}

.subContent {
  margin: 0 108px 0 48px;
}

.main {
  flex: 1;
  overflow: hidden;
  margin-right: 12px;
}

.displayName {
  @extend .paragraph-regular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description,
.noDescription,
.pos {
  @extend .small-paragraph-regular;
  color: $text-gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noDescription {
  font-style: italic;
}

.separator {
  margin: 0 3px;
}

.itemCount,
.price {
  font-weight: $font-weight-semibold;
}

.controls {
  display: flex;
  justify-content: flex-end;
  width: 96px;
  padding-right: 12px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    height: 120px;
    align-items: flex-start;
    padding-top: 12px;
  }

  .content,
  .subContent {
    flex-direction: column;
    max-height: 120px;
  }

  .itemCount,
  .price {
    margin-top: 12px;
  }

  .controls {
    align-self: flex-end;
    margin-bottom: 12px;
  }
}
