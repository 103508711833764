@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.container {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid $background-gray;
}

.notification {
  margin-top: 6px;
}
