@import '../../styles/typography.scss';
@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';

.toast {
  padding: 0;
  left: unset;
  top: 36px;
  right: 18px;
  width: 432px;
  & > div {
    font-family: $font-family;
    padding: 0;
    margin: 0 0 24px 0;
    min-height: unset;
    cursor: inherit;
    background: none;
    box-shadow: none;
    overflow: unset;
    width: 100%;
  }
}
.toastBodyWrapper {
  margin: 0;
  width: 100%;
}
.toastBody {
  display: flex;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
}
.toastBodyIcon {
  padding: 24px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 70px;
  i {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
  }
}
.toastBodyIconError {
  @extend .toastBodyIcon;
  background: $orange-tint;
}
.toastBodyIconSuccess {
  @extend .toastBodyIcon;
  background: $magenta;
  border: solid $magenta-tint 1px;
}
.toastBodyContent {
  border: solid $disabled-gray 1px;
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  flex: 1;
  padding: 18px 24px;
  background: $white;
  width: calc(100% - 70px);
}
.toastBodyContentHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toastBodyContentHeaderContainer {
  display: flex;
  align-items: baseline;
}
.toastBodyContentHeaderTitle {
  @extend .header-5;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 6px;
}
.toastBodyContentHeaderError {
  @extend .toastBodyContentHeaderTitle;
  color: $orange-tint;
}
.toastBodyContentHeaderSuccess {
  @extend .toastBodyContentHeaderTitle;
  color: $magenta;
}
.toastHeaderToggle {
  @extend .tiny-paragraph-regular;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  flex-shrink: 0;
}
.toastHeaderToggleError {
  @extend .toastHeaderToggle;
  color: $orange-tint;
}
.toastHeaderToggleSuccess {
  @extend .toastHeaderToggle;
  color: $magenta;
}
.toastMessage {
  @extend .paragraph-regular;
  margin-top: 6px;
  color: $black;
  ul {
    margin-top: 12px;
    padding-left: 24px;
    li {
      padding-left: 3px;
    }
  }
}
.toastCloseButton {
  flex-shrink: 0;
  i {
    font-size: 20px;
    color: $dark-text-gray;
  }
}

// Tablet viewport
@media screen and (max-width: $breakpoint-mobile) {
  .toast {
    right: 11px;
    width: calc(100% - 22px);
  }
}
