@import './colors.scss';
@import './typography.scss';

/** Reusable styles which follow the design system for buttons:
  * https://zpl.io/2jxz3kp
  */

$minButtonHeight: 36px;
$minButtonWidth: 36px;
$border: solid 1px $black;
$invisibleBorder: solid 1px transparent;
$boxShadow: 0 0 3px 0 $dark-text-gray;
$iconFontSize: 20px;

.basicButtonStyles {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: $invisibleBorder;
  cursor: pointer;
  min-height: $minButtonHeight;
  min-width: $minButtonWidth;

  &:disabled {
    cursor: not-allowed;
  }
}

/*****************************/
/*** Generic Button styles ***/
/*****************************/

.buttonText {
  @extend .paragraph-bold;
}

.buttonIcon {
  font-size: $iconFontSize;
}

/**************************/
/*** Icon Button styles ***/
/**************************/

.iconButton {
  @extend .basicButtonStyles;
  @extend .buttonIcon;
  outline: none; // replaced by the 'focus' border style
  color: $darkest-gray;
  background-color: $white;

  &:hover {
    background-color: $background-gray;
  }

  &:active {
    background-color: $disabled-gray;
  }

  &:focus {
    background-color: $disabled-gray;
    border: $border;
    box-shadow: $boxShadow;
  }

  &:disabled {
    color: $disabled-gray;
  }
}

/*******************************************************************/
/*** Context Menu Button styles (also, the Overflow Icon Button) ***/
/*******************************************************************/

.contextMenuButton {
  @extend .basicButtonStyles;
  @extend .buttonText;
  outline: none; // replaced by the 'focus' border style
  color: $darkest-gray;
  background-color: $white;

  &:hover {
    background-color: $background-gray;
  }

  &:active {
    background-color: $disabled-gray;
  }

  &:focus {
    background-color: $disabled-gray;
    border: $border;
    box-shadow: $boxShadow;
  }

  &:disabled {
    color: $disabled-gray;
  }
}

// Extend this conditionally when the context menu is open to show the button in
// an "active" state
.contextMenuButtonOpen {
  background-color: $light-gray;
}
