@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.listItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $background-gray;

  i {
    padding-right: 6px;
  }

  .name {
    flex: 1;
    padding: 24px 0;
  }

  .control {
    flex: 0;
    padding-right: 12px;
  }
}
