.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px;

  .timePicker {
    display: flex;
    justify-content: space-between;
    width: 252px;
  }
}
