@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';

.row {
  @extend .paragraph-regular;
}

.detail {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.refundIcon {
  margin-right: 6px;
}
