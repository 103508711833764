// removes the number spinners
.numberInput[type='number']::-webkit-inner-spin-button,
.numberInput[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.futureFaysLimit input {
  width: 30%;
}
