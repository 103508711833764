@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.scheduleNoDescription {
  @extend .paragraph-regular;
  font-style: italic;
  color: $text-gray;
  font-family: $font-family;
  span {
    margin-right: 6px;
  }
}

.secondary {
  color: $text-gray;
  font-size: 14px;
}

.scheduleWithEffect {
  font-family: $font-family;
  span {
    margin-right: 6px;
  }
}

.magenta {
  color: $magenta;
  font-weight: $font-weight-semibold;
  padding-left: 6px;
}

.bold {
  font-weight: $font-weight-semibold;
  padding-left: 6px;
}
