@import '../../styles/responsive';
.adminToolbar {
  display: flex;
  align-items: center;
  width: 50%;
  min-width: 450px;
  gap: 12px;
  height: 36px;

  @media screen and (max-width: $breakpoint-mobile) {
    min-width: 100%;
    display: inherit;
    height: auto;
  }
}

.adminSearchFilter {
  min-width: 100%;
}

.adminStatusIndicator {
  @media screen and (max-width: $breakpoint-tablet) {
    display: inline-flex;
  }
}
