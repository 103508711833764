@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.squareButton {
  @extend .paragraph-bold;
  border: none;
  background: $background-gray;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 160px;
}

.squareLogo {
  width: 16px;
  margin-right: 6px;
}
