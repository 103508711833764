// icons set
@font-face {
  font-family: 'dashboard-icons';
  src: url('assets/fonts/dashboard-icons.eot?kffs25');
  src: url('assets/fonts/dashboard-icons.eot?kffs25#iefix') format('embedded-opentype'),
    url('assets/fonts/dashboard-icons.ttf?kffs25') format('truetype'),
    url('assets/fonts/dashboard-icons.woff?kffs25') format('woff'),
    url('assets/fonts/dashboard-icons.svg?kffs25#dashboard-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dashboard-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dashboard-icons_user-checkmark:before {
  content: '\e931';
}
.icon-dashboard-icons_external-link:before {
  content: '\e930';
}
.icon-dashboard-icons_frown-face:before {
  content: '\e92e';
}
.icon-dashboard-icons_smile-face:before {
  content: '\e92d';
}
.icon-dashboard-icons_caret-right:before {
  content: '\e92b';
}
.icon-dashboard-icons_trash-can:before {
  content: '\e92a';
}
.icon-dashboard-icons_star:before {
  content: '\e929';
}
.icon-dashboard-icons_collapsed-section:before {
  content: '\e926';
}
.icon-dashboard-icons_expanded-section:before {
  content: '\e927';
}
.icon-dashboard-icons_partialcollapsed-section:before {
  content: '\e928';
}
.icon-dashboard-icons_car:before {
  content: '\e924';
}
.icon-dashboard-icons_caret-down:before {
  content: '\e925';
}
.icon-dashboard-icons_caret-down-tiny:before {
  content: '\e92c';
}
.icon-dashboard-icons_cutlery:before {
  content: '\e922';
}
.icon-dashboard-icons_takeout-bag:before {
  content: '\e923';
}
.icon-dashboard-icons_cog:before {
  content: '\e921';
}
.icon-dashboard-icons_link:before {
  content: '\e920';
}
.icon-dashboard-icons_heart-outline:before {
  content: '\e91f';
}
.icon-dashboard-icons_pencil_pencil:before {
  content: '\e91e';
}
.icon-dashboard-icons_refresh:before {
  content: '\e91d';
}
.icon-dashboard-icons_arrow-right:before {
  content: '\e91c';
}
.icon-dashboard-icons_info-circle:before {
  content: '\e91b';
}
.icon-dashboard-icons_reverse-arrow:before {
  content: '\e91a';
}
.icon-dashboard-icons_alert:before {
  content: '\e919';
}
.icon-dashboard-icons_calendar:before {
  content: '\e916';
}
.icon-dashboard-icons_camera:before {
  content: '\e917';
}
.icon-dashboard-icons_speech-bubble:before {
  content: '\e918';
}
.icon-dashboard-icons_arrow-left:before {
  content: '\e914';
}
.icon-dashboard-icons_clock:before {
  content: '\e915';
}
.icon-dashboard-icons_questionmark-circle:before {
  content: '\e913';
}
.icon-dashboard-icons_angle-down:before {
  content: '\e900';
}
.icon-dashboard-icons_angle-left:before {
  content: '\e901';
}
.icon-dashboard-icons_angle-right:before {
  content: '\e902';
}
.icon-dashboard-icons_angle-up:before {
  content: '\e903';
}
.icon-dashboard-icons_checkmark-circle:before {
  content: '\e904';
}
.icon-dashboard-icons_checkmark:before {
  content: '\e905';
}
.icon-dashboard-icons_close:before {
  content: '\e906';
}
.icon-dashboard-icons_drag:before {
  content: '\e907';
}
.icon-dashboard-icons_ellipsis:before {
  content: '\e908';
}
.icon-dashboard-icons_eye-closed:before {
  content: '\e909';
}
.icon-dashboard-icons_eye-open:before {
  content: '\e90a';
}
.icon-dashboard-icons_location:before {
  content: '\e90b';
}
.icon-dashboard-icons_martini:before {
  content: '\e90c';
}
.icon-dashboard-icons_menu:before {
  content: '\e90d';
}
.icon-dashboard-icons_remove-circle:before {
  content: '\e90e';
}
.icon-dashboard-icons_remove:before {
  content: '\e90f';
}
.icon-dashboard-icons_search:before {
  content: '\e910';
}
.icon-dashboard-icons_star-circle:before {
  content: '\e911';
}
.icon-dashboard-icons_tri-dot:before {
  content: '\e912';
}
.icon-dashboard-icons_collapsed-section:before {
  content: '\e926';
}
.icon-dashboard-icons_expanded-section:before {
  content: '\e927';
}
.icon-dashboard-icons_partialcollapsed-section:before {
  content: '\e928';
}
.icon-dashboard-icons_share:before {
  content: '\e92f';
}
