@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.container {
  padding: 12px 48px 0;
}

.item:hover {
  background-color: $background-gray-op;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.searchContainer {
  flex: 1;
}

.search {
  max-width: 492px;
  margin-right: 12px;
}

.buttonsOverflow {
  display: none;
}

.noResults {
  color: $text-gray;
  font-style: italic;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px 0;
  }

  .search {
    width: 100%;
    display: flex;
  }

  .buttons {
    display: none;
  }

  .buttonsOverflow {
    display: block;
    padding-left: 12px;
  }
}
