@import 'styles/colors.scss';
@import 'styles/responsive.scss';

.greyText {
  color: $text-gray;
  font-style: italic;
}

.offsetItem {
  margin-left: 100px;
}

.specialHours {
  .dateLabel {
    font-weight: 100;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .offsetItem {
    margin-top: 5px;
    margin-left: 0px;
  }
}
