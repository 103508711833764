.input-error {
  @extend .tiny-paragraph-bold;
  color: $orange-tint;
  line-height: 1;
}

// container styles
.container.input-error-container {
  padding-top: 0;
  flex-basis: 100%;
  padding-bottom: 12px;
}
