@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.paymentHistoryItem {
  @extend .paragraph-regular;
  display: flex;
  margin-bottom: 18px;

  @media screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }
}

.historyDate {
  flex-basis: 35%;
  flex-shrink: 0;
}

.historyAmount {
  flex-basis: 20%;
  flex-shrink: 0;
  padding: 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media screen and (max-width: $breakpoint-tablet) {
    padding: 0;
  }
}

.historyDescription {
  flex-basis: 45%;
  flex-shrink: 1;
}
