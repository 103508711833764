@import '../../../styles/responsive.scss';

.input {
  max-width: 584px;
}

.fullWidth {
  max-width: 100%;
}

@media screen and (max-width: $breakpoint-mobile) {
  .input {
    width: 100%;
  }
}
