@import '../../../styles/colors.scss';

.noSelectedText {
  color: $text-gray;
}
.truncatedIframeLink {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} 

.imageUploaderPlaceholder {
  border: solid $background-gray 1px;
  background-color: $background-gray;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cameraIcon {
  color: $text-gray;
  font-size: 32px;
}

.loadingSpinner {
  width: 60px;
}