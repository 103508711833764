@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';
@import '../../styles/typography.scss';

.container,
.containerShaded {
  flex: 1;
  border-radius: 6px;
  border: solid $disabled-gray 1px;
}

.containerVerticalSpacing {
  margin-bottom: 24px;
}

.containerVerticalSpacing:last-of-type {
  margin-bottom: 0;
}

.containerTransparent {
  flex: 1;
  margin: 0;
}

.container {
  background-color: $white;
}

.containerShaded {
  background-color: $light-gray;
}

.header {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  min-height: 72px;
}

.headerMain {
  flex: 1;
  display: flex;
  align-items: center;
}

.title {
  flex: 1;
  overflow: hidden;
  min-width: 0;
}

.content {
  padding: 24px;
}

.contentWithTitle {
  padding-top: 0;
}

.contentFull {
  padding: 0;
}

.contentNoChildren {
  padding-top: 0;
}

.contentTransparent {
  padding: 0;
}

.section {
  padding-bottom: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid $disabled-gray;
}

.section:last-child {
  padding: 0;
  margin: 0;
  border-bottom: none;
}

@media screen and (max-width: $breakpoint-mobile) {
  .header {
    padding: 6px 12px;
  }

  .content {
    padding: 12px;
  }

  .contentFull {
    padding: 0;
  }

  .contentWithTitle {
    padding-top: 0;
  }
}
