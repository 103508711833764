@import 'styles/colors.scss';
@import 'styles/responsive.scss';

.toggles {
  padding-top: 12px;
  padding-bottom: 12px;
}

.item {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 12px 30px;
  border-bottom: 1px $background-gray solid;

  .name {
    width: 75%;
  }

  .assignedLocationsCount {
    padding-right: 14px;
  }

  .controls {
    margin-left: auto;
  }
}
