@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.name {
  flex: 1;
  overflow: hidden;
}

.large {
  @extend .paragraph-regular;
}

.small {
  @extend .small-paragraph-regular;
}

.compactContainer {
  display: flex;
}

.details {
  flex: 1;
  color: $text-gray;
  @extend .small-paragraph-regular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.compactContainer .details {
  flex-basis: 40%;
  margin-right: 24px;
}

.compactContainer .missing,
.compactContainer .name {
  flex-basis: 60%;
}

.parentDisabled,
.disabled,
.hidden,
.missing {
  color: $text-gray;
}

.disabled,
.missing {
  font-style: italic;
}

.separator {
  padding: 0 6px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .details {
    display: none;
  }
}
