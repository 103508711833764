@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.tableLayout {
  margin-top: 0;
}

.noAssignedLocations {
  color: $text-gray;
  font-style: italic;
  font-weight: $font-weight-regular;
  border-bottom: solid $main-panel-gray 1px;
  text-align: center;
  padding: 24px;

  @media screen and (min-width: $breakpoint-tablet) {
    white-space: pre-line;
  }
}
