.overflow-button {
  text-align: right;

  .overflow-button__button-wrapper {
    color: $darkest-gray;
  }
  .pulse {
    border: 1px solid $border-gray;
    animation: pulse 1400ms infinite;
  }
  @keyframes pulse {
    0% {
      color: $darkest-gray;
    }
    50% {
      color: $disabled-gray;
    }
    100% {
      color: $dark-text-gray;
    }
  }
  i {
    font-size: 20px;
  }
}

.iOScolor {
  color: $darkest-gray;
}
