@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 72px;
  }
}

.list {
  overflow: auto;
  height: 100%;
  max-height: calc(75vh - 156px);

  &.isInModal {
    min-height: 24px;
  }
}

.empty {
  padding-top: 24px;
}

.emptyFilter {
  padding-top: 24px;
  color: $text-gray;
  font-style: italic;
}

.controls {
  text-align: center;

  .spinner {
    opacity: 0;
    height: 48px;
  }

  .endOfList {
    @extend .tiny-paragraph-regular;
    display: none;
    color: $text-gray;
  }

  &.paginationLoading {
    .spinner {
      opacity: 1;
    }

    .endOfList {
      display: none;
    }
  }

  &.noMoreResults {
    .spinner {
      display: none;
    }

    .endOfList {
      display: block;
      text-align: center;
    }
  }
}
