@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.menuEntry {
  background: $white;
  border: solid $disabled-gray 1px;
  border-radius: 6px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.menuEntryDetails {
  width: 100%;
}

.menuEntryLink {
  display: block;
}

.menuEntryDisplayNameWrapper {
  display: flex;
  align-items: center;
}

.menuEntryDisplayName {
  @extend .paragraph-regular;
}

.menuEntryDisplayNameDisabled {
  @extend .paragraph-regular;
  color: $text-gray;
  font-style: italic;
  margin-right: 6px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .menuEntry {
    padding: 12px;
  }
}

.readonlyValue {
  padding-bottom: 12px;
}
