@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

.toggleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  background-color: $white;

  &.hidden {
    display: none;
  }
}

.label {
  @extend .paragraph-regular;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 24px;
}
