@import '../../../styles/colors';
@import '../../../styles/responsive';
@import '../../../styles/typography';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nestingLevel1 {
  margin-left: 18px;
}

.nestingLevel2 {
  margin-left: 36px;
}

.nestingLevel3 {
  margin-left: 54px;
}

.nestingLevel4 {
  margin-left: 72px;
}

.modGroup {
  flex-grow: 1;
  min-height: 48px;
  background-color: $light-gray;
  display: flex;
  align-items: center;
  padding: 0 24px 0 12px;

  &.compactView {
    min-height: 24px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 12px;
  }
}

.modGroupName,
.modGroupNameLink {
  flex-grow: 1;
  @extend .header-5;
}

.compactView .modGroupNameLink {
  @extend .header-6;
  text-transform: uppercase;
}

.modGroupNameLink {
  color: $magenta;
}

.options {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
