@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.container {
  display: flex;
  margin: 6px 0;

  @media screen and (max-width: $breakpoint-tablet-mobile-midway) {
    flex-direction: column;
  }
}

.header {
  @extend .header-5;
  flex-basis: 33.3%;
  padding-right: 6px;
  padding-top: 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding-top: 0;
  }
}

.inputContainer {
  display: flex;
  flex-basis: 66.6%;
  padding-left: 6px;

  @media screen and (max-width: $breakpoint-tablet-mobile-midway) {
    padding-left: 0;
    padding-top: 12px;
  }
}

.timeInputContainer {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  margin-right: 12px;
}

.errorMessage {
  @extend .tiny-paragraph-bold;

  // combine bottom margin with line heigh to make 24px between the bottom of
  // the time input container and the guest message container
  line-height: 16px;
  height: 16px;
  margin-bottom: 8px;
  color: $orange-tint;
}
