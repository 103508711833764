@import 'styles/typography.scss';

.forcedMods {
  width: 100%;
  margin: 12px 0;
}

.forcedModifierLink {
  color: #a7135b;
}

.forcedMod {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
}

.displayNameContainer {
  @extend .paragraph-regular;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.removeAndNumberOfLocationsContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.assgnedLocationsWidth {
  width: 50px;
}
