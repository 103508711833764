@import '../../styles/typography';

.container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  @extend .header-3;
}

.syncButton {
  display: flex;
  align-items: center;
}

.syncButtonIcon {
  @extend .paragraph-regular;
  margin-right: 6px;
}

.syncButtonText {
  @extend .paragraph-bold;
}
