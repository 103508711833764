@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.noLocations {
  color: $text-gray;
  font-style: italic;
  font-weight: $font-weight-regular;
  border-bottom: solid $main-panel-gray 1px;
  text-align: center;
  padding: 24px;
}

.nameCell {
  @media screen and (min-width: $breakpoint-tablet) {
    padding-left: 50px;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    max-width: 312px;
    width: 312px;
  }
}

.assignedMenuGroupsCell {
  overflow: unset;

  @media screen and (min-width: $breakpoint-mobile) {
    border-left: 1px solid #eeeeee;
    padding-left: 24px !important; // override table styling
  }
}

.activeMenuConfigTooltip {
  white-space: break-spaces;
  text-transform: none;
}

.table {
  // required for tooltip to display properly on the bottom result in the mobile view.
  @media screen and (max-width: $breakpoint-mobile) {
    overflow-y: unset;
  }
}
