@import '../../styles/colors';
@import '../../styles/typography';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.week {
  display: flex;
  flex-direction: row;
}

.weekHeader {
  font-weight: bold;
  font-size: 12px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-family: $font-family;
}

.weekDay {
  font-size: 16px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: $text-gray;
  border-radius: 0;
  background: transparent;
  border: none;
  font-family: $font-family;

  &:focus {
    outline-offset: -2px;
    outline: $magenta solid 2px;
  }

  &.clickableDay {
    cursor: pointer;

    &:hover {
      background-color: $light-gray;
    }
  }

  &.currentMonth {
    color: $black;

    &.firstSelectedDay,
    &.lastSelectedDay,
    &.singleSelectedDay {
      background-color: $magenta;
      color: $white;
      font-weight: bold;

      &:focus {
        outline-offset: -2px;
        outline: $black solid 2px;
      }

      &:hover {
        background-color: $magenta-tint;
      }
    }

    &.firstSelectedDay {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &.lastSelectedDay {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.singleSelectedDay {
      border-radius: 6px;
    }
  }

  &.betweenSelectedDays {
    border-radius: 0;
    background-color: $lightmagenta;
    color: $magenta;

    &:hover {
      background-color: $lightmagenta-tint;
    }
  }

  &.rangeHover {
    background-color: $lightmagenta;
    color: $magenta;
  }
}
