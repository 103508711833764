@import '../../styles/colors.scss';

// Container sizes
$tiny: 6px;
$small: 12px;
$medium: 24px;
$large: 36px;
$huge: 48px;

// HR borders
$border-width: 1px;
$border-style: solid;

.container {
  display: flex;
  justify-content: center;

  &.horizontal {
    flex-direction: column;

    &.tiny {
      height: $tiny;
    }
    &.small {
      height: $small;
    }
    &.medium {
      height: $medium;
    }
    &.large {
      height: $large;
    }
    &.huge {
      height: $huge;
    }
  }

  &.vertical {
    &.tiny {
      width: $tiny;
    }
    &.small {
      width: $small;
    }
    &.medium {
      width: $medium;
    }
    &.large {
      width: $large;
    }
    &.huge {
      width: $huge;
    }
  }
}

.divider {
  border: none;

  &.horizontal {
    border-top-width: $border-width;
    border-top-style: $border-style;
  }

  &.vertical {
    border-left-width: $border-width;
    border-left-style: $border-style;
  }

  &.lightGray {
    border-color: $main-panel-gray;
  }
  &.gray {
    border-color: $disabled-gray;
  }
}
