@import '../../styles/responsive.scss';
@import '../../styles/typography.scss';

.container {
  padding: 12px 48px 0;
}

.title {
  @extend .header-2;
}

.controls {
  margin-top: 12px;
}

.content {
  margin-top: 24px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px 0;
  }
}
