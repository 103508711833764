@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.container {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  align-items: center;
}

.button {
  @extend .paragraph-regular;
  flex: 1;
  margin-right: 6px !important;
}

.errorMessage {
  @extend .tiny-paragraph-bold;
  display: flex;
  color: $orange-tint;
  line-height: 12px;
}
