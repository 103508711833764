@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.content {
  border-top: solid 1px $main-panel-gray;
  padding: 0;
}

.subtitle {
  .assignedTo {
    font-weight: bold;
  }
}

.emptyResult {
  border: none;
}

.buttons {
  display: flex;
  gap: 12px;
  align-items: center;

  @media screen and (max-width: $breakpoint-tablet) {
    display: none;
  }
}

.overflow {
  display: none;

  @media screen and (max-width: $breakpoint-tablet) {
    display: block;
  }
}

.warningMessageIconSpacing {
  margin-right: 5px;
}
