@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.details {
  display: flex;
  padding: 6px 24px;
  min-height: 72px;
}

.details {
  align-items: flex-start;
}

.content {
  flex: 1;
}

.name {
  flex: 1;
}

.description {
  margin: 6px 0;
  flex: 1;
  max-width: 486px;
}

.controls {
  display: flex;
}

.oldControls {
  padding-top: 6px;
}

.defaultAppearanceContent {
  display: none;
}

.label {
  @extend .small-paragraph-bold;
}

.selector {
  margin: 6px 0 0 12px;
}

.button {
  margin-left: 12px;
}

.overflow {
  display: none;
}

.itemGallery,
.itemList {
  border-top: 1px solid $disabled-gray;
}

.itemList {
  border-top: 1px solid $main-panel-gray;
}

.itemGalleryTitleContainer {
  display: flex;
  align-items: center;
}

.itemGalleryTitle,
.itemListTitle {
  @extend .header-4;
}

.itemGalleryEnabled {
  padding-left: 12px;
}

.itemGalleryButton,
.itemListButton {
  margin-right: 12px;
}

.itemGalleryNoItems {
  display: flex;
  justify-content: center;
  padding: 24px;
}

.itemGalleryFormHeader {
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  border-bottom: 1px solid $background-gray;
}

.itemGalleryFormHeaderInputs {
  width: 100%;
  max-width: 486px;
}

.itemGalleryFormHeaderInputs div {
  margin-top: 6px;
}

.itemGalleryFormHeaderInputs div:first-child {
  margin-top: unset;
}

.itemGalleryNoItemsText {
  @extend .paragraph-regular;
  color: $text-gray;
  text-align: center;
}

.itemGalleryNoItemsText span {
  margin-left: 3px;
}

.itemGalleryNoItemsText span span {
  margin-left: 0;
  font-style: italic;
}

.count,
.warning {
  padding-left: 6px;
}

.warning {
  color: $orange-tint;
}

.warningIcon {
  padding-left: 6px;
}

.itemsEmpty {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $background-gray;
  min-height: 72px;
  padding: 0 24px;
}

.itemsMessage {
  flex: 100%;
  text-align: center;
}

.itemsMessageText {
  @extend .paragraph-regular;
  margin: 0 3px;
}

.itemsMessageText:last-of-type {
  margin-right: 0;
}

.itemsMessageAction {
  @extend .paragraph-bold;
  color: $magenta;
}

.footer {
  border-top: 1px solid $main-panel-gray;
}

.buttons {
  padding: 12px 24px;
  max-width: 100%;
}

@media screen and (max-width: 860px) {
  .defaultAppearanceContent {
    display: block;
  }

  .defaultAppearanceControl {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .details {
    display: flex;
    align-items: baseline;
    padding: 0 24px;
    min-height: 72px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .details {
    padding: 0 12px;
  }

  .defaultAppearanceControl {
    display: none;
  }

  .defaultAppearanceContent {
    display: block;
  }

  .button,
  .itemGalleryButton,
  .itemListButton {
    display: none;
  }

  .overflow {
    display: block;
    padding-left: 12px;
    position: relative;
    top: 3px;
  }

  .dragHandle {
    margin: 12px 12px 0 0;
  }

  .itemGalleryNoItems {
    padding: 12px;
  }
}
