.loading-spinner {
  background-color: $main-panel-gray;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;

  img {
    width: 148px;
    position: relative;
    top: 30%;
  }
}
