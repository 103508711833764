@import '../../styles/colors.scss';

$iconWidthHeight: 48px;

.icon {
  max-width: $iconWidthHeight;
  min-width: $iconWidthHeight;
  max-height: $iconWidthHeight;
  min-height: $iconWidthHeight;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 24px;
  background-color: $black;
  color: $white;
}
