@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.itemGalleryFormHeader {
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
}

.itemGalleryFormHeaderInputs {
  width: 33%;
}

.itemGalleryFormHeaderInputs div {
  margin-top: 6px;
}

.itemGalleryFormHeaderInputs div:first-child {
  margin-top: unset;
}

.itemGalleryNoItems {
  border-top: solid $main-panel-gray 1px;
  border-bottom: solid $main-panel-gray 1px;
  display: flex;
  justify-content: center;
  padding: 24px;
}

.itemGalleryNoItemsText {
  @extend .paragraph-regular;
  color: $text-gray;
  text-align: center;
}

.itemGalleryNoItemsText span {
  margin-left: 3px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .itemGalleryFormHeaderInputs {
    width: 50%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .itemGalleryFormHeader {
    padding: 12px;
    flex-direction: column;
  }

  .itemGalleryFormHeaderInputs {
    width: 100%;
  }

  .itemGalleryNoItems {
    padding: 12px;
  }
}
