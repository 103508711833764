@import '../../../styles/colors';
@import '../../../styles/responsive';

.Card {
  background: $white;
  border: 1px solid $disabled-gray;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $breakpoint-mobile) {
    padding: 24px 12px;
  }
  &GeneralText {
    font-size: 40px;
    font-weight: bold;
    color: $black;
    line-height: 1.8;
  }
  &Title {
    text-transform: uppercase;
    font-weight: bold;
    color: $text-gray;
    font-size: 14px;
  }
}
