@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.addTagButton {
  @extend .paragraph-bold;
  color: $magenta;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tagsWrapper {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
