@import '../../styles/colors.scss';

.dropdownIcon {
  font-size: 20px;
  color: $darkest-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -12px;
}
