@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.container {
  &.hidden {
    display: none;
  }
}

.separator {
  display: flex;
  align-items: center;
  height: 72px;

  @media screen and (max-width: $breakpoint-mobile) {
    height: 48px;
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.title {
  @extend .header-5;
  color: $text-gray;
}

.line {
  border: 0.5px solid $disabled-gray;
  flex-grow: 1;
  margin-left: 12px;
}
