@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/responsive.scss';

$tinyTextLineHeight: 1.2em;

.container {
  display: flex;
  align-items: center;
  color: $magenta;
}

.locationText {
  @extend .paragraph-bold;
  padding-left: 3px;
}

.link {
  color: $magenta;
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
}

.changeText {
  @extend .tiny-paragraph-regular;
  align-self: flex-end;
  padding-left: 6px;
}

.iconContainer {
  padding-left: 16px;
}

.icon {
  @extend .paragraph-bold;
  color: $magenta;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    justify-content: flex-end;
    color: $text-gray;
  }

  .locationText {
    font-family: $font-family;
    font-size: 10px;
    line-height: $tinyTextLineHeight;
    font-weight: $font-weight-regular;
    margin-right: 6px;
    max-width: 90px;

    /* NOTE: non-standard feature
     *
     * The following selectors are required to automatically limit the lines of
     * text to two, and truncate the text with an ellipsis. There is currently
     * no other CSS-only way to do this, the alternative would require
     * javascript code to break up the text into multiple containers by the
     * number of characters.
     * 
     * Standardized implementation of 'line-clamp' appears to be planned for the
     * future, but for now the -webkit- prefix is supported in all browsers 
     * (except IE). Once there is a standard implementation available, this 
     * should be replaced and re-tested.
     * 
     * Here is a list tracking browser version compatibility: 
     *   https://caniuse.com/css-line-clamp
    */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}
