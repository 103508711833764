@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.shortCodeContainer {
  display: flex;
  align-items: center;
}

.validationFields {
  width: 100%;
}
