.form {
  margin-top: 0 !important;
}

.formControlContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 504px;
}
