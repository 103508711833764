@import 'styles/responsive.scss';
@import 'styles/colors.scss';
@import 'styles/typography.scss';

.itemGalleryTitleContainer {
  display: flex;
  align-items: center;
}

.itemGalleryTitle,
.itemListTitle {
  @extend .header-4;
}

.itemGalleryTitle {
  &.isDisabled {
    color: $text-gray;
    font-style: italic;
  }
}

.itemGalleryEnabled {
  padding-left: 12px;
}

.itemGallery,
.itemList {
  border-top: 1px solid $disabled-gray;
}

.details {
  display: flex;
  padding: 6px 24px;
  min-height: 72px;
}

.details {
  align-items: flex-start;
}

.content {
  flex: 1;
}

.itemGalleryNameAndDescriptionContainer {
  margin-top: 15px;
}

.itemGalleryName,
.itemGalleryNameDisabled {
  @extend .paragraph-bold;
}

.itemGalleryDescription,
.itemGalleryDescriptionDisabled {
  max-width: 702px;
  @extend .paragraph-regular;
  color: $text-gray;
}

.itemGalleryNameDisabled,
.itemGalleryDescriptionDisabled {
  color: $text-gray;
  font-style: italic;
}

.name {
  flex: 1;
}
.description {
  @extend .paragraph-regular;
  color: $text-gray;
  max-width: 60%;
}
.controls {
  display: flex;
}

.label {
  @extend .small-paragraph-bold;
  margin-left: 10px;
}

.noItems {
  @extend .paragraph-regular;
  color: $text-gray;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;
}

.defaultAppearanceContent {
  display: none;
}

.schedules {
  margin-top: 10px;
}
.itemList {
  border-top: 1px solid $main-panel-gray;
}

.footer {
  border-top: 1px solid $main-panel-gray;
}

.itemGalleryDisabledBadge {
  display: inline;
}

@media screen and (max-width: 860px) {
  .defaultAppearanceContent {
    display: block;
  }

  .defaultAppearanceControl {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .defaultAppearanceControl {
    display: none;
  }

  .defaultAppearanceContent {
    display: block;
  }

  .label {
    margin-left: 0px;
  }

  .schedules {
    margin-top: 0px;
  }
}
