@import '../../../styles/responsive.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.contentContainer {
  padding: 12px 48px 0;

  @media screen and (max-width: $breakpoint-tablet) {
    padding: 12px 24px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px;
  }
}

.controlContainer {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  align-items: center;
}

.searchContainer {
  display: flex;
  align-items: center;
  height: 48px;
  max-width: 486px;
  margin-right: 6px;
  flex-grow: 1;

  @media screen and (max-width: $breakpoint-mobile) {
    max-width: unset;
    width: 100%;
  }
}

.textLinkContainer {
  display: flex;
  flex-grow: 1;
  min-height: 36px;
  align-items: center;
}

.searchInput {
  background-color: $white !important;
}

.item:hover {
  background-color: $background-gray-op;
}

.emptyResultMessage {
  @extend .paragraph-regular;
  max-width: 700px;
}
