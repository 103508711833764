@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.container {
  flex: 100%;
}

.itemsPanelFooter {
  border-top: solid 1px $background-gray;
}

.saveButton {
  margin-left: 12px !important;
}

.noItemsMessage {
  @extend .paragraph-regular;
  text-align: center;
  color: $text-gray;
  padding: 24px;
}

.dropAreaDragging {
  background-color: $disabled-gray;
}

.dragItem,
.dragItemDragging {
  border-top: 1px solid $background-gray;
  min-height: 72px;
  display: flex;
  align-items: center;
}

.dragItem {
  background-color: $white;
}

.dragItemDragging {
  background-color: $main-panel-gray;
}

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  font-size: 20px;
  color: $border-gray;
  margin-right: 12px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .itemsPanelFooter {
    padding: 12px;
  }

  .dragItem {
    align-items: flex-start;
  }

  .dragHandle {
    margin-top: 6px;
  }
}
