@import 'styles/responsive.scss';
@import 'styles/colors.scss';
@import 'styles/typography.scss';

.componentContainer {
  width: 60%;

  .isClosedContainer {
    height: 100%;
    display: flex;
    align-items: center;
    width: 255px;

    .isClosed {
      color: $text-gray;
      font-style: italic;
    }
  }

  .timeSlotColumn {
    display: flex;
    margin-left: 90px;
    overflow: hidden;
    align-items: center;

    .timePicker {
      display: flex;
      width: 255px;

      .timeDivider {
        align-items: center;
        margin: 5px;
        color: $text-gray;
      }
    }

    .timeError {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .daysList {
    .dayTimeRow {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $background-gray;

      .dayColumn {
        display: flex;
        width: 30%;
      }
    }

    .dayTimeRow:nth-child(7) {
      border: none;
    }
  }

  .customizePrepTimeContainer {
    display: flex;
    align-items: baseline;
    margin-top: 24px;

    .minOffsetTimeColumn {
      width: 30%;
    }

    .eachDayOffsetTimeColumn {
      margin-left: 90px;
      width: 60%;

      .minOffsetTimeInput {
        width: 186px;
      }

      .customOffsetWrapper {
        .offsetRow {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .componentContainer {
    width: 100%;
    .daysList {
      .dayTimeRow {
        display: block;
      }
    }

    .timeSlotColumn {
      margin-left: 30px;
    }

    .customizePrepTimeContainer {
      display: block;

      .minOffsetTimeColumn {
        width: 100%;
      }

      .eachDayOffsetTimeColumn {
        margin-left: 0px;
        width: 100%;

        .minOffsetTimeInput {
          width: 186px;
        }
      }
    }
  }

  .eachDayOffsetTimeColumn {
    .customOffsetWrapper {
      .offsetRow {
        margin-right: 40%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .componentContainer {
    .customizePrepTimeContainer {
      .eachDayOffsetTimeColumn {
        .minOffsetTimeInput {
          width: 186px;
        }
      }
    }
  }

  .eachDayOffsetTimeColumn {
    .customOffsetWrapper {
      .offsetRow {
        margin-right: 0;
      }
    }
  }
}
