@import '../../styles/typography';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 24px;

  .button {
    width: 24px;
    height: 24px;
  }
}

.displayText {
  align-self: center;
  font-weight: bold;
  font-size: 16px;
  margin: 0 auto;
  font-family: $font-family;
}
