@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.statusToggle {
  justify-content: left !important;
}

.unlinkButton {
  width: 200px;
}

.labelNote {
  font-size: 14px;
  line-height: 1.71;
  color: $text-gray;
  font-weight: $font-weight-regular;
}
