@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.container {
  padding-top: 12px;
}

.onAccountPaymentOption {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    padding-bottom: 12px;
  }

  .label {
    @extend .header-6;
    color: $text-gray;
    text-transform: uppercase;
  }

  .value {
    @extend .paragraph-regular;
  }
}
