@import 'styles/typography.scss';
@import 'styles/colors.scss';
@import 'styles/responsive.scss';

.forcedModsHeader {
  @extend .header-3;
}

.forcedModsLink {
  margin-top: 12px;
}

.modifiersHeader,
.pairingsHeader {
  display: flex;
  margin: 48px 48px 0;
  padding-bottom: 12px;
}

.modifiersHeaderText,
.pairingsHeaderText {
  @extend .header-2;
  flex: 1;
}

.modifiersHeaderControls,
.pairingsHeaderControls {
  display: flex;
}

.modifiersHeaderOverflow,
.pairingsHeaderOverflow {
  display: none;
}

.modifiers,
.pairings {
  border-top: 1px solid #d4d4d4;
  padding: 24px 0 0;
  margin: 0 48px;
}

.modifiersDragging {
  margin: 0 24px 18px;
}

.modifier,
.pairing {
  margin-bottom: 24px;
}

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  font-size: 20px;
  color: $dark-text-gray;
}

.alcoholQuantityNote {
  @extend .tiny-paragraph-regular;
  color: $text-gray;
  font-style: italic;
}

@media screen and (max-width: $breakpoint-mobile) {
  .modifiersHeader {
    margin: 48px 12px 0;
  }

  .modifiers,
  .pairingsHeader,
  .pairings {
    margin: 0 12px;
  }

  .pairingsHeader {
    margin: 24px 12px 0;
  }

  .modifiersHeaderControls,
  .pairingsHeaderControls {
    display: none;
  }

  .modifiersHeaderOverflow,
  .pairingsHeaderOverflow {
    display: flex;
    align-items: center;
  }
}
