@import '../../../styles/responsive.scss';

.targetsSearch {
  padding: 6px 0;
  white-space: nowrap;
}

.searchSelectFilterDisabledLoading {
  opacity: 0.4;
  pointer-events: none;
}
