@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';

.container {
  display: flex;
}

.buttonContainer {
  flex-grow: 0;
}

.orderingButton {
  @extend .contextMenuButton;
  display: flex;
  flex-grow: 0;
  padding-left: 12px;
  padding-right: 12px;

  &.isOpen {
    @extend .contextMenuButtonOpen;
  }
}

.orderOnText,
.orderOffText {
  display: flex;
  align-items: center;
}

.orderOnText {
  color: $green;
}

.orderOffText {
  color: $orange;
}

.disabledText {
  opacity: 0.4;
}

.orderSubText {
  margin-left: 6px;
  font-style: italic;
}

.statusIndicator {
  margin-right: 6px;
}

.openOptionsIcon {
  margin-left: 12px;
}
