@import '../../../styles/colors';
@import '../../../styles/typography';

.constraints {
  text-align: end;
  color: $text-gray;
}

.large {
  @extend .header-5;
}

.small {
  @extend .header-6;
}
