@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.square {
  background: $main-panel-gray;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.result {
  @extend .header-3;
}

.message {
  @extend .paragraph-regular;
  text-align: center;
  color: $text-gray;
  padding: 0 48px;
}
