@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.container {
  max-width: 100%;
}

.select {
  width: 100%;
  max-width: 500px;
}

.noMenusPlaceholder {
  margin-left: 6px;
  font-style: italic;
}

.noMenusPlaceholderIcon {
  color: $orange-tint;
}
