@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.warning {
  @extend .paragraph-regular;
  color: $orange-tint;
}

.priceDeleted {
  margin-left: 3px;
  margin-right: 6px;
  font-style: italic;
}

.priceLevelPrice {
  @extend .paragraph-regular;
}

.priceLevelId,
.priceLevelName {
  @extend .small-paragraph-regular;
  color: $text-gray;
  margin-left: 3px;
}
