@import 'styles/responsive.scss';

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .controls {
    flex-direction: column;
    gap: 0px;
  }
}
