@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.editPanelFooter {
  min-height: 72px;
  align-items: center;
  padding: 12px 24px;

  &.footerSeparator {
    margin-top: 12px;
    border-top: 1px solid $main-panel-gray;
  }
}

.mainContent {
  display: flex;
  justify-content: space-between;

  &.noStatusChange {
    justify-content: flex-end;
  }
}

.subContent {
  display: flex;
  justify-content: flex-end;
  padding: 9px 0 9px;
}

.warningMessage {
  width: auto !important;
}

@media screen and (max-width: $breakpoint-mobile) {
  .editPanelFooter {
    align-items: flex-start;
    padding: 12px;
  }

  .mainContent {
    flex-direction: column;
  }

  .buttons {
    padding-top: 12px;
  }
}
