@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.container {
  display: flex;
  align-items: center;
  height: 72px;
  border-bottom: 1px solid $background-gray;
}

.displayNameContainer {
  flex: 1;
  display: flex;
  margin-right: 12px;
  max-height: 72px;
  min-width: 0;
}

.displayName {
  @extend .paragraph-regular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rightColumns {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
  justify-content: flex-end;
  width: 96px;
}

.assignedLocationsContainer {
  width: 80px;
}

.numberOfLocations {
  padding-left: 5px;
  font-weight: bold;
}

.icon {
  font-size: 14px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    height: 120px;
    padding-top: 12px;
  }

  .controls {
    margin-bottom: 12px;
  }

  .assignedLocations {
    margin-right: 10px;
  }
}
