@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.primary {
  font-weight: $font-weight-bold;
}

.secondary {
  color: $text-gray;
}

.special {
  color: $green-tint;
}

.overridden {
  text-decoration: line-through;
}
