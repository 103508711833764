@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.container {
  padding: 12px 48px 0;
}

.roleList {
  padding: 0 48px;
}

.controls {
  margin-top: 6px;
}

.content {
  margin-top: 18px;
}

.noResultsBoxBorders {
  border-radius: 6px;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 12px 24px 0;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px 0;
  }
}

.roleDataColumn {
  min-width: 0;
  padding: 0 6px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.roleName,
.userCount {
  @extend .paragraph-regular;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
}

.roleDescription {
  @extend .small-paragraph-regular;
  color: $text-gray;
  word-break: break-word;
}

.placeholderRoleDescription {
  @extend .roleDescription;
  font-style: italic;
}
