@import '../../styles/colors';

.container {
  border-radius: 6px;
  border: solid $disabled-gray 1px;
  margin-bottom: 24px;
  background-color: $white;
  padding: 12px 0;
}

.container:last-of-type {
  margin-bottom: 0;
}
