@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.container {
  display: flex;
}

.field {
  flex: 1;
  padding-top: 24px;
}

.fieldTitle {
  font-weight: $font-weight-bold;
}

.fieldText {
  padding-top: 24px;
}

.fieldInput {
  padding-top: 12px;
}

.fieldMessage {
  color: $orange-tint;
  font-size: 12px;
  font-weight: $font-weight-bold;
}
