.card-wrapper {
  background-color: $white;
}

.card-content {
  min-height: 72px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $main-panel-gray;
  background-color: $white;
  padding: 12px 24px;
}

.removeMinHight {
  min-height: 0;
  padding: 6px 24px;
}

a > .card-wrapper > .card-content:hover {
  background-color: $background-gray-op;
}

@media screen and (max-width: $breakpoint-mobile) {
  .card-content {
    padding: 12px;
  }

  .locations {
    margin: 24px 12px 0;
  }
}
