@import '../../styles/colors';
@import '../../styles/responsive';

.headerCell {
  &--comment {
    @media screen and (max-width: $breakpoint-mobile) {
      padding-bottom: 14px;
    }
  }
}
.cell {
  &Comment {
    min-width: 200px;
  }
}

.feedback {
  overflow: auto;
  background: $white;
  &__widgets {
    display: flex;
    justify-content: center;
    border-bottom: 12px solid $background-gray;
    &Container {
      background-color: $background-gray;
      & > div {
        padding: 12px;
        background-color: $white;
      }
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(637px, 1fr));
      grid-gap: 1px;
      margin: 12px 36px;
      width: calc(100% - 72px);
      max-width: 1696px;

      @media screen and (max-width: $breakpoint-tablet) {
        grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
        margin: 12px;
        width: calc(100% - 24px);
      }
      @media screen and (max-width: $breakpoint-mobile) {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
    }
  }
  &__line {
    border-right: 1px solid $background-gray;
    display: block;
    margin: 12px;
    @media screen and (max-width: $breakpoint-tablet) {
      margin: 0 30px;
    }
    @media screen and (max-width: $breakpoint-mobile) {
      border-bottom: 1px solid $background-gray;
      display: flex;
    }
  }
}
