@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.link {
  @extend .paragraph-bold;
  color: $magenta;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &.normalFontWeight {
    font-weight: normal;
  }
}

.disabled {
  color: $magenta-op;
  cursor: default;
}
