@import '../../styles/colors.scss';
@import '../../styles/typography.scss';
@import '../../styles/z-index.scss';

.container {
  position: relative;
  width: 100%;
}

.label {
  position: absolute;
  top: -4px;
  left: 8px;
  z-index: $text-input-nested-label-z-index;
  background-color: $white;
  padding: 0 4px;
  @extend .tiny-paragraph-bold;
  line-height: 16px;
  color: $text-gray;

  &.hidden {
    display: none;
  }

  &.loading {
    color: #{$text-gray}80;
  }

  &.error {
    color: $orange-magenta-tint;
  }
}
