@import '../../../styles/colors';
@import '../../../styles/typography';

.preview {
  @extend .tiny-paragraph-regular;
  background-color: $black;
  color: $white;
  padding: 0 12px;
  border-radius: 6px;
}

.disabled {
  background-color: $disabled-gray;
}
