@import 'styles/responsive.scss';

.groupItemsHeaderOverflow {
  display: none;
}
.groupItemsHeaderButton {
  display: flex;
}

@media screen and (max-width: $breakpoint-mobile) {
  .groupItemsHeaderOverflow {
    display: flex;
  }
  .groupItemsHeaderButton {
    display: none;
  }
}
