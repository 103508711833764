@import '../../../styles/responsive.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.maxNumberOfDrinks {
  width: 30% !important;
}

.orderThottlingInputs {
  width: 70% !important;
}

@media screen and (max-width: $breakpoint-tablet) {
  .orderThottlingInputs {
    width: 67% !important;
  }
  .form .form__control .form__control__label {
    width: 100% !important;
  }
  .maxNumberOfDrinks {
    margin-bottom: 34px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .orderThottlingInputs {
    width: inherit;
  }
  .maxNumberOfDrinks {
    width: inherit;
  }
}
