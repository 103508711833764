@import '../../styles/colors.scss';
@import '../../styles/responsive.scss';
@import '../../styles/typography.scss';
@import '../../styles/z-index.scss';

.container {
  position: relative;
}

.tooltip,
.tooltipShown,
.tooltipOnTheLeft,
.tooltipOnTheLeftShown {
  z-index: $modal-z-index;
  position: absolute;
  // This width is unfortunately duplicated in the TOOLTIP_SAFE_AREA constant in the component, so please keep in sync.
  width: 274px;
  top: -12px;
  @extend .tiny-paragraph-regular;

  @media screen and (max-width: $breakpoint-mobile) {
    // This width is unfortunately duplicated in the TOOLTIP_SAFE_AREA_MOBILE constant in the component, so please keep
    // in sync.
    width: 174px;
  }
}

.tooltip,
.tooltipShown {
  left: 24px;
}

.tooltipOnTheLeft,
.tooltipOnTheLeftShown {
  left: -284px;
  @media screen and (max-width: $breakpoint-mobile) {
    left: -184px;
  }
}

.tooltip,
.tooltipOnTheLeft {
  display: none;
}

.container:hover > .tooltip,
.tooltipShown,
.container:hover > .tooltipOnTheLeft,
.tooltipOnTheLeftShown {
  display: block;
}
