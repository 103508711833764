@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.container {
  @extend .header-4;
  display: flex;
  background: transparent;
  border: none;
  text-align: left;

  &.editable {
    cursor: pointer;
    color: $magenta;
  }
}

.iconContainer {
  display: flex;
  margin-left: 6px;
}
