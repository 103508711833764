// Border

$modal-border: solid 1px #eeeeee;

// Border radius

$modal-border-radius: 6px;

// Box shadow

$modal-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
