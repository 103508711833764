@use 'sass:map';
@use 'sass:math';
@import '../../../../styles/colors';
@import '../../colors';
@import '../../../../styles/responsive';
@import '../../../../styles/typography';

@function getColor($iter) {
  $color-index: (math.div($iter - ($iter % 20), 20)) + 1;
  @return map.get($feedback-colors, '#{$color-index}');
}

@mixin conic-gradient-animation($iter) {
  @keyframes blinds#{$iter} {
    @for $iteration from 0 to $iter + 1 {
      #{$iteration}% {
        background: conic-gradient(
          getColor($iteration) calc((3.6 * #{$iteration}deg)),
          $main-panel-gray calc((3.6 * #{$iteration}deg))
        );
      }
    }
  }
  animation: blinds#{$iter} 2s linear;
}

.guestSatisfaction {
  &__factors {
    &Body {
      width: calc(75% - 12px);
      margin-left: 24px;
      position: relative;
      @media screen and (max-width: $breakpoint-tablet) {
        margin: 24px 0 0 0;
        width: 100%;
      }
      @media screen and (max-width: $breakpoint-mobile) {
        min-width: unset;
        width: 300px;
      }
    }
    &Line {
      position: absolute;
      border-right: 1px solid $background-gray;
      left: calc(33% + 6px + 152px);
      height: 100%;
      @media screen and (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }
  &__satisfiedIcon,
  &__dissatisfiedIcon {
    width: 16px;
    aspect-ratio: 1 / 1;
    fill: $text-gray;
  }
  &__satisfactionCounter {
    justify-content: flex-start;
    margin-left: calc(33% + 6px);
    @media screen and (max-width: $breakpoint-mobile) {
      margin-left: 0;
    }
    &Container {
      display: flex;
      width: 300px;
      justify-content: space-between;

      & > span {
        display: flex;
        align-items: center;
        & > :first-child {
          margin-right: 7px;
        }
      }
    }
    @media screen and (max-width: $breakpoint-mobile) {
      width: auto;
    }
    color: $text-gray;
    display: flex;
    margin-bottom: 2px;
    flex: 1;
  }
  &__percentage {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    position: relative;

    @for $i from 0 to 100 {
      &--#{$i} {
        background: conic-gradient(getColor($i) calc(3.6 * #{$i}deg), $main-panel-gray calc(3.6 * #{$i}deg));
        @include conic-gradient-animation($i);
      }
    }
    &Num {
      margin: auto;
      display: flex;
      content: '';
      width: calc(100% - 20px);
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      background-color: $white;
      & > span {
        margin: auto;
        font-size: 32px;
        font-weight: $font-weight-semibold;
      }
    }
  }
}

.satisfactionFactor {
  $this: &;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-mobile) {
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__points {
    display: flex;
    margin: auto 0 auto 0;
    position: relative;
    width: calc(67% - 6px);
    justify-content: flex-start;
    @media screen and (max-width: $breakpoint-mobile) {
      width: 300px;
    }
    &Container {
      display: flex;
      max-width: calc(50% - 5px);
      @media screen and (max-width: $breakpoint-mobile) {
        position: relative;
        &:first-child {
          &::after {
            content: '';
            position: absolute;
            border-right: 1px solid $background-gray;
            right: -6px;
            height: 100%;
            top: 0;
          }
        }
      }
      #{$this}__point {
        border: solid 1px #d4d4d4;
        background-color: #eee;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        &:not(:last-child) {
          margin-right: 3px;
        }
        @each $index, $color in $feedback-colors {
          &--#{$index} {
            background-color: $color;
            border-color: $color;
          }
        }
      }

      &--reverse {
        margin-right: 12px;
        flex-direction: row-reverse;
        > #{$this}__point {
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-right: 3px;
          }
        }
      }
    }
  }
  &__title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: bold;
    white-space: nowrap;
    width: calc(33% - 6px);
    @media screen and (max-width: $breakpoint-mobile) {
      margin-bottom: 7px;
      margin-right: 0;
      text-align: center;
    }
  }
}
