@import 'styles/colors.scss';

.inStockIndicator {
  color: $green;
  padding-right: 3px;
}

.outOfStockIndicator {
  color: $orange-tint;
  padding-right: 3px;
}

.unavailable {
  color: $text-gray;
  font-style: italic;
}
