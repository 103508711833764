@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/sharedIcon.scss';

.PanelLayout_container {
  max-width: 100%;
}

.constraint {
  @extend .paragraph-bold;
}

.separator {
  color: $text-gray;
  padding: 0 3px;
}

.isAllowMultiplesModifier {
  @extend .paragraph-regular;
  color: $text-gray;
}

.isAllowMultiplesModifierEditMode {
  color: $black;
}

.labelNote {
  font-weight: $font-weight-regular;
  padding-left: 3px;
}

.sharedIcon {
  @extend .sharedIconTitle;
}
