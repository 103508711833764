// level 1
$formatted-input-nested-label-z-index: 1;
$text-input-nested-label-z-index: 1;

// level 2
$drop-down-menu-z-index: 2;
$select-filter-menu-z-index: 2;
$select-icon-menu-z-index: 2;
$search-select-filter-menu-z-index: 2;
$checkbox-search-filter-menu-z-index: 2;

// level 3
$underlayer-z-index: 3;

// level 4
$layout-sidebar-z-index: 4;

// level 5
$alert-z-index: 5;

// level 6
$modal-z-index: 6;
