@import '../../../../styles/responsive';

.toolBar {
  display: flex;
  @media screen and (max-width: $breakpoint-tablet) {
    justify-content: flex-start;
  }
  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
  > * {
    flex: 1;
    &:not(&:last-child) {
      padding-right: 12px;
    }
    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column;
      padding-bottom: 15px;
      &:not(&:last-child) {
        padding-right: 0;
      }
    }
    @media screen and (max-width: $breakpoint-tablet) and (min-width: $breakpoint-mobile + 1px) {
      max-width: 342px;
    }
    @media screen and (min-width: $breakpoint-tablet + 1px) {
      max-width: 522px;
    }
  }
}
