@import 'styles/colors.scss';
@import 'styles/typography.scss';

.toggleContainer {
  height: 100%;
  align-items: flex-start;
}

.labelNote {
  display: block;
  font-size: 14px;
  color: $text-gray;
  font-weight: $font-weight-regular;
  line-height: 24px;
}

.screenTimeoutContainer {
  width: '30%';
}
