@import '../../../styles/colors.scss';

.contentContainer {
  margin: 24px 0;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.infoList {
  border-top: solid #ededed 1px;
}

.printerCard {
  justify-content: space-between;
  cursor: pointer;
}

.printerModal {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.deleteButton {
  padding: 24px;
}
