@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';

.paymentInfo {
  display: flex;
  justify-content: center;
}
.paymentParagraph {
  @extend .paragraph-regular;
}
.paymentInfoAmount {
  text-align: center;
  padding: 12px;
  padding-top: 24px;
}
.paymentLabel {
  @extend .tiny-title;
  color: $text-gray;
}
.paymentValue {
  min-width: 108px;
  padding: 24px;
  border-radius: 6px;
  background: $background-gray;
  text-align: center;
}
.paymentDollarValue {
  font-size: 20px;
}
.paymentDollarValueTip {
  @extend .paymentDollarValue;
  color: $green-tint;
}
