@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container {
  flex: 100%;
  min-height: 72px;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.details {
  width: 100%;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.name {
  width: 40%;
  max-width: 486px;
  margin-right: 24px;
}

.information {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pos {
  overflow: hidden;
  width: 90%;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.price {
  font-weight: $font-weight-semibold;
}

.hide,
.show,
.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  font-size: 20px;
  color: $dark-text-gray;
  margin-left: 24px;
}

.remove {
  min-width: 36px;
  margin-left: 12px;
}

.hide,
.show {
  margin-left: 10%;
}

.show {
  color: $border-gray;
}

@media screen and (max-width: $breakpoint-tablet) {
  .name {
    width: 50%;
  }

  .information {
    width: 50%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    align-items: flex-start;
    padding: 12px 12px;
  }

  .details {
    display: block;
  }

  .name {
    width: 100%;
  }

  .information {
    width: 100%;
  }

  .pos {
    flex-direction: row;
    justify-content: flex-start;
  }

  .hide,
  .show {
    display: none;
  }

  .dropdown {
    margin-top: 12px;
    margin-left: 12px;
  }
  .remove {
    margin-left: 0;
  }
}
