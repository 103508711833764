@import '../../styles/typography.scss';

.permissionItem {
  display: flex;
  margin-bottom: 12px;
}

.permissionItemCheck {
  margin-top: -6px;
}

.permissionTitle {
  @extend .header-5;
}

.permissionDescription {
  @extend .paragraph-regular;
}
