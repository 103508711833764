.info-card-pagination {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .info__card__column:last-child {
    text-align: right;
  }
  .info__card__data__row__result__count {
    font-size: 14px;
  }
  .info__card__data__row__result__count__total {
    font-weight: $font-weight-bold;
  }

  .info__card__pagination__separator {
    margin: 0 8px;
    color: $main-panel-gray;
  }

  .info__card__navigation__link {
    color: $magenta;
  }

  .info__card__navigation__link--disabled {
    color: $text-gray;
    cursor: not-allowed;
    font-style: italic;
    font-weight: $font-weight-regular;
  }
}
