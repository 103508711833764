@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.itemGalleryStarIcon {
  margin-right: 6px;
  color: $dark-text-gray;
}

.itemGalleryDescription {
  display: flex;
  align-items: center;
  @extend .header-3;
}

.itemGalleryDescriptionName {
  margin-right: 6px;
}

.itemGalleryDescriptionNameDisabled {
  margin-right: 6px;
  color: $text-gray;
  font-style: italic;
}

.itemGalleryControls {
  display: flex;
  padding-right: 12px;
  align-items: center;
}

.itemGalleryEditButton {
  padding-right: 12px !important;
}

.itemGalleryNoItemsMessage {
  @extend .paragraph-regular;
  color: $text-gray;
  text-align: center;
  padding: 24px;
}

.itemGalleryNameText {
  margin-top: 12px;
  @extend .paragraph-bold;
}

.itemGalleryDescriptionText {
  max-width: 700px;
  @extend .paragraph-regular;
  color: $text-gray;
}

@media screen and (max-width: $breakpoint-mobile) {
  .itemGalleryNoItemsMessage {
    padding: 12px;
  }
}
