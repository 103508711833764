@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.name {
  flex: 1;
}

.details {
  display: flex;
  padding: 6px 24px;
  min-height: 72px;
  align-items: center;
}

.content {
  flex: 1;
}

.itemGalleryStarIcon {
  margin-right: 6px;
  color: $dark-text-gray;
}

.itemGalleryDescription {
  display: flex;
  align-items: center;
  @extend .header-3;
}

.itemGalleryDescriptionName {
  margin-right: 6px;
}

.itemGalleryDescriptionNameDisabled {
  margin-right: 6px;
  color: $text-gray;
  font-style: italic;
}
