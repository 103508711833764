@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';

.infoCard {
  gap: 12px;
}

.container {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.left {
  flex: 3;
  gap: 12px;
  display: flex;

  .details {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .name {
      @extend .paragraph-regular;
      overflow: hidden;
    }

    .description {
      color: $text-gray;
      @extend .small-paragraph-regular;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.missing {
  font-style: italic;
}

.attributes {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  .assignedLocations {
    display: flex;
    align-items: center;

    .icon {
      font-size: 14px;
    }

    .amount {
      padding-left: 3px;
      font-weight: bold;
    }
  }

  .icons {
    display: flex;
    flex-direction: row;

    .alcohol {
      display: flex;
      align-items: center;
      justify-self: flex-end;

      .icon {
        font-size: 14px;
        color: $text-gray;
      }

      .amount {
        font-size: 10px;
        padding-left: 3px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
