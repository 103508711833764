.locations {
  margin: 24px 48px 0;
}

.header-wrapper {
  h2 {
    @extend .header-2;
    margin: 18px 0;
  }

  font-weight: normal;
  margin-left: 48px;
}

.skeleton-list {
  .card-wrapper {
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
}

// inside info card column, but specific to orders location page
.name {
  @extend .header-5;
}
.address {
  color: $text-gray;
  font-size: 14px;
}

.name,
.address {
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: initial;
}

.orders-location-nav {
  span {
    color: $magenta;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .locations {
    margin: 24px 24px 0;
  }
  .header-wrapper {
    margin: 0 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .header-wrapper {
    margin: 0 12px;
  }
}
