@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.description,
.descriptionWithSchedule {
  max-width: 702px;
  @extend .paragraph-regular;
  color: $text-gray;
  margin-bottom: 5px;
}

.descriptionWithSchedule,
.locationSettingsButtonContainer {
  padding-top: 6px;
}

.noItems {
  @extend .paragraph-regular;
  color: $text-gray;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 12px;
}

.itemGalleryBand,
.itemGalleryBandDisabled,
.itemListBand {
  background-color: $light-gray;
  padding: 0 24px;
  @extend .small-paragraph-bold;
}

.itemGalleryBand,
.itemListBand {
  color: $dark-text-gray;
}

.itemGalleryBandDisabled {
  height: 100%;
  color: $text-gray;
  font-style: italic;
  display: flex;
  align-items: center;
}

.itemGalleryIcon {
  margin: 0 6px 3px 0;
}

.itemGallery {
  min-height: fit-content;
  padding: 12px 24px;
}

.itemGalleryName,
.itemGalleryNameDisabled {
  @extend .paragraph-bold;
}

.itemGalleryDescription,
.itemGalleryDescriptionDisabled {
  max-width: 702px;
  @extend .paragraph-regular;
  color: $text-gray;
}

.itemGalleryNameDisabled,
.itemGalleryDescriptionDisabled {
  color: $text-gray;
  font-style: italic;
}

.itemGalleryItems {
  border-top: 1px solid $background-gray;
}

.item:hover {
  background-color: $background-gray-op;
}

.locationSettings {
  padding-top: 6px;
}

@media screen and (max-width: $breakpoint-mobile) {
  .itemGalleryBand,
  .itemListBand {
    padding: 0 12px;
  }

  .itemGallery {
    padding: 6px 12px;
  }
}
