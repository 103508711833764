@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.controls {
  display: flex;
  align-items: center;
}

.expander {
  @extend .header-4;
  color: $dark-text-gray;
  width: 48px;
  text-align: center;
}

.content {
  border-top: 1px solid $background-gray;
}
