@import '../../../styles/colors';
@import '../../../styles/responsive';
@import '../../../styles/typography';

.container {
  min-height: 72px;
  border-top: 1px solid $background-gray;
  padding: 12px 24px;
  display: flex;
  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.details {
  width: 100%;
}

.name {
  @extend .paragraph-regular;
}

.description {
  @extend .small-paragraph-regular;
  color: $text-gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status {
  display: flex;
  margin-left: 12px;
  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 0;
  }
}
