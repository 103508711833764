@import '../../styles/typography.scss';
@import '../../styles/colors.scss';

$border-radius: 6px;

%bordered-box {
  border-radius: $border-radius;
  border: solid 1px $border-gray;
}

.container {
  display: flex;
  flex-grow: 1;
}

.textAreaContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.loadingSpinner {
  position: absolute;
}

.loadingControl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textArea {
  @extend .paragraph-regular;
  @extend %bordered-box;
  width: 100%;
  padding: 12px;
  resize: none;
}

.characterLimit {
  @extend .tiny-paragraph-regular;
  color: $text-gray;
  text-align: right;
  display: block;
}

.text {
  @extend .paragraph-regular;
  width: 100%;
}
