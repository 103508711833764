@import '../../styles/borders';
@import '../../styles/colors';
@import '../../styles/typography';

.container {
  display: flex;
  justify-content: space-between;

  &.padSmall {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  &.padMedium {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .input {
    display: inline-flex;
    border-radius: $modal-border-radius;
    border: $modal-border;
    height: 36px;
    width: 120px;
    justify-content: center;
    align-items: center;

    &.full {
      width: 252px;
    }

    &.disabled {
      background-color: $light-gray;
      cursor: not-allowed;

      .value {
        font-style: italic;
        cursor: not-allowed;
      }
    }

    &.darkBorder {
      border-color: $border-gray;
    }

    &.withError {
      border-color: $orange-tint;
    }

    .value {
      font-family: $font-family;
      font-size: 16px;
      border: none;
      width: 20px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      outline: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        background-color: $light-gray;
      }

      &.period {
        width: 26px;
      }
    }

    .colon {
      width: 4px;
      height: 25px;
      line-height: 25px;
    }

    .space {
      width: 4px;
      height: 25px;
      line-height: 25px;
    }
  }
}
