@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.main {
  flex: 100%;
  max-width: 100%;
}

.container,
.containerLinkable {
  flex: 100%;
  display: flex;
  align-items: center;
  min-height: 72px;
}

.containerCompact {
  min-height: 48px;
}

.hide,
.show,
.dropdown {
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 20px;
}

.hide,
.dropdown {
  color: $dark-text-gray;
}

.show {
  color: $border-gray;
}

.dropdown {
  margin-left: 24px;
}

.controlsPlaceholder {
  width: 72px;
}

.mainContent {
  flex-grow: 1;
  min-width: 0;
}

@media screen and (max-width: $breakpoint-tablet) {
  .dropdown {
    margin-left: 12px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container,
  .containerLinkable {
    align-items: flex-start;
  }

  .show,
  .hide {
    margin-right: 12px;
  }
}
