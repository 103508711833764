@import 'styles/responsive.scss';

.searchFilter {
  flex: 1 0.3 40%;
}

.itemTypeFilter {
  flex: 1 0 10%;
  min-width: 150px;
}

.mobileOverflowButton,
.tabletOverflowButton {
  display: none;
}

.buttons {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: $breakpoint-tablet) {
  .buttons {
    display: none;
  }

  .tabletOverflowButton { 
    display: initial;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .searchFilter {
    display: flex;
    width: 100%;
    gap: 12px;
    padding-bottom: 6px;
  }

  .mobileOverflowButton {
    display: initial;
  }

  .tabletOverflowButton {
    display: none;
  }
}
