@import '../../../styles/colors';
@import '../../../styles/typography';

.warningIcon {
  color: $orange-tint;
}

.textBlock:not(:last-of-type) {
  margin-bottom: 24px;
}

.highlight {
  font-style: italic;
}

.emphasis {
  font-weight: $font-weight-bold;
}

.copyInProgressWarning {
  margin-bottom: 12px;
}
