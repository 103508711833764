@import 'styles/colors.scss';
@import 'styles/typography.scss';

.alcoholQuantityNote {
  @extend .tiny-paragraph-regular;
  color: $text-gray;
  font-style: italic;
}

.sharedModifierTabsContainer {
  margin-bottom: 20px;
}
