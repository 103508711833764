@import '../../../../../styles/colors.scss';
@import '../../../../../styles/typography.scss';

.secondarySmall,
.secondarySmallDisabled {
  @extend .small-paragraph-regular;
}

.secondarySmall {
  color: $text-gray;
}

.details {
  flex: 1;
}
