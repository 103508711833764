.text-input-nested-label {
  display: block;

  .text-input-nested-label__label {
    @extend .tiny-paragraph-bold;
    line-height: 16px;
    color: $text-gray;
    background-color: $white;
    padding: 0 4px;
    position: absolute;
    top: 4px;
    left: 8px;
    opacity: 0;

    &.text-input-nested-label__label--visible {
      opacity: 1;
      z-index: $text-input-nested-label-z-index;
    }
  }
  .text-input-nested-label__required-label {
    background-color: $white;
    padding: 0 4px;
    position: absolute;
    top: 4px;
    right: 8px;
    z-index: $text-input-nested-label-z-index;
    width: 58px;
    height: 15px;
    font-size: 12px;
    font-style: italic;
    line-height: 1;
    color: $text-gray;
  }
  .text-input-container {
    padding: 0;
    margin-top: 8px;
  }

  &.text-input-nested-label--state-error {
    span {
      color: $orange-tint;
    }
    div {
      padding-bottom: 6px;
    }
  }
  &.text-input-nested-label--state-loading {
    span {
      color: #{$text-gray}80;
    }
  }
}

// container styles
.container.text-input-nested-label-container {
  padding-bottom: 0;
  position: relative;
}
