@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.container {
  display: flex;
  align-items: center;
  height: 72px;
  margin: 0 48px;
}

.text {
  @extend .header-3;
  flex: 1;
}

.controls {
  display: flex;
}

.controls > div {
  margin-left: 12px;
}

.overflow {
  display: none;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    margin: 0 24px;
  }

  .controls {
    display: none;
  }

  .overflow {
    display: block;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    margin: 0 12px;
  }
}
