@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

$iconSize: 18px;

.selectedIcon {
  font-size: $iconSize;
  color: $dark-text-gray;
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    position: absolute;
    right: 12px;
  }
}

// select-filter
.select {
  display: flex;
  justify-content: flex-end;

  // control
  > div:first-of-type {
    max-width: 60px;
    border: none;

    span {
      color: $dark-text-gray;
    }

    &:hover,
    &:hover span {
      background-color: $disabled-gray;
      color: $darkest-gray;
      border: none;
    }
  }

  // menu-list
  > div:last-of-type {
    display: flex;
    justify-content: flex-end;

    > div {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
