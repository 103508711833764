@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.container {
  display: flex;
}

.button,
.buttonSelected {
  flex: 1;
  height: 36px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

.button {
  @extend .paragraph-regular;
  background-color: $background-gray;
  color: $darkest-gray;
}

.buttonSelected {
  @extend .paragraph-bold;
  background-color: $magenta;
  color: $white;
}

.button:first-child,
.buttonSelected:first-child {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}

.button:last-child,
.buttonSelected:last-child {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
