@import '../../../../styles/typography';
@import '../../../../styles/responsive';
@import '../../../../styles/colors';

.cell__amountPaid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  > :first-child {
    margin-bottom: 8px;
  }
  &Sum {
    font-weight: $font-weight-semibold;
  }
  &Link {
    color: #a7125b;
  }
  @media screen and (max-width: $breakpoint-mobile) {
    align-items: flex-start;
  }
}
.cell__rating {
  display: flex;
  flex-wrap: wrap;
  &RatingItem {
    margin-bottom: 6px;
  }
  &FactorItem {
    display: block;
    &:not(&:last-child) {
      margin-right: 5px;
      &::after {
        content: ',';
      }
    }
  }
  .noServiceFactors {
    font-style: italic;
    color: $text-gray;
  }
}
