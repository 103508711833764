@import '../../../styles/colors';
@import '../../../styles/typography';
@import '../../../styles/responsive';

.tableV2__cell {
  display: table-cell;
  white-space: var(--cell-white-space, nowrap);
  overflow: hidden;

  @media (min-width: $breakpoint-mobile + 1px) {
    &TextAlign {
      &--right {
        text-align: right;
        display: block;
      }

      &--left {
        text-align: left;
        display: block;
      }

      &--center {
        text-align: center;
        display: block;
      }
    }
  }
  &Skeleton {
    display: table-cell;
    > .skeleton {
      border-radius: 4px;
      height: 12px;
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 14px 10px 14px 0;
    text-align: left;
  }
  &CheckBox {
    > div {
      padding: 0;
    }
  }
  &Wrapper {
    display: table-cell;
    padding: 14px 24px 14px 0;
    max-width: 500px;

    @media screen and (max-width: $breakpoint-tablet) {
      padding: 14px 0 14px 24px;
      &:last-child {
        padding: 14px 24px 14px 24px;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      display: table-row;
      justify-content: space-between;
      position: relative;
      padding: 0;
      white-space: normal;

      &::after {
        content: '';
        width: 100%;
        position: absolute;
        border-bottom: 1px solid #eeeeee;
        bottom: 0;
        left: 0;
      }
    }
  }
  &Date {
    white-space: nowrap;
  }
  &Header {
    display: table-cell;
    cursor: pointer;
    padding: 0 24px 0 0;
    @media screen and (max-width: $breakpoint-tablet + 1px) {
      padding: 0 0 0 24px;
      &:last-child {
        padding: 0 24px 0 24px;
      }
    }

    font-size: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
    color: $text-gray;
    line-height: 48px;
    &--mobile {
      @media screen and (max-width: $breakpoint-mobile) {
        white-space: initial;
        line-height: 1.5;
      }
    }
  }
  &HeaderWrapped {
    cursor: initial;
    @media screen and (max-width: $breakpoint-mobile) {
      padding: 6px 58px 6px 13px;
      vertical-align: middle;
      text-transform: capitalize;
      font-size: 16px;
      width: 160px;
    }
  }
  .cellHeader__content {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 3px);
      left: calc(100% - 12px);
    }
    &--sortDirection- {
      &asc,
      &desc {
        color: $black;
        padding-right: 18px;
      }

      &asc::after {
        border-style: solid;
        border-width: 0 4px 6px 4px;
        border-color: transparent transparent $black transparent;
      }
      &desc::after {
        border-style: solid;
        border-width: 6px 4px 0 4px;
        border-color: $black transparent transparent transparent;
      }
    }
  }
  &--textEmpty {
    color: $text-gray;
    font-style: italic;
  }
}
.hidden {
  &--mobile {
    @media screen and (max-width: $breakpoint-mobile) {
      display: none !important;
    }
  }
  &--tablet {
    @media screen and (min-width: $breakpoint-mobile + 1px) and (max-width: $breakpoint-tablet) {
      display: none !important;
    }
  }
  &--desktop {
    @media screen and (min-width: $breakpoint-tablet + 1px) {
      display: none !important;
    }
  }
}
