@import 'styles/colors.scss';

.bulkEditToolbar {
  width: 100%;

  .subHeader {
    font-weight: bold;
    margin: auto 0;
    min-height: 50px;

  }

  .selectAllRow {
    background-color: $background-gray;
    height: 48px;
    padding: 13px;
    text-align: center;
    margin: auto;
    border-radius: 6px;
  }

  .editSelectedButton {
    display: inline-flex;
    &:first-of-type:not(:last-of-type) {
      margin-right: 12px;
    }
  }
}

.divider {
  color: $background-gray;
  margin: auto 0;
  padding: 0 10px;
  font-size: x-large;
}