$text-area-height: 108px;

.textarea {
  border: solid $border-gray 1px;
  border-radius: 6px;
  background-color: $white;
  padding: 12px;
  font-family: $font-family;
  font-size: 16px;
  resize: none;
  width: 100%;
  height: $text-area-height;

  &:active,
  &:focus {
    border: solid $black 1px;
  }
  &::placeholder {
    color: $text-gray;
  }
  &.textarea--state-error {
    border: solid $orange-tint 1px;
  }
  &.textarea--state-loading {
    opacity: 0.5 !important;
    border: solid $border-gray 1px;
  }
  &.textarea--disabled {
    border: solid $border-gray 1px;
    background-color: $disabled-gray;
    font-style: italic;
    color: $dark-text-gray;
    &::placeholder {
      color: $dark-text-gray;
      font-style: italic;
    }
    &,
    * {
      cursor: not-allowed !important;
    }
  }
}

// container styles
.textarea-container {
  width: 100%;
  padding-bottom: 2px;
}

// optional max length styles
.max-length-count-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.textarea-error {
  @extend .tiny-paragraph-bold;
  color: $orange-tint;
  line-height: 2;
}
