@import 'styles/colors.scss';
@import 'styles/responsive.scss';
@import 'styles/typography.scss';
@import 'styles/sharedIcon.scss';

.container:hover {
  background-color: $background-gray-op;
}

.link,
.linkDisabled {
  width: 100%;
  display: flex;
}

.linkDisabled {
  color: $text-gray;
}

.itemGroupImage {
  flex-shrink: 0;
  width: 48px;
}

.itemGroupImageMobile {
  display: none;
}

.itemGroupImageAndDetails {
  display: flex;
  flex-shrink: 1;
  flex-basis: 70%;
}

.itemGroupDetails {
  flex-basis: 100%;
  padding-left: 12px;
  margin-right: 24px;
  width: 0;
}

.itemGroupQuantityAndSpacer {
  display: flex;
  flex-basis: 30%;
}

.itemGroupQuantity,
.itemGroupQuantityHidden {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
  padding-right: 24px;
  flex-basis: 50%;
  flex-shrink: 0;
}

.itemGroupQuantityHidden {
  color: $text-gray;
}

.itemGroupQtyMobile {
  @extend .small-paragraph-regular;
  font-weight: $font-weight-semibold;
  display: none;
}

.sharedIconColumn {
  display: flex;
  align-items: center;
}
.sharedIcon {
  @extend .sharedIconItemList;
}

@media screen and (max-width: $breakpoint-mobile) {
  .itemGroupImage {
    display: none;
  }

  .itemGroupImageMobile {
    display: flex;
    flex-shrink: 0;
    width: 48px;
  }

  .itemGroupDetails {
    flex-basis: 100%;
  }

  .itemGroupQuantity {
    display: none;
  }

  .itemGroupQtyMobile {
    display: block;
    padding: 0 12px;
  }
}
