@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';
@import '../../../styles/typography.scss';

.draggableItemCard {
  display: flex;
  padding: 0 0 0 24px;
  align-items: center;
  flex: 1;
  max-width: 100%;
}

.draggableItemCardFields {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: calc(100% - 84px);

  &.isSharedItemGroup {
    max-width: none;
  }
}

.inputCol {
  padding-right: 24px;
  flex-basis: 25%;
  max-width: 25%;
  flex-shrink: 0;
}

.detailsCol {
  padding: 12px 24px 12px 0;
  flex-basis: 50%;
  max-width: 50%;
  width: 0;
}

.posName {
  @extend .paragraph-regular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.posDetails {
  @extend .small-paragraph-regular;
  color: $text-gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.priceCol {
  padding-right: 24px;
  flex-basis: 15%;
}

.priceValue {
  @extend .paragraph-regular;
  font-weight: $font-weight-semibold;
}

.visibilityRemoveContainer {
  display: flex;
  margin-left: auto;
}

.visibilityCol {
  padding-right: 12px;
  flex-shrink: 0;
}

.removeCol {
  flex-shrink: 0;
}

.cardControls {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  color: $dark-text-gray;
  font-size: 20px;
}

.mobilePosDetails {
  @extend .paragraph-regular;
  display: none;
  align-items: center;
  padding-bottom: 6px;
  justify-content: space-between;
}

.description {
  flex: 1;
  color: $text-gray;
  @extend .small-paragraph-regular;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.missing {
    font-style: italic;
  }
}

.imageCol {
  margin-right: 12px;
}

.iconsCol {
  padding-right: 48px;
}

.mobilePriceAndIconsCol {
  display: none;
}

@media screen and (max-width: 750px) {
  .detailsCol,
  .priceCol,
  .visibilityCol {
    display: none;

    &.isSharedItemGroup {
      display: flex;
    }
  }
  .draggableItemCardFields {
    max-width: 90%;
  }

  .inputCol {
    flex: 1;
    max-width: 320px;
    padding: 0px;
  }

  .draggableItemCard {
    padding: 0 0 12px 0;
    &.isSharedItemGroup {
      padding: 0 0 0 24px;
    }
  }

  .draggableItemCard,
  .draggableItemCardFields {
    align-items: flex-start;
  }

  .cardControls {
    position: relative;
    top: 6px;
  }

  .mobilePriceAndIconsCol {
    display: flex;
    justify-content: space-between;
  }

  .iconsCol {
    display: none;
  }

  .mobileIcons {
    padding-right: 0;
    div {
      width: 30px;
    }
  }
}
