@import '../../styles/responsive.scss';

.container {
  display: flex;
  padding: 24px 48px;
  gap: 24px;
  flex-direction: column;
}

@media screen and (max-width: $breakpoint-tablet) {
  .container {
    padding: 24px 24px;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .container {
    padding: 12px 12px;
  }
}
