@import 'styles/colors.scss';

.noSelectedText {
  color: $text-gray;
}

.groupLabel {
  font-size: 12px;
  font-weight: bold;
  color: $dark-text-gray;
  background-color: $light-gray;
  line-height: 24px;

  &.unavailable {
    display: none;
  }
}

.optionContent {
  [class^='icon-dashboard-icons'] {
    margin-right: 6px;
  }
  &.enabled [class^='icon-dashboard-icons'] {
    color: $green;
  }

  &.disabled {
    color: $text-gray;
    font-style: italic;
  }
}

.nestedDetailContent {
  padding-left: 24px;
  flex-basis: 100%;

  > p {
    margin-top: 12px;
  }
}
