@import '../../../styles/colors';

.Distribution {
  &Line {
    margin-top: 2px;
    height: 6px;
    display: flex;
    box-sizing: border-box;
    &__empty {
      border-radius: 3px;
      background-color: $disabled-gray;
    }
    &Item {
      height: 100%;
      border-radius: 3px;
      &:not(&:last-child) {
        margin-right: 3px;
      }
    }
  }
  &Description {
    margin-top: 12px;
    &Point {
      width: 6px;
      display: block;
      margin: auto 4px auto 0;
      height: 6px;
      border-radius: 50%;
    }
    &Item {
      &:not(&:first-child) {
        margin-top: 6px;
      }
      display: flex;
    }
  }
}
