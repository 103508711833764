@import '../../../styles/typography.scss';

.modalMessage {
  @extend .paragraph-regular;
}

.smsNumberLoaderContainer {
  display: inline-flex;
  vertical-align: text-bottom;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.smsNumberLoader {
  border-radius: 6px;
}
