@import 'styles/colors.scss';
@import 'styles/responsive.scss';

.container {
  display: flex;
  align-items: center;
  border-top: 1px solid $main-panel-gray;
  padding: 12px 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px;
  }
}

.noResults {
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: $text-gray;
}
