@import 'styles/responsive.scss';

.effectiveDates {
  width: 100%;
  display: flex;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.effectiveDates > div {
  margin-right: 12px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-right: 0;
  }
}

.effectiveDates > div:last-of-type {
  margin-right: 0;
}
