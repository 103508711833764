@keyframes animateSkeleton {
  from {
    background-image: linear-gradient(to right, $main-panel-gray, $light-gray);
  }
  to {
    background-image: linear-gradient(to right, $light-gray, $main-panel-gray);
  }
}

.skeleton {
  width: 100%;
  height: 100%;
  background-color: $main-panel-gray;
  animation: animateSkeleton 0.3s ease infinite;
}
