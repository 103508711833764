@import '../../styles/colors';
@import '../../styles/responsive';

.cellPosIntegration {
  @media (max-width: $breakpoint-tablet) {
    order: 5 !important;
  }
}

.showHiddenContainer {
  display: flex;
  .showHiddenText {
    padding: 12px 0px;
    margin-right: 50px;
  }
}
