.listItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > .serial {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.skels {
  width: 40%;
  height: 16px;
}
