@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.container {
  display: flex;
}

.button {
  flex: 1;
  padding: 8px;
  text-decoration: none;
  border-top: 1px solid $magenta;
  border-bottom: 1px solid $magenta;
  border-left: 1px solid $magenta;
  border-right: 0px;
  text-align: center;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 14px;
  background-color: $white;
  color: $magenta;
  white-space: nowrap;
}

.button:hover {
  background-color: $lightmagenta-tint;
  color: $magenta-tint;
}

.button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right-width: 1px;
  border-right-style: solid;
}

.selected {
  font-family: $font-family;
  font-weight: $font-weight-bold;
  background-color: $magenta;
  color: $white;
}

.selected:hover {
  background-color: $magenta;
  color: $white;
}
