@import '../../styles/typography';
@import '../../styles/colors';
@import '../../styles/responsive';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  &.expanded {
    border-bottom: solid 1px $disabled-gray;
    padding-bottom: 12px;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 12px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  min-height: 48px;
}

.serverName {
  @extend .header-3;
  line-height: unset;
  word-wrap: break-word;
  min-width: 0;
}

.tableCount {
  @extend .tiny-paragraph-regular;
  color: $text-gray;
  margin-left: 6px;
  flex-shrink: 0;

  // minor adjustment to visually match baseline
  // with the server name
  padding-top: 8px;
}

.controlContainer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-left: 12px;
}

.buttonContainer {
  margin: 0 6px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.expandButton {
  font-size: 20px;
  color: $dark-text-gray;
}
