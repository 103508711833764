@import 'styles/colors.scss';
@import 'styles/typography.scss';
@import 'styles/responsive.scss';

.infoCardContainer {
  display: flex;
  flex-direction: column;
  & .infoCard {
    align-items: flex-start;
  }
}

.settingsContainer {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  flex-direction: column;
}

.configDetails {
  margin-left: 12px;
  width: 40%;
  word-break: break-all;
}

.configName {
  line-height: 1.5;
}

.configHeader {
  @extend .paragraph-bold;
  color: $text-gray;
}

.configData {
  @extend .paragraph-regular;
  line-height: 1.5;
  color: $text-gray;
}

@media screen and (max-width: $breakpoint-tablet) {
  .configDetails {
    width: 60%;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .configDetails {
    width: 90%;
  }
}
