@import '../../styles/colors';

.container {
  position: relative;
}

.dropdown {
  margin-left: 6px;
  font-size: 10px;
}

.options {
  position: absolute;
  top: 38px;
  right: -3px;
}
