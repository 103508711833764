@import '../../colors';

.star {
  &:not(&:last-child) {
    margin-right: 3px;
  }
  @each $index, $color in $feedback-colors {
    &--#{$index} {
      color: $color;
    }
  }
}
