.search-input {
  border: solid $background-gray 1px;
  border-radius: 6px;
  padding: 12px 12px 12px 32px;
  height: 36px;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  font-size: 16px;
  color: $black;
  box-sizing: border-box;
  background-color: $background-gray;
  width: 33%;

  &.search-input-disabled {
    color: $dark-text-gray;
    font-style: italic;
    cursor: not-allowed;
    background-color: $disabled-gray;
  }

  &:active,
  &:focus {
    border: solid $black 1px;
  }
  &::placeholder {
    color: $dark-text-gray;
  }

  &.white {
    background-color: $white;
  }

  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    width: 100%;
  }
}
.search-input-full-width {
  width: 100%;
}

.search-input-container {
  width: 100%;
  padding: 0;
  position: relative;

  .icon-dashboard-icons_search {
    position: absolute;
    top: 10px;
    left: 12px;
    font-size: 16px;
    color: $dark-text-gray;
  }
}
