@import '../../styles/colors';

.container {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $disabled-gray;

  &:last-of-type {
    border-bottom: none;
  }
}

.checkboxContainer {
  flex-shrink: 0;
}

.secondary {
  color: $text-gray;
}

.tableName {
  flex-shrink: 0;
  width: 30%;
  padding: 12px 0;
}

.revenueCenter {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
