@import '../../../styles/colors.scss';

.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.popular,
.schedule,
.alcohol,
.shared {
  width: 36px;
}

.popular,
.schedule,
.alcohol,
.alcoholAmount,
.shared {
  color: $text-gray;
}

.popular,
.schedule,
.shared {
  font-size: 14px;
}

.alcohol {
  font-size: 14px;
}

.alcoholAmount {
  font-size: 10px;
  padding-left: 3px;
}
