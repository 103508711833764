@import '../../../styles/colors';
@import '../../../styles/typography';

.menuItem {
  @extend .paragraph-regular;
  display: flex;
  align-items: center;
  height: 48px;
  padding-left: 12px;
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: solid $disabled-gray 1px;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    color: $magenta;
  }
}

.pendingMenuItem {
  @extend .paragraph-bold;
  color: $dark-text-gray;
}

.inProgressMenuItem {
  @extend .paragraph-bold;
  color: $magenta;
}

.readyMenuItem {
  @extend .paragraph-bold;
  color: $green;
}

.completedMenuItem {
  @extend .paragraph-bold;
  color: $black;
}
