@import '../../../styles/colors.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/responsive.scss';

.panelContainer {
  margin: 24px 0;
}

.headerControls {
  display: flex;
}

.headerControlsEditButton {
  margin-left: 12px;
}

.noPromoCodesMessage {
  color: $text-gray;
  text-align: center;
  margin: 24px 0;
}

.container {
  margin: 24px 48px 0;

  @media screen and (max-width: $breakpoint-tablet) {
    margin: 24px 24px 0;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    margin: 12px 12px 0;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.title {
  flex: 1;
  @extend .header-3;
}

.button {
  @media screen and (max-width: $breakpoint-mobile) {
    display: none;
  }
}

.overflow {
  display: none;

  @media screen and (max-width: $breakpoint-mobile) {
    display: block;
  }
}

.noCodes,
.code {
  display: flex;
  align-items: center;
  border-top: 1px solid $main-panel-gray;
  padding: 12px 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 12px;
  }
}

.noCodes {
  min-height: 72px;
  justify-content: center;
  color: $text-gray;
  text-align: center;
}

.code {
  min-height: 48px;
  justify-content: flex-start;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.name {
  width: 15%;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 20%;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.description {
  width: 30%;

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.posDiscountName {
  width: 45%;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 30%;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.enabled {
  width: 10%;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 20%;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.name,
.description,
.posDiscountName,
.enabled {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name,
.description,
.posDiscountName {
  margin-right: 24px;

  @media screen and (max-width: $breakpoint-mobile) {
    margin-left: 24px;
  }
}

.noDescription {
  color: $text-gray;
  font-style: italic;
}

.name > a {
  color: $magenta;
}
