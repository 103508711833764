@import '../../styles/typography';
@import '../../styles/colors';

.container {
  // display space for at least three lines of text, even when the modal shows
  // only one line
  min-height: 72px;
  padding: 24px;
}

.noResultsMessage,
.noServersMessage {
  @extend .paragraph-regular;
  color: $text-gray;
}

.noResultsMessage {
  font-style: italic;
}

.noServersMessage {
  text-align: center;
}

.syncText {
  @extend .paragraph-bold;
}
