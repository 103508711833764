@import '../../../styles/colors.scss';

.itemGroupImage {
  background-color: $background-gray;
  width: 48px;
  height: 48px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.itemGroupImage i {
  color: $text-gray;
}

.imtemGroupImageImgElement {
  display: block;
  width: 48px;
}
