$form-group-name-width: 226px;

.form__control__label__bold {
  font-weight: $font-weight-bold;
}

.form__control__label--with-tooltip {
  span {
    margin-left: 3px;
  }
}

@mixin tablet {
  .form__group {
    margin: 0 24px 24px 24px;
    flex-direction: column;

    .form__group__name {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
  .form__panel {
    margin: 24px 24px 0;
  }
  .form__control {
    div.form__control__wrapper {
      &.form__control__label__wrapper__hidden {
        display: none;
      }
    }
    .form__control__label__wrapper {
      padding-bottom: 0;
      &.form__control__label__wrapper__hidden {
        display: none;
      }
    }
    .form__control__label {
      padding-bottom: 6px;

      &.form__control__label--sublabeled {
        padding-left: 0;
      }
    }
  }

  .modal__form__panel {
    .form__control {
      .form__control__wrapper {
        flex-direction: column;
        .form__control__label__wrapper {
          width: 100%;
        }
        .form__control__label {
          width: 100%;
        }
      }
    }
  }
}
@mixin mobile {
  .form__group {
    margin: 0 12px 12px 12px;
  }
  .form__panel {
    margin: 24px 12px 0;
    padding: 12px;
  }
  .form__control div.form__control__wrapper .form__control__inputs.form__control__inputs--gray-background {
    padding: 12px;
  }
  .form__control {
    div.form__control__wrapper {
      flex-direction: column;
    }
    .form__control__label__wrapper {
      width: 100%;
      &.form__control__label__wrapper__empty {
        display: none;
      }
    }
    .form__control__label {
      width: 100%;
    }
  }
}

.form {
  &.mobileStyling {
    @include tablet();
    @include mobile();
  }

  h3.form__panel__title {
    @extend .header-3;
  }
  .form__panel {
    margin: 24px 48px 0;
    padding: 24px;
    border-radius: 6px;
    background-color: $white;
    border: solid $disabled-gray 1px;
    &.modal__form__panel {
      border: none;
      margin: 0;
      padding: 0;
    }
  }

  .form__group {
    display: flex;
    margin: 0 48px 24px 48px;

    .form__group__name {
      width: $form-group-name-width;
      padding: 24px 0;
      margin-right: 48px;

      .from__group__name__container {
        padding: 12px 0;
      }
      h4 {
        @extend .header-4;
      }
    }
    .form__panel {
      flex: 1;
      margin: 0;
    }
  }
  .form__group:last-of-type {
    margin-bottom: 0;
  }
  .form__control {
    width: 100%;
    max-width: 900px;

    &.form__control--separated {
      margin-bottom: 24px;
    }
    div.form__control__wrapper {
      width: 100%;
      display: flex;
    }
    .form__control__label__wrapper {
      display: flex;
      flex: var(--formControlLabelColumnSpace) 1;
      flex-direction: column;
      width: 30%;
      margin-right: 24px;
      padding: 12px 0;
      &.form__control__label__wrapper__hidden {
        display: none;
      }
    }
    .form__control__label {
      @extend .header-5;

      &.form__control__label--icon {
        padding-right: 12px;
      }

      &.form__control__label--sublabeled {
        padding-left: 24px;
        font-weight: $font-weight-regular;
      }

      &.form__control__label--disabled {
        color: $text-gray;
        font-style: italic;
      }
    }
    .form__control__description {
      font-style: italic;
    }
    .form__control__inputs {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
      max-width: 100%;

      .form__control__inputs__elements {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        &.full-width-button {
          .container.button-container {
            width: 100%;
          }
        }
        .control--stacked {
          display: flex;
          flex-basis: 100%;

          &.control--stacked--indented {
            padding-left: 32px;

            @media screen and (max-width: $breakpoint-mobile) {
              padding-left: 24px;
            }
          }
        }
        .form__control__separator,
        .form__control__sublabel {
          height: 48px;
          padding: 12px 0;
          line-height: 24px;
          font-size: 16px;
        }
        .form__control__separator {
          width: 48px;
          text-align: center;
        }
        .form__control__sublabel {
          width: 50%;
        }
        p:not(.input-error) {
          @extend .paragraph-regular;
          line-height: 2;
          width: 100%;
        }
      }
      .text-input-container,
      .formatted-input-container,
      .select-filter-container,
      .text-input-nested-label-container,
      .formatted-input-nested-label-container {
        margin-left: 6px;
        margin-right: 6px;
        flex: 1;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .form__control__error {
        display: none;

        &.form__control__error--show {
          display: block;
        }
        .input-error-container {
          padding-top: 0;
          padding-bottom: 12px;
        }
      }
      &.form__control__inputs--gray-background {
        background-color: $light-gray;
        border: solid $disabled-gray 1px;
        border-radius: 6px;
        padding: 24px;
        box-sizing: border-box;
        margin: 6px 0;

        .form__control__inputs__elements {
          display: block;
          flex-wrap: unset;
        }
        .text-input-container,
        .formatted-input-container,
        .select-filter-container,
        .text-input-nested-label-container,
        .formatted-input-nested-label-container {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  .form__control__help_instructions {
    @extend .small-paragraph-regular;
    width: 100%;
    font-style: italic;
  }
  .form__control__hide__help_instructions {
    display: none;
  }
  .notification {
    max-width: 900px;
  }
  .form__action-buttons {
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: flex-end;

    .container {
      margin-left: 12px;
    }
    &.form__action-buttons--container-layout {
      margin-top: 12px;
      padding-top: 12px;
      border-top: solid $main-panel-gray 1px;
    }
  }
  .form__panel--collapsable {
    position: relative;

    .form__panel--collapsable__header {
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 24px;
      padding: 0 24px 12px 24px;
      border-bottom: solid $main-panel-gray 1px;
      display: flex;
      justify-content: space-between;

      h3 {
        @extend .header-3;
        font-style: italic;
        color: $text-gray;

        span {
          margin-left: 6px;
          font-size: 12px;
        }
      }
      .form__panel--collapsable__controls {
        display: flex;
        align-items: center;

        .icon-button {
          margin-left: 12px;
          i {
            font-size: 16px;
          }
        }
      }
    }
    &.form__panel--collapsable-collapsed {
      .form__panel--collapsable__header {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      .form__panel--collapsable__content {
        height: 0;
        overflow: hidden;
      }
    }
    &.form__panel--collapsable-enabled {
      .form__panel--collapsable__header {
        h3 {
          @extend .header-3;
          font-style: unset;
          color: $black;

          span {
            display: none;
          }
        }
      }
    }
  }
  .form-panel-separator {
    border-bottom: solid $disabled-gray 1px;
    margin: 12px 0;
  }
  .form-sub-header-container {
    padding: 12px 0;

    h3 {
      @extend .header-3;
    }
  }

  // Tablet viewport
  @media screen and (max-width: $breakpoint-tablet) {
    @include tablet();
  }

  // Mobile viewport
  @media screen and (max-width: $breakpoint-mobile) {
    @include mobile();
  }
}
