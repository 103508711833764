@import '../../../styles/typography.scss';

.label {
  font-weight: $font-weight-bold;
}

.subLabel {
  font-style: italic;
  margin-left: 3px;
}
