@import '../../../styles/colors.scss';
@import '../../../styles/responsive.scss';

.dragHandle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 36px;
  height: 36px;
  font-size: 20px;
  color: $border-gray;
  margin-right: 12px;
}

.itemsDragging {
  background-color: $disabled-gray;
}

.item,
.itemDragging {
  padding: 0;
  border-bottom: 1px solid $background-gray;
  min-height: 72px;
  display: flex;
  align-items: center;
}

.item {
  background-color: $white;
}

.itemDragging {
  background-color: $main-panel-gray;
}

@media screen and (max-width: $breakpoint-mobile) {
  .item {
    align-items: flex-start;
  }
}
