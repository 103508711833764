@import '../../styles/colors.scss';

.tagShortCode {
  width: 22px;
  height: 22px;
  border: 1px solid $border-gray;
  border-radius: 50px;
  text-align: center;
  color: $text-gray;
  font-size: 12px;
  padding: 2px;
}
