@import '../../styles/responsive.scss';

.info__card__footer {
  display: flex;
  align-items: center;
  background-color: $white;
  justify-content: space-between;
  height: 48px;
  padding: 0 24px;
  border-radius: 0 0 6px 6px;

  .info__card__data__row__result__count__total {
    font-weight: $font-weight-bold;
  }

  @media screen and (max-width: $breakpoint-mobile) {
    padding: 0 12px;
  }
}
