@import '../../styles/colors';
@import '../../styles/typography';

.container {
  display: flex;
  width: 100%;
  margin: 12px 0 6px;
  background-color: $background-gray;
  border-radius: 6px;
  border: solid 1px $disabled-gray;

  &.readOnly {
    background-color: transparent;
    border: none;
  }
}

.firstContainer {
  margin-top: 6px;

  &.readOnly {
    margin-top: 0;
  }
}

.locationList {
  flex: 1;
  padding: 12px 0 12px 24px;

  &.readOnly {
    padding: 0;
  }
}

.location {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.locationLabelList {
  padding-left: 24px;
}

.locationLabel {
  height: 48px;
  line-height: 48px;
}

.locationIcon {
  padding-right: 6px;
}

.roleName {
  margin-bottom: 12px;
}

.atLabel {
  font-weight: $font-weight-bold;
  margin-right: 12px;
  padding: 12px 8px;
  font-size: 16px;
  align-items: flex-start;
}

.locationListItem {
  flex: 1;
  display: flex;

  .control {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    line-height: 48px;

    button {
      width: 36px;
      height: 36px;

      i {
        font-size: 16px;
      }
    }
  }
}

.locations {
  flex: 2;
}

.removePermission {
  width: 36px;
  height: 36px;
  margin: 18px 12px;
}

.removeIcon {
  font-size: 16px;
  color: $dark-text-gray;
}

.addLocation {
  // normalizes the 4px to 6px that surrounds the .button-large-container class
  padding-top: 2px;
  padding-bottom: 2px;
}
