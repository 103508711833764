@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.name {
  @extend .paragraph-regular;
}

.address {
  @extend .small-paragraph-regular;
  color: $text-gray;
}

.name,
.address {
  line-height: 24px;
}
