@import '../../styles/colors.scss';
@import '../../styles/typography.scss';

.imageUploaderLabel {
  display: block;
  cursor: pointer;
  width: 100%;

  &.readonly {
    cursor: default;
  }
}
.imageUploaderPlaceholder {
  border: solid $background-gray 1px;
  background-color: $background-gray;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cameraIcon {
  color: $text-gray;
  font-size: 32px;
}
.loadingSpinner {
  width: 60px;
}
.imageUPloaderInput {
  display: none;
}
.imageUploaderHelpText {
  margin-top: 18px;
  flex-basis: 100%;
  @extend .tiny-paragraph-regular;
}
.additionalHelpText {
  font-style: italic;
}
.imageUploaderErrors {
  flex-basis: 100%;
}
.imageUploaderErrorText {
  @extend .tiny-paragraph-bold;
  color: $orange-tint;
  display: block;
}
.imageUploaderControls {
  margin-top: 18px;
  display: flex;
  div {
    margin-right: 12px;
    padding-top: 0;
  }
}
