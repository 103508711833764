@import 'styles/colors.scss';

.divider {
  color: $background-gray;
}

.controls {
  display: flex;
  gap: 12px;
  align-items: center;
}
